import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { KIND } from "baseui/button";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { FormRenderer } from "components/form-renderer";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { DeviceFloppy, FileOff } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";
import { scrollOnError } from "utils/scrollOnError";

import { Organizer } from "../organizers";
import { ORGANIZERS_FIELDS, OrganizersFormInputs } from "../organizers.form";
import { ORGANIZERS_SHOW, ORGANIZERS_UPDATE } from "../organizers.gql";

export default function OrganizersUpdate(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const { enqueueSnackbar } = useSnackbar();
  const { setIsFetching, isLoading, setIsLoading } = useLoading();
  const { checkPermission } = useAuth();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    setValue,
    reset,
    watch,
    ...methods
  } = useForm<OrganizersFormInputs>();

  const { refetch, data, error: queryError } = useQuery(ORGANIZERS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
  });
  const organizer: Organizer = data?.organizer;

  useEffect(() => {
    setIsFetching(true);
    if (data?.organizer) refetch();
  }, []);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    if (organizer) {
      setValue("name", organizer?.name);
      setValue("slug", organizer?.slug);

      setIsFetching(false);
    }
  }, [data]);

  useEffect(() => {
    setIsFetching(true);
    if (data?.organizer) refetch();
  }, []);

  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  useEffect(() => {
    if (errors) scrollOnError(errors, ORGANIZERS_FIELDS, "update");
  }, [errorTimeStamp]);

  const [updateOrganizer, { error }] = useMutation(ORGANIZERS_UPDATE);

  const onSubmit = async ({
    ...values
  }: OrganizersFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await updateOrganizer({
        variables: {
          organizerUpdateInput: {
            id: id ? parseInt(id) : null,
            ...values,
          },
        },
      });
      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.push(`/organizers/${id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.organizer.update))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title={organizer?.name}
        labels={["Organizatorzy", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit, onError),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "updateOrganizer",
          },
        ]}
      />
      <Content>
        <FormProvider<OrganizersFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          setValue={setValue}
          watch={watch}
          {...methods}
        >
          <FormRenderer<OrganizersFormInputs>
            id="updateOrganizer"
            onSubmit={handleSubmit(onSubmit, onError)}
            type="update"
            isLoading={isLoading}
            error={error}
            fields={ORGANIZERS_FIELDS}
          />
        </FormProvider>
        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie edycji organizatora"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
      </Content>
    </article>
  );
}
