import { useStyletron } from "baseui";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { Cell } from "components/cell";
import { Content } from "components/content";
import { FormControl } from "components/form-control";
import { ChangePasswordModal } from "components/form-modal";
import { DataType, FormattedValue } from "components/formatted-value";
import { Grid } from "components/grid";
import { Header } from "components/header";
import { Role } from "containers/Roles/roles";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import React, { useEffect, useState } from "react";
import { Lock } from "tabler-icons-react";
import { renderUserLabel } from "utils/render-user-label";

export default function MyAccount(): React.ReactElement {
  const [css, theme] = useStyletron();
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(
    false
  );
  const { isFetching } = useLoading();
  const { user, refetch } = useAuth();

  useEffect(() => {
    refetch();
  }, []);

  return (
    <article>
      <Header
        title={"Moje konto"}
        actions={[
          {
            label: "Zmień hasło",
            icon: Lock,
            onClick: () => setIsChangePasswordModalOpen(true),
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Dane
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>

          <Cell span={4}>
            <FormControl label="Imię i nazwisko">
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <span>{renderUserLabel(user)}</span>
              )}
            </FormControl>
          </Cell>

          <Cell span={4}>
            <FormControl label="Adres e-mail">
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue dataType={DataType.Email}>
                  {user?.email}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>

          <Cell span={4}>
            <FormControl label="Opis">
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue>{user?.description}</FormattedValue>
              )}
            </FormControl>
          </Cell>

          {!!user?.roles?.length && (
            <Cell span={12}>
              <LabelMedium marginBottom="scale200" marginTop="scale600">
                Role
              </LabelMedium>
              <hr
                className={css({
                  borderWidth: "0px",
                  height: "1px",
                  backgroundColor: "#eee",
                })}
              />
            </Cell>
          )}
          {!!user?.roles?.length && (
            <Cell span={12}>
              <FormControl>
                {isFetching ? (
                  <Skeleton rows={0} height="20px" width="100%" animation />
                ) : (
                  <>
                    {user?.roles?.map(({ id, name }: Role) => (
                      <FormattedValue
                        key={`role-` + id}
                        dataType={"model:roles"}
                        data={id}
                        $style={{ marginRight: theme.sizing.scale600 }}
                      >
                        {name}
                      </FormattedValue>
                    ))}
                  </>
                )}
              </FormControl>
            </Cell>
          )}

          <ChangePasswordModal
            isOpen={isChangePasswordModalOpen}
            close={() => setIsChangePasswordModalOpen(false)}
          />
        </Grid>
      </Content>
    </article>
  );
}
