import { styled, useStyletron } from "baseui";
import { Skeleton } from "baseui/skeleton";
import { Theme } from "baseui/theme";
import { Action } from "components/actions-list";
import { Cell } from "components/cell";
import { Grid } from "components/grid";
import { useLoading } from "contexts/loading-context";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useLocation } from "react-router-dom";

const StickedActionsList = styled(
  "summary",
  ({
    $theme,
    $sticked,
    $left,
  }: {
    $theme?: Theme;
    $sticked?: boolean;
    $left?: number;
  }) => ({
    backgroundColor: "white",
    paddingTop: $sticked ? $theme?.sizing.scale300 : $theme?.sizing.scale300,
    paddingBottom: $sticked ? $theme?.sizing.scale300 : $theme?.sizing.scale300,
    position: "fixed",
    left: $left ? `${$left}px` : "225px",
    right: "0px",
    bottom: "0px",
    zIndex: 10,
    borderTopWidth: $sticked ? "2px" : "0px",
    borderTopStyle: "solid",
    borderTopColor: $theme?.colors.backgroundTertiary,
    transform: "translateY(200px)",
    ...($sticked && {
      animationIterationCount: "1",
      animationDelay: "0ms",
      animationDuration: "200ms",
      animationFillMode: "both",
      animationName: {
        from: {
          transform: "translateY(200px)",
        },
        to: {
          transform: "translateY(0px)",
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any,
    }),
  })
);

type ActionsListProps = {
  actions: Action[];
};

export default function ActionsList({
  actions,
}: ActionsListProps): React.ReactElement {
  const [css, theme] = useStyletron();
  const [sticked, setSticked] = useState(false);
  const { isFetching } = useLoading();
  const { pathname } = useLocation();
  const listRef = useRef<HTMLUListElement>(null);

  function checkPosition() {
    if (
      (listRef?.current?.getBoundingClientRect().top || 0) -
        window.innerHeight +
        20 >
      0
    )
      setSticked(true);
    else setSticked(false);
  }

  useEffect(() => {
    checkPosition();
  }, [isFetching]);

  useEffect(() => {
    checkPosition();
  }, [pathname]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      document
        ?.querySelector("main > section")
        ?.addEventListener("scroll", checkPosition);

      window?.addEventListener("resize", checkPosition);

      const observer = new ResizeObserver(function () {
        const width = document?.querySelector("aside")?.offsetWidth;

        if (width) {
          setSidebarWidth(width);
        }
      });

      const sidebarNode = document?.querySelector("aside");

      if (sidebarNode) {
        observer.observe(sidebarNode);
      }

      return () => {
        window?.removeEventListener("resize", checkPosition);
        document
          ?.querySelector("main > section")
          ?.removeEventListener("scroll", checkPosition);
        observer.disconnect();
      };
    }
  }, []);

  const customSidebarWidth =
    localStorage.getItem("sidebarWidth") &&
    parseInt(localStorage.getItem("sidebarWidth") as string);

  const [sidebarWidth, setSidebarWidth] = useState<number>(
    customSidebarWidth || 225
  );

  return (
    <>
      <ul
        ref={listRef}
        className={css({
          marginTop: "0px",
          paddingLeft: "0px",
          paddingRight: "0px",
        })}
      >
        {actions &&
          actions.map((item, index) => {
            return (
              (item.permission || item.permission === undefined) && (
                <li
                  key={`action-${index}`}
                  className={css({
                    display: "flex",
                    alignItems: "center",
                    width: "fit-content",
                    position: "relative",
                    marginTop: theme.sizing.scale400,
                    marginBottom: theme.sizing.scale600,
                    paddingRight: theme.sizing.scale600,
                    color: item?.color,
                    fontWeight: 400,
                    ...(!isFetching && {
                      ":hover": {
                        color: item?.color || theme.colors.primary,
                        cursor: "pointer",
                        ...(item?.color && { filter: "brightness(0.5)" }),
                      },
                    }),
                  })}
                  {...(!isFetching && { onClick: item.onClick })}
                >
                  {isFetching ? (
                    <Skeleton height="20px" width="200px" animation />
                  ) : (
                    <>
                      <div
                        className={css({ marginRight: "10px", height: "20px" })}
                      >
                        <item.icon size={20} role="icon" />
                      </div>
                      {item.label}
                    </>
                  )}
                </li>
              )
            );
          })}
      </ul>

      <StickedActionsList $sticked={sticked} $left={sidebarWidth}>
        <Grid>
          <Cell span={12}>
            <ul
              className={css({
                marginTop: "0px",
                marginBottom: "0px",
                paddingLeft: "0px",
                paddingRight: "0px",
                display: "flex",
                flexWrap: "wrap",
              })}
            >
              {actions &&
                actions.map((item, index) => {
                  return (
                    (item.permission || item.permission === undefined) && (
                      <li
                        key={`sticked-action-${index}`}
                        className={css({
                          display: "flex",
                          width: "fit-content",
                          position: "relative",
                          alignItems: "center",
                          marginTop: theme.sizing.scale200,
                          marginBottom: theme.sizing.scale200,
                          paddingRight: theme.sizing.scale800,
                          color: item?.color,
                          fontWeight: 400,
                          ...(!isFetching && {
                            ":hover": {
                              color: item?.color || theme.colors.primary,
                              cursor: "pointer",
                              ...(item?.color && { filter: "brightness(0.5)" }),
                            },
                          }),
                        })}
                        {...(!isFetching && { onClick: item.onClick })}
                      >
                        {isFetching ? (
                          <Skeleton height="20px" width="200px" animation />
                        ) : (
                          <>
                            <div
                              className={css({
                                marginRight: "10px",
                                height: "20px",
                              })}
                            >
                              <item.icon size={20} role="icon" />
                            </div>
                            {item.label}
                          </>
                        )}
                      </li>
                    )
                  );
                })}
            </ul>
          </Cell>
        </Grid>
      </StickedActionsList>
    </>
  );
}
