import { EntriesField } from "containers/Faq/Entries/entries.form";
import { BasicFilter, FilterType } from "filters.d";

export const ENTRIES_FILTERS: BasicFilter[] = [
  {
    id: EntriesField.Name,
    label: "Nazwa",
    type: FilterType.Text,
  },
  {
    id: EntriesField.CreatedAt,
    label: "Utworzenie",
    type: FilterType.Date,
  },
  {
    id: EntriesField.IsVisible,
    label: "Widoczność",
    type: FilterType.Boolean,
  },
  {
    id: EntriesField.Position,
    label: "Pozycja",
    type: FilterType.Position,
  },
  {
    id: EntriesField.Groups,
    label: "Grupa",
    type: FilterType.Group,
  },
  {
    id: EntriesField.CreatedAt,
    label: "Utworzenie",
    type: FilterType.Date,
  },
  {
    id: EntriesField.CreatedBy,
    label: "Utworzono przez",
    type: FilterType.User,
  },
  {
    id: EntriesField.UpdatedAt,
    label: "Ostatnia aktualizacja",
    type: FilterType.Date,
  },
  {
    id: EntriesField.UpdatedBy,
    label: "Zaktualizowano przez",
    type: FilterType.User,
  },
];
