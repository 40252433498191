import { useStyletron } from "baseui";
import { CustomContentProps, SnackbarContent } from "notistack";
import React from "react";
import {
  AlertCircle,
  AlertTriangle,
  Check,
  InfoCircle,
} from "tabler-icons-react";

export const SnackbarSuccess = React.forwardRef<
  HTMLDivElement,
  CustomContentProps
>((props, ref) => {
  // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
  const { id, message, style, ...other } = props;

  const [, theme] = useStyletron();

  return (
    <SnackbarContent
      ref={ref}
      role="alert"
      style={{
        ...style,
        backgroundColor: theme.colors.positive,
        color: "white",
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
      {...other}
    >
      <Check size={18} style={{ flexShrink: 0, marginRight: "8px" }} />

      {message}
    </SnackbarContent>
  );
});

export const SnackbarError = React.forwardRef<
  HTMLDivElement,
  CustomContentProps
>((props, ref) => {
  // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
  const { id, message, style, ...other } = props;

  const [, theme] = useStyletron();

  return (
    <SnackbarContent
      ref={ref}
      role="alert"
      style={{
        ...style,
        backgroundColor: theme.colors.negative,
        color: "white",
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
      {...other}
    >
      <AlertCircle size={18} style={{ flexShrink: 0, marginRight: "8px" }} />

      {message}
    </SnackbarContent>
  );
});

export const SnackbarInfo = React.forwardRef<
  HTMLDivElement,
  CustomContentProps
>((props, ref) => {
  // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
  const { id, message, style, ...other } = props;

  const [, theme] = useStyletron();

  return (
    <SnackbarContent
      ref={ref}
      role="alert"
      style={{
        ...style,
        backgroundColor: theme.colors.primary,
        color: "white",
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
      {...other}
    >
      <InfoCircle size={18} style={{ flexShrink: 0, marginRight: "8px" }} />

      {message}
    </SnackbarContent>
  );
});

export const SnackbarWarning = React.forwardRef<
  HTMLDivElement,
  CustomContentProps
>((props, ref) => {
  // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
  const { id, message, style, ...other } = props;

  const [, theme] = useStyletron();

  return (
    <SnackbarContent
      ref={ref}
      role="alert"
      style={{
        ...style,
        backgroundColor: theme.colors.warning,
        color: "white",
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
      {...other}
    >
      <AlertTriangle size={18} style={{ flexShrink: 0, marginRight: "8px" }} />

      {message}
    </SnackbarContent>
  );
});
