import { BasicFilter, FilterType } from "./../../filters.d";
import { OrganizersField } from "./organizers.form";

export const ORGANIZERS_FILTERS: BasicFilter[] = [
  {
    id: OrganizersField.Name,
    label: "Nazwa",
    type: FilterType.Text,
  },
  {
    id: OrganizersField.Slug,
    label: "Slug",
    type: FilterType.Text,
  },
  {
    id: OrganizersField.CreatedAt,
    label: "Utworzenie",
    type: FilterType.Date,
  },
  {
    id: OrganizersField.CreatedBy,
    label: "Utworzono przez",
    type: FilterType.User,
  },
  {
    id: OrganizersField.UpdatedAt,
    label: "Ostatnia aktualizacja",
    type: FilterType.Date,
  },
  {
    id: OrganizersField.UpdatedBy,
    label: "Zaktualizowano przez",
    type: FilterType.User,
  },
];
