import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND, SIZE } from "baseui/button";
import { BottomPanel } from "components/bottom-panel";
import { Button } from "components/button";
import { Cell } from "components/cell";
import { Content } from "components/content";
import { Filters } from "components/filters";
import { DataType, FormattedValue } from "components/formatted-value";
import { Grid } from "components/grid";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { PagingControls } from "components/pagination";
import { Table } from "components/table";
import SortingTableHeader, {
  SortDirection,
} from "components/table/sorting-table-header";
import { Tooltip } from "components/tooltip";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { usePaging } from "contexts/paging-context";
import { BasicFilter, FiltersState } from "filters";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Row } from "react-table";
import { Eye, Plus } from "tabler-icons-react";
import { translateFiltersState } from "utils/filters";
import { PERMISSIONS } from "utils/permissions";
import { renderUserLabel } from "utils/render-user-label";

import { EventAggregator } from "../event-aggregators";
import { EVENT_AGGREGATORS_FILTERS } from "../event-aggregators.filters";
import { EventAggregatorsField } from "../event-aggregators.form";
import {
  EVENT_AGGREGATORS_INDEX,
  EVENT_AGGREGATORS_UPDATE,
} from "../event-aggregators.gql";

export default function EventAggregatorsIndex(): React.ReactElement {
  const { pageSize, currentPage, setTotalCount } = usePaging();
  const [sortBy, setSortBy] = useState<EventAggregatorsField | null>(
    EventAggregatorsField.Name
  );
  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    SortDirection.ASC
  );
  const { enqueueSnackbar } = useSnackbar();
  const [css] = useStyletron();
  const {
    isFetching,
    setIsFetching,
    setIsLoading,
    isPartialFetching,
    setIsPartialFetching,
  } = useLoading();
  const history = useHistory();
  const { checkPermission } = useAuth();
  const [filters, setFilters] = useState<FiltersState>();

  const handleSorting = (column: EventAggregatorsField) => {
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const { refetch, data, loading, error } = useQuery(EVENT_AGGREGATORS_INDEX, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      filter: {
        and: [
          {
            ...(filters && (translateFiltersState(filters) as BasicFilter[])),
          },
        ],
      },
    },
  });

  const eventAggregators: { totalCount: number; nodes: EventAggregator[] } =
    data?.eventAggregators;

  useEffect(() => {
    if (data?.eventAggregators) setTimeout(() => refetch(), 100);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    refetch();
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    refetch();
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.eventAggregators) setIsFetching(false);
    if (eventAggregators?.totalCount >= 0)
      setTotalCount(eventAggregators?.totalCount);
  }, [data]);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  const [updateEventAggregator] = useMutation(EVENT_AGGREGATORS_UPDATE);

  const onSubmit = async (
    eventAggregator: EventAggregator,
    fieldName: string
  ): Promise<void> => {
    setIsLoading(true);

    const updatedValues: Partial<EventAggregator> = {
      id: eventAggregator.id,
      isVisible: eventAggregator.isVisible,
      showInSlider: eventAggregator.showInSlider,
    };
    let successMessage = "";

    switch (fieldName) {
      case "isVisible":
        updatedValues.isVisible = !eventAggregator.isVisible;
        successMessage = `Agregator "${eventAggregator.name}" jest ${
          eventAggregator.isVisible ? "niewidoczny" : "widoczny"
        }`;
        break;
      case "showInSlider":
        updatedValues.showInSlider = !eventAggregator.showInSlider;
        successMessage = `Agregator "${eventAggregator.name}" w sliderze jest ${
          eventAggregator.showInSlider ? "niewidoczny" : "widoczny"
        }`;
        break;
      default:
        break;
    }

    try {
      await updateEventAggregator({
        variables: {
          eventAggregatorUpdateInput: updatedValues,
        },
      });
      enqueueSnackbar({
        message: successMessage,
        variant: "success",
      });
      refetch();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventAggregatorsField.Name)}
            sortDirection={
              sortBy === EventAggregatorsField.Name ? sortDirection : null
            }
          >
            Nazwa
          </SortingTableHeader>
        ),
        accessor: EventAggregatorsField.Name,
        Cell: ({ row }: { row: Row<EventAggregator> }) => (
          <FormattedValue
            dataType={DataType.EventAggregators}
            data={row.original.id}
          >
            {row.original.name}
          </FormattedValue>
        ),
        disableGlobalFilter: true,
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventAggregatorsField.Slug)}
            sortDirection={
              sortBy === EventAggregatorsField.Slug ? sortDirection : null
            }
          >
            Slug
          </SortingTableHeader>
        ),
        accessor: EventAggregatorsField.Slug,
        Cell: ({ row }: { row: Row<EventAggregator> }) => (
          <FormattedValue dataType={DataType.Pre} $style={{ fontSize: "12px" }}>
            {row.original.slug}
          </FormattedValue>
        ),
      },
      {
        Header: "Kategoria",
        accessor: EventAggregatorsField.Categories,
        Cell: ({ row }: { row: Row<EventAggregator> }) => (
          <FormattedValue
            dataType={DataType.Categories}
            data={row.original.categories?.[0]?.id}
          >
            {row.original.categories?.[0]?.name}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventAggregatorsField.LocationName)}
            sortDirection={
              sortBy === EventAggregatorsField.LocationName
                ? sortDirection
                : null
            }
          >
            Lokalizacja
          </SortingTableHeader>
        ),
        accessor: EventAggregatorsField.LocationName,
        Cell: ({ row }: { row: Row<EventAggregator> }) => (
          <FormattedValue>{row.original.locationName}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventAggregatorsField.StartedAt)}
            sortDirection={
              sortBy === EventAggregatorsField.StartedAt ? sortDirection : null
            }
          >
            Rozpoczęcie wydarzeń
          </SortingTableHeader>
        ),
        accessor: EventAggregatorsField.StartedAt,
        Cell: ({ row }: { row: Row<EventAggregator> }) => (
          <FormattedValue dataType={DataType.Date}>
            {row.original.startedAt}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventAggregatorsField.EndedAt)}
            sortDirection={
              sortBy === EventAggregatorsField.EndedAt ? sortDirection : null
            }
          >
            Zakończenie wydarzeń
          </SortingTableHeader>
        ),
        accessor: EventAggregatorsField.EndedAt,
        Cell: ({ row }: { row: Row<EventAggregator> }) => (
          <FormattedValue dataType={DataType.Date}>
            {row.original.endedAt}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventAggregatorsField.CreatedAt)}
            sortDirection={
              sortBy === EventAggregatorsField.CreatedAt ? sortDirection : null
            }
          >
            Utworzenie
          </SortingTableHeader>
        ),
        accessor: EventAggregatorsField.CreatedAt,
        Cell: ({ row }: { row: Row<EventAggregator> }) => (
          <FormattedValue dataType={DataType.Date}>
            {row.original.createdAt}
          </FormattedValue>
        ),
      },
      {
        Header: "Utworzono przez",
        accessor: EventAggregatorsField.CreatedBy,
        Cell: ({ row }: { row: Row<EventAggregator> }) => (
          <FormattedValue
            dataType={DataType.Users}
            data={row.original?.createdBy?.id}
          >
            {row.original?.createdBy
              ? renderUserLabel(row.original.createdBy)
              : undefined}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventAggregatorsField.UpdatedAt)}
            sortDirection={
              sortBy === EventAggregatorsField.UpdatedAt ? sortDirection : null
            }
          >
            Ostatnia aktualizacja
          </SortingTableHeader>
        ),
        accessor: EventAggregatorsField.UpdatedAt,
        Cell: ({ row }: { row: Row<EventAggregator> }) => (
          <FormattedValue dataType={DataType.Date}>
            {row.original.updatedAt}
          </FormattedValue>
        ),
      },
      {
        Header: "Zaktualizowano przez",
        accessor: EventAggregatorsField.UpdatedBy,
        Cell: ({ row }: { row: Row<EventAggregator> }) => (
          <FormattedValue
            dataType={DataType.Users}
            data={row.original?.updatedBy?.id}
          >
            {row.original?.updatedBy
              ? renderUserLabel(row.original.updatedBy)
              : undefined}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventAggregatorsField.IsVisible)}
            sortDirection={
              sortBy === EventAggregatorsField.IsVisible ? sortDirection : null
            }
          >
            Widoczność
          </SortingTableHeader>
        ),
        accessor: EventAggregatorsField.IsVisible,
        Cell: ({ row }: { row: Row<EventAggregator> }) => (
          <Tooltip
            content={`Ustaw jako ${
              row.original.isVisible ? "niewidoczny" : "widoczny"
            }`}
            placement="right"
          >
            <span
              onClick={() => onSubmit(row.original, "isVisible")}
              className={css({
                cursor: "pointer",
              })}
            >
              <FormattedValue dataType={DataType.VisibilityBoolean}>
                {row.original.isVisible}
              </FormattedValue>
            </span>
          </Tooltip>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventAggregatorsField.ShowInSlider)}
            sortDirection={
              sortBy === EventAggregatorsField.ShowInSlider
                ? sortDirection
                : null
            }
          >
            Widoczność w sliderze
          </SortingTableHeader>
        ),
        accessor: EventAggregatorsField.ShowInSlider,
        Cell: ({ row }: { row: Row<EventAggregator> }) => (
          <Tooltip
            content={`Ustaw jako ${
              row.original.showInSlider ? "niewidoczny" : "widoczny"
            }`}
            placement="right"
          >
            <span
              onClick={() => onSubmit(row.original, "showInSlider")}
              className={css({
                cursor: "pointer",
              })}
            >
              <FormattedValue dataType={DataType.VisibilityBoolean}>
                {row.original.showInSlider}
              </FormattedValue>
            </span>
          </Tooltip>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventAggregatorsField.SliderPosition)}
            sortDirection={
              sortBy === EventAggregatorsField.SliderPosition
                ? sortDirection
                : null
            }
          >
            Pozycja w sliderze
          </SortingTableHeader>
        ),
        accessor: EventAggregatorsField.SliderPosition,
        Cell: ({ row }: { row: Row<EventAggregator> }) => (
          <FormattedValue dataType={DataType.Pre} $style={{ fontSize: "12px" }}>
            {row.original.sliderPosition}
          </FormattedValue>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<EventAggregator> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $style={{ marginLeft: "6px" }}
              onClick={() =>
                history.push(`/event-aggregators/${row.original.id}`)
              }
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [sortBy, sortDirection]
  );

  if (!checkPermission(PERMISSIONS.eventAggregator.read))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title="Agregatory wydarzeń"
        recordsNum={eventAggregators?.totalCount}
        labels={["Lista"]}
        buttons={[
          {
            label: "Dodaj agregator wydarzeń",
            kind: KIND.primary,
            startEnhancer: <Plus size={18} />,
            permission: checkPermission(PERMISSIONS.eventAggregator.create),
            onClick: () => history.push("/event-aggregators/create"),
          },
        ]}
      />
      <Filters
        filters={EVENT_AGGREGATORS_FILTERS}
        state={filters}
        setState={setFilters}
      />
      <Content filtersOffset>
        <Grid>
          <Cell span={12}>
            <Table<EventAggregator>
              columns={columns}
              data={eventAggregators?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>
          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
