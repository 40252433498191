import { BasicFilter, FilterType } from "./../../filters.d";
import { EventAggregatorsField } from "./event-aggregators.form";

export const EVENT_AGGREGATORS_FILTERS: BasicFilter[] = [
  {
    id: EventAggregatorsField.Name,
    label: "Nazwa",
    type: FilterType.Text,
  },
  {
    id: EventAggregatorsField.Categories,
    label: "Kategoria",
    type: FilterType.Category,
  },
  {
    id: EventAggregatorsField.LocationName,
    label: "Lokalizacja",
    type: FilterType.Text,
  },
  {
    id: EventAggregatorsField.IsVisible,
    label: "Widoczność",
    type: FilterType.Boolean,
  },
  {
    id: EventAggregatorsField.StartedAt,
    label: "Rozpoczęcie wydarzeń",
    type: FilterType.Date,
  },
  {
    id: EventAggregatorsField.EndedAt,
    label: "Zakończenie wydarzeń",
    type: FilterType.Date,
  },
  {
    id: EventAggregatorsField.SliderPosition,
    label: "Pozycja w sliderze",
    type: FilterType.Position,
  },
  {
    id: EventAggregatorsField.ShowInSlider,
    label: "Widoczność w sliderze",
    type: FilterType.Boolean,
  },
  {
    id: EventAggregatorsField.CreatedAt,
    label: "Utworzenie",
    type: FilterType.Date,
  },
  {
    id: EventAggregatorsField.CreatedBy,
    label: "Utworzono przez",
    type: FilterType.User,
  },
  {
    id: EventAggregatorsField.UpdatedAt,
    label: "Ostatnia aktualizacja",
    type: FilterType.Date,
  },
  {
    id: EventAggregatorsField.UpdatedBy,
    label: "Zaktualizowano przez",
    type: FilterType.User,
  },
];
