import { useStyletron } from "baseui";
import {
  FlexGrid as BaseFlexGrid,
  FlexGridProps as BaseFlexGridProps,
} from "baseui/flex-grid";
import {
  Grid as BaseGrid,
  GridProps as BaseGridProps,
} from "baseui/layout-grid";
import React from "react";
import { useLocation } from "react-router-dom";

type GridProps = {
  isFlex?: boolean;
  fill?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: React.ReactChild | React.ReactChild[] | any;
} & BaseFlexGridProps &
  BaseGridProps;

export default function Grid({
  isFlex,
  fill,
  children,
  ...props
}: GridProps): React.ReactElement {
  const [css, theme] = useStyletron();

  const { pathname } = useLocation();

  const isCompactGrid = /(.*)\/(\d{1,}|create)/gm.test(pathname);

  if (isFlex)
    return (
      <BaseFlexGrid
        flexGridColumnCount={[1, 2, 2, 2]}
        flexGridColumnGap="scale2400"
        flexGridRowGap="scale2400"
        {...props}
        className={css({
          marginLeft: fill ? `-${theme.sizing.scale700}` : "0px",
          marginRight: fill ? `-${theme.sizing.scale700}` : "0px",
          width: "100%",
        })}
      >
        {children}
      </BaseFlexGrid>
    );
  else
    return (
      <BaseGrid
        overrides={{
          Grid: {
            style: {
              marginLeft: fill ? `-${theme.sizing.scale1600}` : "auto",
              marginRight: fill ? `-${theme.sizing.scale1600}` : "auto",
              ...props.$style,
            },
          },
        }}
        gridMaxWidth={isCompactGrid ? 920 : 1640}
        {...props}
      >
        {children}
      </BaseGrid>
    );
}
