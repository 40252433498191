import { BasicFilter, FilterType } from "./../../filters.d";
import { ImagesField } from "./images.form";

export const IMAGES_FILTERS: BasicFilter[] = [
  {
    id: ImagesField.OriginalName,
    label: "Nazwa i rozszerzenie",
    type: FilterType.Text,
  },
  {
    id: ImagesField.Alt,
    label: "Tekst alternatywny",
    type: FilterType.Text,
  },
  {
    id: ImagesField.CreatedAt,
    label: "Utworzenie",
    type: FilterType.Date,
  },
  {
    id: ImagesField.CreatedBy,
    label: "Utworzono przez",
    type: FilterType.CreatedBy,
  },
  {
    id: ImagesField.UpdatedAt,
    label: "Ostatnia aktualizacja",
    type: FilterType.Date,
  },
  {
    id: ImagesField.UpdatedBy,
    label: "Zaktualizowano przez",
    type: FilterType.UpdatedBy,
  },
];
