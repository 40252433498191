import { ApolloError, useMutation } from "@apollo/client";
import { KIND } from "baseui/button";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { FormRenderer } from "components/form-renderer";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import {
  GROUPS_FIELDS,
  GroupsFormInputs,
} from "containers/Faq/Groups/groups.form";
import { GROUPS_CREATE } from "containers/Faq/Groups/groups.gql";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { DeviceFloppy, FileOff, Note } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";
import { scrollOnError } from "utils/scrollOnError";

export default function GroupsCreate(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [isClearConfirmDialogOpen, setIsClearConfirmDialogOpen] = useState(
    false
  );
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const { isLoading, setIsLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const { checkPermission } = useAuth();
  const history = useHistory();

  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    reset,
    setValue,
    watch,
    ...methods
  } = useForm<GroupsFormInputs>({
    defaultValues: {
      name: "",
      slug: "",
      isVisible: false,
    },
  });

  const [faqGroupCreate, { error }] = useMutation(GROUPS_CREATE);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  useEffect(() => {
    if (errors) scrollOnError(errors, GROUPS_FIELDS, "create");
  }, [errorTimeStamp]);

  const onSubmit = async ({
    isVisible,
    ...values
  }: GroupsFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      const { data } = await faqGroupCreate({
        variables: {
          faqGroupCreateInput: {
            isVisible: !!isVisible,
            ...values,
          },
        },
      });

      enqueueSnackbar({
        message: "Utworzono pomyślnie",
        variant: "success",
      });
      history.push(`/groups/${data?.faqGroupCreate?.id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.group.create))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        labels={["Grupy", "Tworzenie"]}
        title="Nowa grupa"
        goBackOption
        buttons={[
          {
            label: "Wyczyść",
            kind: KIND.secondary,
            startEnhancer: <Note size={18} />,
            disabled: isLoading,
            onClick: () => {
              setIsClearConfirmDialogOpen(true);
            },
          },
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Utwórz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit, onError),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "createGroup",
          },
        ]}
      />
      <Content>
        <FormProvider<GroupsFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          watch={watch}
          setValue={setValue}
          {...methods}
        >
          <FormRenderer<GroupsFormInputs>
            id="createGroup"
            onSubmit={handleSubmit(onSubmit)}
            type="create"
            isLoading={isLoading}
            error={error}
            fields={GROUPS_FIELDS}
          />
        </FormProvider>
        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie tworzenia nowej grupy"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
        <ConfirmDialog
          isOpen={isClearConfirmDialogOpen}
          label="Wyczyszczenie formularza"
          close={() => setIsClearConfirmDialogOpen(false)}
          confirm={() => {
            reset();
            setIsClearConfirmDialogOpen(false);
          }}
        />
      </Content>
    </article>
  );
}
