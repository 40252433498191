export enum FilterType {
  Activity = "Activity",
  ActivityLogsSubjectType = "activity-logs-subject-type",
  Boolean = "boolean",
  InvertedBoolean = "inverted-boolean",
  Category = "category",
  CreatedBy = "created-by",
  Date = "date",
  DictionaryValues = "dictionary-values",
  Price = "price",
  Role = "role",
  SeoTagsetsSubjectType = "seo-tagsets-subject-type",
  Text = "text",
  Position = "position",
  UpdatedBy = "updatedBy",
  User = "user",
  Organizer = "organizer",
  Group = "group",
  IsRedirectUrl = "is-redirect-url",
}

export type FilterState = {
  not?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  dictionarySystemName?: string;
};

export type BasicFilter = {
  id: string;
  label: string;
  type: FILTERS;
  dictionarySystemName?: string;
};

export type SingleFilter = {
  uuid: string;
  filter: BasicFilter;
  state: FilterState;
};

export type FiltersState = SingleFilter[];
