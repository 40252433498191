/**
 * App Constants
 */

export const AUTH_TOKEN = "auth-token";
export const NOT_FOUND_ERROR = "NOT_FOUND_ERROR";
export const INPUT_VALIDATION_ERROR = "INPUT_VALIDATION_ERROR";
export const APP_NAME = "SuperTicket Admin";

export const PAGE_SIZE = 25;

export enum ReactAppSystemVersion {
  TEST = "TEST",
  PRODUCTION = "PRODUCTION",
  TRAINING = "TRAINING",
}

export enum FormType {
  Update = "update",
  Create = "create",
}

export enum Typename {
  Category = "Category",
  Dictionary = "Dictionary",
  DictionaryValue = "DictionaryValue",
  Event = "Event",
  EventAggregator = "EventAggregator",
  FaqGroup = "FaqGroup",
  FaqEntry = "FaqEntry",
  Image = "Image",
  Role = "Role",
  SeoTagset = "SeoTagset",
  Slide = "Slide",
  StaticPage = "StaticPage",
  Tag = "Tag",
  User = "User",
  Organizer = "Organizer",
}

export const ACTIVITY_LOG_ACTIVITIES = [
  { id: "updated", label: "Aktualizacja" },
  { id: "exportedUsers", label: "Eksport użytkowników" },
  { id: "login", label: "Logowanie" },
  { id: "failedLogin", label: "Nieudane logowanie" },
  { id: "unblocked", label: "Odblokowanie" },
  { id: "revealed", label: "Odczytanie" },
  { id: "forgotPassword", label: "Prośba o reset hasła" },
  { id: "moved", label: "Przeniesienie" },
  { id: "resetPassword", label: "Reset hasła" },
  { id: "hidden", label: "Ukrycie" },
  { id: "setInitialPassword", label: "Ustawienie hasła" },
  { id: "deleted", label: "Usunięcie" },
  { id: "created", label: "Utworzenie" },
  { id: "logout", label: "Wylogowanie" },
  { id: "blocked", label: "Zablokowanie" },
  { id: "changedPassword", label: "Zmiana hasła" },
];

export const ACTIVITY_LOGS_SUBJECTS = [
  { id: "Image", label: "Grafika" },
  { id: "Category", label: "Kategoria" },
  { id: "Role", label: "Rola" },
  { id: "Slide", label: "Slajd" },
  { id: "Dictionary", label: "Słownik" },
  { id: "StaticPage", label: "Strona informacyjna" },
  { id: "Tag", label: "Tag" },
  { id: "SeoTagset", label: "Tag SEO" },
  { id: "User", label: "Użytkownik" },
  { id: "DictionaryValue", label: "Wartość słownika" },
  { id: "Event", label: "Wydarzenie" },
  { id: "EventAggregator", label: "Agregator wydarzenia" },
  { id: "FaqEntry", label: "Pozycja FAQ" },
  { id: "FaqGroup", label: "Grupa FAQ" },
];

export const SEO_TAGSETS_SUBJECTS = [
  { id: "Category", label: "Kategoria" },
  { id: "StaticPage", label: "Strona informacyjna" },
  { id: "Event", label: "Wydarzenie" },
  { id: "EventAggregator", label: "Agregator wydarzeń" },
];

export enum TestEmails {
  AllPermissions = "boyle@codeshine.com",
  NoPermissions = "riemann@codeshine.com",
  PermissionsToShow = "euclid@codeshine.com",
}

export const ALL_PERMISSIONS_USER = "Robert Boyle";

export const TEST_PASSWORD = "password";

export enum Difficulty {
  Easy = "Easy",
  Medium = "Medium",
  Hard = "Hard",
}

export const DIFFICULTY_LEVELS = [
  { id: Difficulty.Easy, label: "Łatwy" },
  { id: Difficulty.Medium, label: "Średnio-zaaw." },
  { id: Difficulty.Hard, label: "Wymagający" },
];

export enum DictionarySystemName {
  RobotsSeoTagsets = "robotsSeoTagsets",
  SocialMedia = "socialMedia",
}

export const BANNED_EVENT_AGGREGATORS_SLUGS = [
  "bilety",
  "faq",
  "regulamin",
  "kontakt",
  "informacje",
  "status-rezerwacji",
  "statusrezerwacji",
  "polityka-prywatnosci",
  "politykaprywatnosci",
];
