import { ApolloError, useMutation } from "@apollo/client";
import { KIND } from "baseui/button";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { FormRenderer } from "components/form-renderer";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { DeviceFloppy, FileOff, Note } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";
import { scrollOnError } from "utils/scrollOnError";

import {
  EVENT_AGGREGATORS_FIELDS,
  EventAggregatorsFormInputs,
} from "../event-aggregators.form";
import { EVENT_AGGREGATORS_CREATE } from "../event-aggregators.gql";

export default function EventAggregatorsCreate(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [isClearConfirmDialogOpen, setIsClearConfirmDialogOpen] = useState(
    false
  );
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const { isLoading, setIsLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const { checkPermission } = useAuth();
  const history = useHistory();

  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    reset,
    setValue,
    watch,
    ...methods
  } = useForm<EventAggregatorsFormInputs>({
    defaultValues: {
      name: "",
      slug: "",
      isVisible: false,
      events: [],
      showInSlider: false,
      locationName: "",
      metaDescription: "",
      metaKeywords: "",
      ogTitle: "",
      ogDescription: "",
      robots: [],
      canonical: "",
    },
  });

  const [createEventAggregator, { error }] = useMutation(
    EVENT_AGGREGATORS_CREATE
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  useEffect(() => {
    if (errors) scrollOnError(errors, EVENT_AGGREGATORS_FIELDS, "create");
  }, [errorTimeStamp]);

  const onSubmit = async ({
    isVisible,
    events,
    metaTitle,
    metaDescription,
    metaKeywords,
    ogTitle,
    ogDescription,
    ogImage,
    canonical,
    robots,
    categories,
    ...values
  }: EventAggregatorsFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      const { data } = await createEventAggregator({
        variables: {
          eventAggregatorCreateInput: {
            isVisible: !!isVisible,
            eventIds: events?.map(
              ({ id }: { id: number | string }) => id as number
            ),
            categoryIds: categories?.map(
              ({ id }: { id: number | string }) => id as number
            ),
            ...values,
          },
          seoTagsetUpsertInput: {
            metaTitle,
            metaDescription,
            metaKeywords,
            ogTitle,
            ogDescription,
            ogImage,
            canonical,
            robots: robots?.length ? robots?.[0]?.label : null,
          },
        },
      });

      enqueueSnackbar({
        message: "Utworzono pomyślnie",
        variant: "success",
      });
      history.push(`/event-aggregators/${data?.eventAggregatorCreate?.id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.eventAggregator.create))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title="Nowy agregator wydarzeń"
        labels={["Agregatory wydarzeń", "Tworzenie"]}
        goBackOption
        buttons={[
          {
            label: "Wyczyść",
            kind: KIND.secondary,
            startEnhancer: <Note size={18} />,
            disabled: isLoading,
            onClick: () => {
              setIsClearConfirmDialogOpen(true);
            },
          },
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Utwórz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit, onError),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "createEventAggregator",
          },
        ]}
      />
      <Content>
        <FormProvider<EventAggregatorsFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          watch={watch}
          setValue={setValue}
          {...methods}
        >
          <FormRenderer<EventAggregatorsFormInputs>
            id="createEventAggregator"
            onSubmit={handleSubmit(onSubmit)}
            type="create"
            isLoading={isLoading}
            error={error}
            fields={EVENT_AGGREGATORS_FIELDS}
          />
        </FormProvider>
        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie tworzenia nowego agregatora wydarzeń"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
        <ConfirmDialog
          isOpen={isClearConfirmDialogOpen}
          label="Wyczyszczenie formularza"
          close={() => setIsClearConfirmDialogOpen(false)}
          confirm={() => {
            reset();
            setIsClearConfirmDialogOpen(false);
          }}
        />
      </Content>
    </article>
  );
}
