import { BasicFilter, FilterType } from "./../../filters.d";
import { EventsField } from "./events.form";

export const EVENTS_FILTERS: BasicFilter[] = [
  {
    id: EventsField.Name,
    label: "Nazwa",
    type: FilterType.Text,
  },
  {
    id: EventsField.Slug,
    label: "Slug",
    type: FilterType.Text,
  },
  {
    id: EventsField.Categories,
    label: "Kategoria",
    type: FilterType.Category,
  },
  {
    id: EventsField.LocationName,
    label: "Lokalizacja",
    type: FilterType.Text,
  },
  {
    id: EventsField.BiletynaId,
    label: "ID wydarzenia w systemie Biletyna",
    type: FilterType.Text,
  },
  {
    id: EventsField.StartedAt,
    label: "Rozpoczęcie wydarzenia",
    type: FilterType.Date,
  },
  {
    id: EventsField.EndedAt,
    label: "Zakończenie wydarzenia",
    type: FilterType.Date,
  },
  {
    id: EventsField.SaleStartedAt,
    label: "Rozpoczęcie sprzedaży",
    type: FilterType.Date,
  },
  {
    id: EventsField.SaleEndedAt,
    label: "Zakończenie sprzedaży",
    type: FilterType.Date,
  },
  {
    id: EventsField.IsSaleBlocked,
    label: "Blokada sprzedaży",
    type: FilterType.InvertedBoolean,
  },
  {
    id: EventsField.StartingPrice,
    label: "Cena od",
    type: FilterType.Price,
  },
  {
    id: EventsField.Organizers,
    label: "Organizator",
    type: FilterType.Organizer,
  },
  {
    id: EventsField.IsVisible,
    label: "Widoczność",
    type: FilterType.Boolean,
  },
  {
    id: EventsField.Position,
    label: "Pozycja",
    type: FilterType.Position,
  },
  {
    id: EventsField.IsRedirectUrl,
    label: "Przekierowanie",
    type: FilterType.IsRedirectUrl,
  },
  {
    id: EventsField.SliderPosition,
    label: "Pozycja w sliderze",
    type: FilterType.Position,
  },
  {
    id: EventsField.ShowInSlider,
    label: "Widoczność w sliderze",
    type: FilterType.Boolean,
  },
  {
    id: EventsField.VisibleAt,
    label: "Data widoczności",
    type: FilterType.Date,
  },
  {
    id: EventsField.CreatedAt,
    label: "Utworzenie",
    type: FilterType.Date,
  },
  {
    id: EventsField.CreatedBy,
    label: "Utworzono przez",
    type: FilterType.User,
  },
  {
    id: EventsField.UpdatedAt,
    label: "Ostatnia aktualizacja",
    type: FilterType.Date,
  },
  {
    id: EventsField.UpdatedBy,
    label: "Zaktualizowano przez",
    type: FilterType.User,
  },
  {
    id: EventsField.DisplayAlternativeButton,
    label: "Pokaż alternatywny przycisk",
    type: FilterType.Boolean,
  },
];
