export enum FieldType {
  Author = "author",
  CategoryRelations = "category-relations",
  CategorySelect = "category-select",
  CategoriesSelect = "categories-select",
  CategoriesTable = "categories-table",
  EventsTable = "events-table",
  Checkbox = "checkbox",
  ColorPicker = "color-picker",
  DatePicker = "date-picker",
  TimePicker = "time-picker",
  DictionaryValuesSelect = "dictionary-values-select",
  DifficultySelect = "difficulty-select",
  Domains = "domains",
  Editor = "editor",
  EventsSelect = "events-select",
  EventsLiveSearchSelect = "events-live-search-select",
  FileDownload = "file-download",
  FilesList = "files-list",
  FilesPicker = "files-picker",
  Image = "image",
  OgImage = "og-image",
  ImageCropper = "image-cropper",
  Input = "input",
  BigInput = "big-input",
  Multi = "multi",
  NumberInput = "number-input",
  ParentCategorySelect = "parent-category-select",
  PermissionsTable = "permissions-table",
  RadioButton = "radio-button",
  RobotsSelect = "robots-select",
  RolesSelect = "roles-select",
  RolesMultiSelect = "roles-multi-select",
  Select = "select",
  SlugInput = "slug-input",
  Link = "link",
  SeoTagsetSubject = "subject",
  SlideVariant = "slide-variant",
  TagsSelect = "tags-select",
  UserFullName = "user-full-name",
  UsersSelect = "users-select",
  UserStatus = "user-status",
  TextArea = "text-area",
  Token = "token",
  Switch = "switch",
  OrganizersSelect = "organizers-select",
  GroupsSelect = "groups-select",
  LockedInput = "locked-input",
}

type BasicField = {
  id: string;
  label: string;
  caption?: string;
  placeholder?: string;
  autocomplete?: string;
  info?: string;
  span?: number;
  skip?: number;
  dataType?: string;
  typeName?: string;
  type: FieldType[keyof FieldType];
  mask?: string;
  maskChar?: string;
  endEnhancer?: string;
  startEnhancer?: string;
  readonly?: boolean;
  maxLength?: number;
  imageRequirements?: {
    aspect?: number;
    rounded?: boolean;
    allowVectors?: boolean;
  };
  dictionarySystemName?: string;
  maxSize?: number;
  minDate?: Date | string;
  maxDate?: Date | string;
  withTime?: boolean;
  rows?: number;
};

export type Field = {
  show: { visible: boolean; accessor?: string | string[]; span?: number };
  create: { visible: boolean; required?: boolean };
  update: { visible: boolean; required?: boolean };
} & BasicField;

type BasicFieldsGroup = {
  id: string;
  label: string;
};

export type FieldsGroup = {
  fields: Field[];
  accessor?: string;
} & BasicFieldsGroup;
