import { gql } from "@apollo/client";

export const EVENT_AGGREGATORS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: EventAggregatorFilter
    $sorting: [EventAggregatorSort!]
  ) {
    eventAggregators(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        slug
        isVisible
        startedAt
        endedAt
        sliderPosition
        showInSlider
        categories {
          id
          name
        }
        locationName
        createdAt
        updatedAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const EVENT_AGGREGATORS_SHOW = gql`
  query EventAggregatorShow($id: Int!) {
    eventAggregator(id: $id) {
      id
      name
      slug
      isVisible
      createdAt
      updatedAt
      deletedAt
      events {
        id
        name
        slug
        locationName
        startedAt
        endedAt
        startingPrice
        biletynaId
        sliderPosition
        showInSlider
        position
        isVisible
        visibleAt
        saleStartedAt
        saleEndedAt
        createdAt
        organizer {
          id
          name
        }
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
        deletedAt
        categories {
          id
          name
        }
      }
      startedAt
      endedAt
      sliderPosition
      showInSlider
      categories {
        id
        name
      }
      locationName
      verticalPosterUrl
      content
      createdBy {
        id
        firstName
        lastName
      }
      updatedBy {
        id
        firstName
        lastName
      }
      seoTagset {
        id
        metaTitle
        metaDescription
        metaKeywords
        ogTitle
        ogDescription
        ogImageUrl
        canonical
        robots
      }
    }
  }
`;

export const EVENT_AGGREGATORS_CREATE = gql`
  mutation EventAggregatorCreate(
    $eventAggregatorCreateInput: EventAggregatorCreateInput!
    $seoTagsetUpsertInput: SeoTagsetUpsertInput
  ) {
    eventAggregatorCreate(
      eventAggregatorCreateInput: $eventAggregatorCreateInput
      seoTagsetUpsertInput: $seoTagsetUpsertInput
    ) {
      id
    }
  }
`;

export const EVENT_AGGREGATORS_UPDATE = gql`
  mutation EventAggregatorUpdate(
    $eventAggregatorUpdateInput: EventAggregatorUpdateInput!
    $seoTagsetUpsertInput: SeoTagsetUpsertInput
  ) {
    eventAggregatorUpdate(
      eventAggregatorUpdateInput: $eventAggregatorUpdateInput
      seoTagsetUpsertInput: $seoTagsetUpsertInput
    ) {
      id
    }
  }
`;

export const EVENT_AGGREGATORS_DELETE = gql`
  mutation EventAggregatorDelete(
    $eventAggregatorDeleteInput: EventAggregatorDeleteInput!
  ) {
    eventAggregatorDelete(
      eventAggregatorDeleteInput: $eventAggregatorDeleteInput
    ) {
      id
    }
  }
`;
