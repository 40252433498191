export const PERMISSIONS = {
  activityLog: {
    read: "activityLog.read",
  },
  category: {
    read: "category.read",
    update: "category.update",
    create: "category.create",
    delete: "category.delete",
  },
  group: {
    read: "faqGroup.read",
    update: "faqGroup.update",
    create: "faqGroup.create",
    delete: "faqGroup.delete",
  },
  entry: {
    read: "faqEntry.read",
    update: "faqEntry.update",
    create: "faqEntry.create",
    delete: "faqEntry.delete",
  },
  dictionary: {
    read: "dictionary.read",
    valueUpdate: "dictionaryValue.update",
    valueCreate: "dictionaryValue.create",
    valueDelete: "dictionaryValue.delete",
  },
  event: {
    read: "event.read",
    update: "event.update",
    create: "event.create",
    delete: "event.delete",
  },
  eventAggregator: {
    read: "eventAggregators.read",
    update: "eventAggregators.update",
    create: "eventAggregators.create",
    delete: "eventAggregators.delete",
  },
  image: {
    read: "image.read",
    update: "image.update",
    upload: "image.upload",
    delete: "image.delete",
  },
  role: {
    read: "role.read",
    update: "role.update",
    create: "role.create",
    delete: "role.delete",
  },
  slide: {
    read: "slide.read",
    update: "slide.update",
    create: "slide.create",
    delete: "slide.delete",
  },
  staticPage: {
    read: "staticPage.read",
    update: "staticPage.update",
    create: "staticPage.create",
    delete: "staticPage.delete",
  },
  tag: {
    read: "tag.read",
    update: "tag.update",
    create: "tag.create",
    delete: "tag.delete",
  },
  seoTagset: {
    read: "seoTagset.read",
    update: "seoTagset.update",
  },
  user: {
    block: "user.block",
    create: "user.create",
    delete: "user.delete",
    read: "user.read",
    unblock: "user.unblock",
    update: "user.update",
  },
  organizer: {
    read: "organizer.read",
    update: "organizer.update",
    create: "organizer.create",
    delete: "organizer.delete",
  },
};
