import { useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { StaticPage } from "containers/StaticPages/static-pages";
import { STATIC_PAGES_SELECT } from "containers/StaticPages/static-pages.gql";
import { useAuth } from "contexts/auth-context";
import React from "react";
import { Ban } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import Select, { SelectProps } from "./select";

export default function StaticPagesSelect({
  disabled,
  ...rest
}: SelectProps): React.ReactElement {
  const { checkPermission } = useAuth();
  const [css] = useStyletron();

  const { data, loading } = useQuery(STATIC_PAGES_SELECT);

  const hasPermission = checkPermission(PERMISSIONS.staticPage.read);

  return (
    <Select
      options={data?.staticPages?.nodes?.map(({ id, name }: StaticPage) => ({
        id,
        label: name,
      }))}
      getValueLabel={({ option }) => `${option?.label}`}
      getOptionLabel={({ option }) => `${option?.label}`}
      placeholder={
        hasPermission ? (
          "Wybierz"
        ) : (
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              gap: " 5px",
            })}
          >
            <Ban size={16} />
            Brak uprawnień do przeglądania stron informacyjnych
          </div>
        )
      }
      disabled={disabled || !hasPermission}
      isLoading={loading}
      {...rest}
    />
  );
}
