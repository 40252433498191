import { gql } from "@apollo/client";

export const GROUPS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: FaqGroupFilter
    $sorting: [FaqGroupSort!]
  ) {
    faqGroups(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        slug
        isVisible
        position
        createdAt
        updatedAt
        createdBy {
          id
          firstName
          lastName
          email
        }
        updatedBy {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const GROUPS_SHOW = gql`
  query FaqGroupShow($id: Int!) {
    faqGroup(id: $id) {
      id
      name
      slug
      isVisible
      position
      createdAt
      updatedAt
      createdBy {
        id
        email
        firstName
        lastName
      }
      updatedBy {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

export const GROUPS_SELECT = gql`
  query($filter: FaqGroupFilter) {
    faqGroups(paging: { limit: 10000 }, filter: $filter) {
      nodes {
        id
        name
      }
    }
  }
`;

export const GROUPS_CREATE = gql`
  mutation FaqGroupCreate($faqGroupCreateInput: FaqGroupCreateInput!) {
    faqGroupCreate(faqGroupCreateInput: $faqGroupCreateInput) {
      id
    }
  }
`;

export const GROUPS_UPDATE = gql`
  mutation FaqGroupUpdate($faqGroupUpdateInput: FaqGroupUpdateInput!) {
    faqGroupUpdate(faqGroupUpdateInput: $faqGroupUpdateInput) {
      id
    }
  }
`;

export const GROUPS_DELETE = gql`
  mutation FaqGroupDelete($faqGroupDeleteInput: FaqGroupDeleteInput!) {
    faqGroupDelete(faqGroupDeleteInput: $faqGroupDeleteInput) {
      id
    }
  }
`;
