import { useStyletron } from "baseui";
import { InputProps } from "baseui/input";
import { Tooltip } from "components/tooltip";
import { useLoading } from "contexts/loading-context";
import React, { useState } from "react";
import { UseControllerProps, useFormContext } from "react-hook-form";
import { Lock, LockOpen } from "tabler-icons-react";

import { ControlledInput } from "./input";

export default function LockedInput({
  name,
  disabled,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & InputProps): React.ReactElement {
  const [css] = useStyletron();
  const [isInputLocked, setIsInputLocked] = useState(true);
  const { isLoading } = useLoading();

  const { control } = useFormContext();

  const iconStyle = {
    cursor: isLoading ? "default" : "pointer",
    color: isLoading ? "gray" : "black",
  };

  return (
    <ControlledInput
      {...rest}
      disabled={isInputLocked || disabled}
      name={name}
      control={control}
      endEnhancer={
        <Tooltip
          {...(!disabled && {
            content: isInputLocked ? "Odblokuj" : "Zablokuj",
          })}
          placement="right"
        >
          <span
            className={css({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            })}
            data-test-id={isInputLocked ? "unlock-input" : "lock-input"}
            {...(!disabled && {
              onClick: () => setIsInputLocked(!isInputLocked),
            })}
          >
            {isInputLocked ? (
              <Lock size={16} className={css(iconStyle)} />
            ) : (
              <LockOpen size={16} className={css(iconStyle)} />
            )}
          </span>
        </Tooltip>
      }
    />
  );
}
