import { format } from "date-fns/esm";

import { FiltersState, FilterType, SingleFilter } from "../filters.d";

function translateFilterToObject(
  instance: SingleFilter
): Record<string, unknown> {
  switch (instance.filter.type) {
    case FilterType.Activity:
      return {
        activity: {
          like: instance.state.value,
        },
      };

    case FilterType.Boolean:
    case FilterType.InvertedBoolean:
      return {
        [instance.filter.id]: {
          [!instance.state.not ? "is" : "isNot"]: !!instance.state.is,
        },
      };

    case FilterType.IsRedirectUrl:
      return {
        redirectUrl: {
          [!instance.state.is ? "is" : "isNot"]: null,
        },
      };

    case FilterType.Category:
      return {
        categories: {
          id: {
            eq: parseInt(instance.state.value),
          },
        },
      };

    case FilterType.CreatedBy:
      return {
        createdById: {
          eq: parseInt(instance.state.value),
        },
      };

    case FilterType.Date:
      switch (instance.state.kind) {
        case "between":
          return {
            [instance.filter.id]: {
              [!instance.state.not ? "between" : "notBetween"]: {
                lower: new Date(instance.state.from),
                upper: new Date(+new Date(instance.state.to) + 86400000),
              },
            },
          };

        default:
        case "single":
          return {
            [instance.filter.id]: {
              [!instance.state.not ? "between" : "notBetween"]: {
                lower: new Date(instance.state.in),
                upper: new Date(+new Date(instance.state.in) + 86400000),
              },
            },
          };
      }

    case FilterType.DictionaryValues:
      return {
        [instance.filter.id]: {
          like: `%${instance.state.value}%`,
        },
      };

    case FilterType.Role:
      return {
        roles: {
          id: {
            eq: parseInt(instance.state.value),
          },
        },
      };

    case FilterType.Group:
      return {
        groupId: {
          eq: parseInt(instance.state.value),
        },
      };

    case FilterType.Organizer:
      return {
        organizerId: {
          eq: parseInt(instance.state.value),
        },
      };

    case FilterType.Position:
      return {
        [instance.filter.id]: {
          eq: parseInt(instance.state.eq),
        },
      };

    case FilterType.ActivityLogsSubjectType:
      return {
        subjectType: {
          like: instance.state.value,
        },
      };

    case FilterType.SeoTagsetsSubjectType:
      return {
        subjectType: {
          like: instance.state.value,
        },
      };

    case FilterType.Text:
      return {
        [instance.filter.id]: {
          like: `%${instance.state.like}%`,
        },
      };

    case FilterType.UpdatedBy:
      return {
        updatedById: {
          eq: parseInt(instance.state.value),
        },
      };

    case FilterType.User:
      return {
        userId: {
          eq: parseInt(instance.state.value),
        },
      };

    default:
      return {
        [instance.filter.id]: {},
      };
  }
}

export function translateFiltersState(state: FiltersState): unknown {
  let object = {};

  state
    .filter((instance) => instance.filter.type !== FilterType.Text)
    .forEach((instance) => {
      if (Object.keys(object).length === 0) {
        object = {
          ...translateFilterToObject(instance),
        };
      } else {
        object = {
          ...translateFilterToObject(instance),
          and: object,
        };
      }
    });

  const textFilters = state
    .filter((instance) => instance.filter.type === FilterType.Text)
    .map((instance) => translateFilterToObject(instance));

  if (textFilters.length) {
    object = {
      or: textFilters,
      ...(Object.keys(object).length !== 0 && { and: object }),
    };
  }

  return object;
}

export function translateFilterToString(instance: SingleFilter): string {
  let string = `${instance.filter.label}: `;

  switch (instance.filter.type) {
    case FilterType.Activity:
      string += instance.state.label;
      break;

    case FilterType.Boolean:
    case FilterType.InvertedBoolean:
      string += instance.state.is ? "Tak" : "Nie";
      break;

    case FilterType.Category:
      string += instance.state.label;
      break;

    case FilterType.Position:
      string += instance.state.eq;
      break;

    case FilterType.IsRedirectUrl:
      string += instance.state.is ? "Tak" : "Nie";
      break;

    case FilterType.CreatedBy:
      string += instance.state.label;
      break;

    case FilterType.UpdatedBy:
      string += instance.state.label;
      break;

    case FilterType.Date:
      switch (instance.state.kind) {
        case "between":
          string += `${format(new Date(instance.state.from), "dd.MM.yyyy")} 
          — ${format(new Date(instance.state.to), "dd.MM.yyyy")}`;
          break;

        default:
        case "single":
          string += `${format(new Date(instance.state.in), "dd.MM.yyyy")}`;
          break;
      }
      break;

    case FilterType.DictionaryValues:
      string += instance.state.label;
      break;

    case FilterType.Role:
      string += instance.state.label;
      break;

    case FilterType.Group:
      string += instance.state.label;
      break;

    case FilterType.Organizer:
      string += instance.state.label;
      break;

    case FilterType.ActivityLogsSubjectType:
      string += instance.state.label;
      break;

    case FilterType.SeoTagsetsSubjectType:
      string += instance.state.label;
      break;

    case FilterType.User:
      string += instance.state.label;
      break;

    default:
      break;
  }

  return string;
}
