import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND, SIZE } from "baseui/button";
import { BottomPanel } from "components/bottom-panel";
import { Button } from "components/button";
import { Cell } from "components/cell";
import { Content } from "components/content";
import { Filters } from "components/filters";
import { DataType, FormattedValue } from "components/formatted-value";
import { Grid } from "components/grid";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { PagingControls } from "components/pagination";
import { Table } from "components/table";
import SortingTableHeader, {
  SortDirection,
} from "components/table/sorting-table-header";
import { Tooltip } from "components/tooltip";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { usePaging } from "contexts/paging-context";
import { BasicFilter, FiltersState } from "filters";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Row } from "react-table";
import { Eye, Plus } from "tabler-icons-react";
import { translateFiltersState } from "utils/filters";
import { PERMISSIONS } from "utils/permissions";
import { renderUserLabel } from "utils/render-user-label";

import { Event } from "../events";
import { EVENTS_FILTERS } from "../events.filters";
import { EventsField } from "../events.form";
import { EVENTS_INDEX, EVENTS_UPDATE } from "../events.gql";

export default function EventsIndex(): React.ReactElement {
  const { pageSize, currentPage, setTotalCount } = usePaging();
  const [sortBy, setSortBy] = useState<EventsField | null>(
    EventsField.Position
  );
  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    SortDirection.ASC
  );
  const { enqueueSnackbar } = useSnackbar();
  const {
    isFetching,
    setIsFetching,
    setIsLoading,
    isPartialFetching,
    setIsPartialFetching,
  } = useLoading();
  const history = useHistory();
  const { checkPermission } = useAuth();
  const [filters, setFilters] = useState<FiltersState>();
  const [css] = useStyletron();

  const handleSorting = (column: EventsField) => {
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const { refetch, data, loading, error } = useQuery(EVENTS_INDEX, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      filter: {
        and: [
          {
            ...(filters && (translateFiltersState(filters) as BasicFilter[])),
          },
        ],
      },
    },
  });

  const events: { totalCount: number; nodes: Event[] } = data?.events;

  useEffect(() => {
    if (data?.events) setTimeout(() => refetch(), 100);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    refetch();
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    refetch();
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.events) setIsFetching(false);
    if (events?.totalCount >= 0) setTotalCount(events?.totalCount);
  }, [data]);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  const [updateEvent] = useMutation(EVENTS_UPDATE);

  const onSubmit = async (event: Event, fieldName: string): Promise<void> => {
    setIsLoading(true);

    const updatedValues: Partial<Event> = {
      id: event.id,
      ...(fieldName === "isVisible" && { isVisible: !event.isVisible }),
      ...(fieldName === "showInSlider" && {
        showInSlider: !event.showInSlider,
      }),
      ...(fieldName === "displayAlternativeButton" && {
        displayAlternativeButton: !event.displayAlternativeButton,
      }),
    };

    let successMessage = "";

    switch (fieldName) {
      case "isVisible":
        updatedValues.isVisible = !event.isVisible;
        successMessage = `Wydarzenie "${event.name}" jest ${
          event.isVisible ? "niewidoczna" : "widoczna"
        }`;
        break;

      case "showInSlider":
        updatedValues.showInSlider = !event.showInSlider;
        successMessage = `Wydarzenie "${event.name}" w menu głównym jest ${
          event.showInSlider ? "niewidoczna" : "widoczna"
        }`;
        break;

      case "displayAlternativeButton":
        updatedValues.displayAlternativeButton = !event.displayAlternativeButton;
        successMessage = `Dla wydarzenia "${
          event.name
        }" alternatywny przycisk jest ${
          event.displayAlternativeButton ? "niewidoczny" : "widoczny"
        }`;
        break;

      default:
        break;
    }

    try {
      await updateEvent({
        variables: {
          eventUpdateInput: updatedValues,
        },
      });
      enqueueSnackbar({
        message: successMessage,
        variant: "success",
      });
      refetch();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventsField.Name)}
            sortDirection={sortBy === EventsField.Name ? sortDirection : null}
          >
            Nazwa
          </SortingTableHeader>
        ),
        accessor: EventsField.Name,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue dataType={DataType.Events} data={row.original.id}>
            {row.original.name}
          </FormattedValue>
        ),
        disableGlobalFilter: true,
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventsField.Slug)}
            sortDirection={sortBy === EventsField.Slug ? sortDirection : null}
          >
            Slug
          </SortingTableHeader>
        ),
        accessor: EventsField.Slug,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue dataType={DataType.Pre} $style={{ fontSize: "12px" }}>
            {row.original.slug}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventsField.RedirectUrl)}
            sortDirection={
              sortBy === EventsField.RedirectUrl ? sortDirection : null
            }
          >
            Link do przekierowania zewnętrznego
          </SortingTableHeader>
        ),
        accessor: EventsField.RedirectUrl,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue dataType={DataType.Pre} $style={{ fontSize: "12px" }}>
            {row.original.redirectUrl}
          </FormattedValue>
        ),
      },
      {
        Header: "Kategoria",
        accessor: EventsField.Categories,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue
            dataType={DataType.Categories}
            data={row.original.categories?.[0]?.id}
          >
            {row.original.categories?.[0]?.name}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventsField.LocationName)}
            sortDirection={
              sortBy === EventsField.LocationName ? sortDirection : null
            }
          >
            Lokalizacja
          </SortingTableHeader>
        ),
        accessor: EventsField.LocationName,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue>{row.original.locationName}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventsField.BiletynaId)}
            sortDirection={
              sortBy === EventsField.BiletynaId ? sortDirection : null
            }
          >
            ID wydarzenia w systemie Biletyna
          </SortingTableHeader>
        ),
        accessor: EventsField.BiletynaId,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue>{row.original.biletynaId}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventsField.StartedAt)}
            sortDirection={
              sortBy === EventsField.StartedAt ? sortDirection : null
            }
          >
            Rozpoczęcie wydarzenia
          </SortingTableHeader>
        ),
        accessor: EventsField.StartedAt,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue dataType={DataType.Date}>
            {row.original.startedAt}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventsField.EndedAt)}
            sortDirection={
              sortBy === EventsField.EndedAt ? sortDirection : null
            }
          >
            Zakończenie wydarzenia
          </SortingTableHeader>
        ),
        accessor: EventsField.EndedAt,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue dataType={DataType.Date}>
            {row.original.endedAt}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventsField.StartingPrice)}
            sortDirection={
              sortBy === EventsField.StartingPrice ? sortDirection : null
            }
          >
            Cena od
          </SortingTableHeader>
        ),
        accessor: EventsField.StartingPrice,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue dataType={DataType.Price}>
            {row.original?.startingPrice?.toFixed(2)}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventsField.SaleStartedAt)}
            sortDirection={
              sortBy === EventsField.SaleStartedAt ? sortDirection : null
            }
          >
            Rozpoczęcie sprzedaży
          </SortingTableHeader>
        ),
        accessor: EventsField.SaleStartedAt,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue dataType={DataType.Date}>
            {row.original.saleStartedAt}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventsField.SaleEndedAt)}
            sortDirection={
              sortBy === EventsField.SaleEndedAt ? sortDirection : null
            }
          >
            Zakończenie sprzedaży
          </SortingTableHeader>
        ),
        accessor: EventsField.SaleEndedAt,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue dataType={DataType.Date}>
            {row.original.saleEndedAt}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventsField.IsSaleBlocked)}
            sortDirection={
              sortBy === EventsField.IsSaleBlocked ? sortDirection : null
            }
          >
            Blokada sprzedaży
          </SortingTableHeader>
        ),
        accessor: EventsField.IsSaleBlocked,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue dataType={DataType.InvertedBoolean}>
            {row.original.isSaleBlocked}
          </FormattedValue>
        ),
      },
      {
        Header: "Organizator",
        accessor: EventsField.Organizer,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue
            dataType={DataType.Organizers}
            data={row.original.organizer.id}
          >
            {row.original.organizer.name}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventsField.CreatedAt)}
            sortDirection={
              sortBy === EventsField.CreatedAt ? sortDirection : null
            }
          >
            Utworzenie
          </SortingTableHeader>
        ),
        accessor: EventsField.CreatedAt,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue dataType={DataType.Date}>
            {row.original.createdAt}
          </FormattedValue>
        ),
      },
      {
        Header: "Utworzono przez",
        accessor: EventsField.CreatedBy,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue
            dataType={DataType.Users}
            data={row.original?.createdBy?.id}
          >
            {row.original?.createdBy
              ? renderUserLabel(row.original.createdBy)
              : undefined}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventsField.UpdatedAt)}
            sortDirection={
              sortBy === EventsField.UpdatedAt ? sortDirection : null
            }
          >
            Ostatnia aktualizacja
          </SortingTableHeader>
        ),
        accessor: EventsField.UpdatedAt,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue dataType={DataType.Date}>
            {row.original.updatedAt}
          </FormattedValue>
        ),
      },
      {
        Header: "Zaktualizowano przez",
        accessor: EventsField.UpdatedBy,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue
            dataType={DataType.Users}
            data={row.original?.updatedBy?.id}
          >
            {row.original?.updatedBy
              ? renderUserLabel(row.original.updatedBy)
              : undefined}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventsField.IsVisible)}
            sortDirection={
              sortBy === EventsField.IsVisible ? sortDirection : null
            }
          >
            Widoczność
          </SortingTableHeader>
        ),
        accessor: EventsField.IsVisible,
        Cell: ({ row }: { row: Row<Event> }) => (
          <Tooltip
            content={`Ustaw jako ${
              row.original.isVisible ? "niewidoczne" : "widoczne"
            }`}
            placement="right"
          >
            <span
              onClick={() => onSubmit(row.original, "isVisible")}
              className={css({
                cursor: "pointer",
              })}
            >
              <FormattedValue dataType={DataType.VisibilityBoolean}>
                {row.original.isVisible}
              </FormattedValue>
            </span>
          </Tooltip>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventsField.ShowInSlider)}
            sortDirection={
              sortBy === EventsField.ShowInSlider ? sortDirection : null
            }
          >
            Widoczność w sliderze
          </SortingTableHeader>
        ),
        accessor: EventsField.ShowInSlider,
        Cell: ({ row }: { row: Row<Event> }) => (
          <Tooltip
            content={`Ustaw jako ${
              row.original.showInSlider ? "niewidoczne" : "widoczne"
            }`}
            placement="right"
          >
            <span
              onClick={() => onSubmit(row.original, "showInSlider")}
              className={css({
                cursor: "pointer",
              })}
            >
              <FormattedValue dataType={DataType.VisibilityBoolean}>
                {row.original.showInSlider}
              </FormattedValue>
            </span>
          </Tooltip>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventsField.Position)}
            sortDirection={
              sortBy === EventsField.Position ? sortDirection : null
            }
          >
            Pozycja
          </SortingTableHeader>
        ),
        accessor: EventsField.Position,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue dataType={DataType.Pre} $style={{ fontSize: "12px" }}>
            {row.original.position}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventsField.SliderPosition)}
            sortDirection={
              sortBy === EventsField.SliderPosition ? sortDirection : null
            }
          >
            Pozycja w sliderze
          </SortingTableHeader>
        ),
        accessor: EventsField.SliderPosition,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue dataType={DataType.Pre} $style={{ fontSize: "12px" }}>
            {row.original.sliderPosition}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(EventsField.VisibleAt)}
            sortDirection={
              sortBy === EventsField.VisibleAt ? sortDirection : null
            }
          >
            Data widoczności
          </SortingTableHeader>
        ),
        accessor: EventsField.VisibleAt,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue dataType={DataType.Date}>
            {row.original.visibleAt}
          </FormattedValue>
        ),
      },
      {
        Header: "Pokaż alternatywny przycisk",
        accessor: EventsField.DisplayAlternativeButton,
        Cell: ({ row }: { row: Row<Event> }) => (
          <Tooltip
            content={`Ustaw jako ${
              row.original.displayAlternativeButton ? "niewidoczny" : "widoczny"
            }`}
            placement="right"
          >
            <span
              onClick={() => onSubmit(row.original, "displayAlternativeButton")}
              className={css({
                cursor: "pointer",
              })}
            >
              <FormattedValue dataType={DataType.VisibilityBoolean}>
                {row.original.displayAlternativeButton}
              </FormattedValue>
            </span>
          </Tooltip>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<Event> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $style={{ marginLeft: "6px" }}
              onClick={() => history.push(`/events/${row.original?.id}`)}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [sortBy, sortDirection]
  );

  if (!checkPermission(PERMISSIONS.event.read))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title="Wydarzenia"
        recordsNum={events?.totalCount}
        labels={["Lista"]}
        buttons={[
          {
            label: "Dodaj wydarzenie",
            kind: KIND.primary,
            startEnhancer: <Plus size={18} />,
            permission: checkPermission(PERMISSIONS.event.create),
            onClick: () => history.push("/events/create"),
          },
        ]}
      />
      <Filters filters={EVENTS_FILTERS} state={filters} setState={setFilters} />
      <Content filtersOffset>
        <Grid>
          <Cell span={12}>
            <Table<Event>
              columns={columns}
              data={events?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>
          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
