const defaultColumns: { [pathname: string]: string[] } = {
  "/static-pages": ["name", "createdAt", "isVisible"],
  "/categories": ["name", "appearance", "createdAt", "isVisible", "actions"],
  "/events": [
    "name",
    "locationName",
    "createdAt",
    "category",
    "isVisible",
    "actions",
  ],
  "/groups": ["name", "slug", "isVisible", "actions"],
  "/entries": ["name", "slug", "isVisible", "actions"],
  "/slides": ["title", "createdAt", "isVisible"],
  "/images": ["originalName", "createdAt"],
  "/tags": ["name", "createdAt", "isVisible"],
  "/users": ["fullName", "email", "status", "roles", "actions"],
  "/organizers": ["name", "slug", "actions"],
  pathname: ["id"],
};

export function getDefaultColumns(pathname: string): string[] | undefined {
  return defaultColumns?.[pathname];
}
