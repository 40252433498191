import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { KIND } from "baseui/button";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { FormRenderer } from "components/form-renderer";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { DeviceFloppy, FileOff } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";
import { scrollOnError } from "utils/scrollOnError";

import { Event } from "../events";
import { EVENTS_FIELDS, EventsField, EventsFormInputs } from "../events.form";
import { EVENTS_SHOW, EVENTS_UPDATE } from "../events.gql";

export default function EventsUpdate(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const { enqueueSnackbar } = useSnackbar();
  const {
    setIsFetching,
    isLoading,
    setIsLoading,
    setIsPartialFetching,
  } = useLoading();
  const { checkPermission } = useAuth();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const {
    control,
    formState: { errors, dirtyFields, isDirty, ...formState },
    handleSubmit,
    setValue,
    reset,
    watch,
    register,
    unregister,
    ...methods
  } = useForm<EventsFormInputs>();

  const { refetch, data, error: queryError } = useQuery(EVENTS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
  });
  const event: Event = data?.event;

  useEffect(() => {
    setIsFetching(true);
    if (data?.event) refetch();
  }, []);

  useEffect(() => {
    if (watch(EventsField.IsRedirectUrl)) {
      register(EventsField.RedirectUrl);
    } else {
      unregister(EventsField.RedirectUrl);
      setValue(EventsField.RedirectUrl, "" as never);
    }
  }, [watch(EventsField.IsRedirectUrl)]);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    async function setFile(fieldName: string, url: string) {
      const response = await fetch(url + "?cropper");
      const data = await response.blob();
      const extension = /\.(jpe?g|png|gif|svg)$/gi.exec(url)?.[1];
      const metadata = {
        type: `image/${extension === "svg" ? "svg+xml" : "png"}`,
      };

      const file = new File([data], `${event?.id}.${extension}`, metadata);

      if (url) setValue(fieldName as keyof EventsFormInputs, file);
    }

    if (event) {
      setValue("name", event?.name);
      setValue("slug", event?.slug);
      setValue("locationName", event?.locationName);
      setValue("biletynaId", event?.biletynaId);
      setValue("startingPrice", event?.startingPrice);
      setValue("isVisible", event?.isVisible);
      setValue("isSaleBlocked", event?.isSaleBlocked);
      event?.termsOfServiceUrl &&
        setValue("termsOfService", [
          ({ name: "Usuń aktualny plik" } as unknown) as File,
        ]);
      event?.startedAt && setValue("startedAt", new Date(event?.startedAt));
      event?.endedAt && setValue("endedAt", new Date(event?.endedAt));
      event?.saleStartedAt &&
        setValue("saleStartedAt", new Date(event?.saleStartedAt));
      event?.saleEndedAt &&
        setValue("saleEndedAt", new Date(event?.saleEndedAt));
      event?.visibleAt && setValue("visibleAt", new Date(event?.visibleAt));
      setValue("content", event?.content);
      setValue("categories", event?.categories);
      setValue("organizers", [
        { id: event?.organizerId, label: event?.organizer?.name },
      ]);
      setValue("metaTitle", event?.seoTagset?.metaTitle);
      setValue("position", event?.position);
      setValue("sliderPosition", event?.sliderPosition);
      setValue("showInSlider", event?.showInSlider);
      setValue("metaDescription", event?.seoTagset?.metaDescription);
      setValue("ogTitle", event?.seoTagset?.ogTitle);
      setValue("ogDescription", event?.seoTagset?.ogDescription);
      setValue("metaKeywords", event?.seoTagset?.metaKeywords);
      setValue("canonical", event?.seoTagset?.canonical);
      setValue("redirectUrl", event?.redirectUrl ?? "");
      setValue("isRedirectUrl", !!event?.redirectUrl);

      setValue("displayAlternativeButton", !!event?.displayAlternativeButton);
      event?.alternativeButtonText &&
        setValue("alternativeButtonText", event.alternativeButtonText);
      event?.alternativeButtonUrl &&
        setValue("alternativeButtonUrl", event.alternativeButtonUrl);

      !!event?.seoTagset?.robots &&
        setValue("robots", [
          {
            id: event?.seoTagset?.robots,
            label: event?.seoTagset?.robots,
          },
        ]);

      setTimeout(() => setIsFetching(false), 250);

      setTimeout(() => {
        setIsPartialFetching(true);

        Promise.all([
          setFile("verticalPoster", event?.verticalPosterUrl),
          setFile("horizontalPoster", event?.horizontalPosterUrl),
          setFile("ogImage", event?.seoTagset?.ogImageUrl),
        ]).then(() => setIsPartialFetching(false));
      }, 500);
    }
  }, [data]);

  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  useEffect(() => {
    if (errors) scrollOnError(errors, EVENTS_FIELDS, "update");
  }, [errorTimeStamp]);

  useEffect(() => {
    if (watch(EventsField.IsRedirectUrl)) {
      register(EventsField.RedirectUrl);
    } else {
      unregister(EventsField.RedirectUrl);
      setValue(EventsField.RedirectUrl, "" as never);
    }
  }, [watch(EventsField.IsRedirectUrl)]);

  const [updateEvent, { error }] = useMutation(EVENTS_UPDATE);

  const onSubmit = async ({
    startingPrice,
    categories,
    isVisible,
    isSaleBlocked,
    metaTitle,
    showInSlider,
    metaDescription,
    organizers,
    metaKeywords,
    ogTitle,
    ogDescription,
    ogImage,
    termsOfService,
    canonical,
    robots,
    horizontalPoster,
    verticalPoster,
    isRedirectUrl,
    ...values
  }: EventsFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await updateEvent({
        variables: {
          eventUpdateInput: {
            id: id ? parseInt(id) : null,
            startingPrice: parseFloat(startingPrice as string) || null,
            showInSlider: !!showInSlider,
            isVisible: !!isVisible,
            ...(dirtyFields.termsOfService && {
              termsOfService: termsOfService?.[0] ? termsOfService?.[0] : null,
            }),
            isSaleBlocked: !!isSaleBlocked,
            categoryIds: categories?.[0]?.id || [],
            organizerId: organizers?.length ? organizers?.[0]?.id : null,
            ...(dirtyFields.horizontalPoster && { horizontalPoster }),
            ...(dirtyFields.verticalPoster && { verticalPoster }),
            ...values,
          },
          seoTagsetUpsertInput: {
            metaTitle,
            metaDescription,
            metaKeywords,
            ogTitle,
            ogDescription,
            ogImage: ogImage || null,
            canonical,
            robots: robots?.length ? robots?.[0]?.label : null,
          },
        },
      });
      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.push(`/events/${id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.event.update))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title={event?.name}
        labels={["Wydarzenia", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit, onError),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "updateEvent",
          },
        ]}
      />
      <Content>
        <FormProvider<EventsFormInputs>
          control={control}
          formState={{ errors, dirtyFields, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          register={register}
          unregister={unregister}
          setValue={setValue}
          watch={watch}
          {...methods}
        >
          <FormRenderer<EventsFormInputs>
            id="updateEvent"
            onSubmit={handleSubmit(onSubmit, onError)}
            type="update"
            isLoading={isLoading}
            error={error}
            fields={EVENTS_FIELDS}
          />
        </FormProvider>
        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie edycji wydarzenia"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
      </Content>
    </article>
  );
}
