import { gql } from "@apollo/client";

export const STATIC_PAGES_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: StaticPageFilter
    $sorting: [StaticPageSort!]
  ) {
    staticPages(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        createdAt
        updatedAt
        showInMainMenu
        showInFooterMenu
        position
        isVisible
        slug
        redirectUrl
      }
    }
  }
`;

export const STATIC_PAGES_SHOW = gql`
  query StaticPageShow($id: Int!) {
    staticPage(id: $id) {
      id
      name
      slug
      content
      redirectUrl
      isVisible
      showInMainMenu
      showInFooterMenu
      position
      createdAt
      updatedAt
      seoTagset {
        id
        metaTitle
        metaDescription
        metaKeywords
        ogTitle
        ogDescription
        ogImageUrl
        canonical
        robots
      }
    }
  }
`;

export const STATIC_PAGES_SELECT = gql`
  query($filter: StaticPageFilter) {
    staticPages(paging: { limit: 10000 }, filter: $filter) {
      nodes {
        id
        name
      }
    }
  }
`;

export const STATIC_PAGES_CREATE = gql`
  mutation StaticPageCreate(
    $staticPageCreateInput: StaticPageCreateInput!
    $seoTagsetUpsertInput: SeoTagsetUpsertInput
  ) {
    staticPageCreate(
      staticPageCreateInput: $staticPageCreateInput
      seoTagsetUpsertInput: $seoTagsetUpsertInput
    ) {
      id
    }
  }
`;

export const STATIC_PAGES_UPDATE = gql`
  mutation StaticPageUpdate(
    $staticPageUpdateInput: StaticPageUpdateInput!
    $seoTagsetUpsertInput: SeoTagsetUpsertInput
  ) {
    staticPageUpdate(
      staticPageUpdateInput: $staticPageUpdateInput
      seoTagsetUpsertInput: $seoTagsetUpsertInput
    ) {
      id
    }
  }
`;

export const STATIC_PAGES_DELETE = gql`
  mutation StaticPageDelete($staticPageDeleteInput: StaticPageDeleteInput!) {
    staticPageDelete(staticPageDeleteInput: $staticPageDeleteInput) {
      id
    }
  }
`;
