import { gql } from "@apollo/client";

export const ORGANIZERS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: OrganizerFilter
    $sorting: [OrganizerSort!]
  ) {
    organizers(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        slug
        updatedAt
        createdAt
        createdBy {
          id
          email
          firstName
          lastName
        }
        updatedBy {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;

export const ORGANIZERS_SHOW = gql`
  query OrganizersShow($id: Int!) {
    organizer(id: $id) {
      id
      name
      slug
      createdAt
      updatedAt
      createdBy {
        id
        email
        firstName
        lastName
      }
      updatedBy {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

export const ORGANIZERS_SELECT = gql`
  query($filter: OrganizerFilter) {
    organizers(paging: { limit: 10000 }, filter: $filter) {
      nodes {
        id
        name
      }
    }
  }
`;

export const ORGANIZERS_CREATE = gql`
  mutation OrganizerCreate($organizerCreateInput: OrganizerCreateInput!) {
    organizerCreate(organizerCreateInput: $organizerCreateInput) {
      id
    }
  }
`;

export const ORGANIZERS_UPDATE = gql`
  mutation OrganizerUpdate($organizerUpdateInput: OrganizerUpdateInput!) {
    organizerUpdate(organizerUpdateInput: $organizerUpdateInput) {
      id
    }
  }
`;

export const ORGANIZERS_DELETE = gql`
  mutation OrganizerDelete($organizerDeleteInput: OrganizerDeleteInput!) {
    organizerDelete(organizerDeleteInput: $organizerDeleteInput) {
      id
    }
  }
`;
