import { ApolloError, useMutation } from "@apollo/client";
import { InputValidationError } from "api";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { Button } from "components/button";
import { ControlledCheckbox } from "components/checkbox";
import { FormControl } from "components/form-control";
import { ControlledInput } from "components/input";
import { Modal } from "components/modal";
import { FieldType } from "fields.d";
import { FormErrors } from "form";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FORM_VALIDATION_MESSAGES } from "utils/form/validation-messages";

import { INPUT_VALIDATION_ERROR } from "../../../constants";
import {
  DICTIONARY_VALUES_FIELDS,
  DictionaryValuesFormInputs,
} from "../dictionaries.form";
import { DICTIONARY_VALUE_CREATE } from "../dictionaries.gql";

type Props = {
  close: () => void;
  dictionaryId?: number;
  isOpen: boolean;
  onCompleted: () => void;
};

export default function ValueCreate({
  close,
  dictionaryId,
  isOpen,
  onCompleted,
}: Props): React.ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [css] = useStyletron();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<DictionaryValuesFormInputs>({
    defaultValues: {
      name: "",
      value: "",
      isHidden: false,
    },
  });

  const [createValue, { loading, error }] = useMutation(
    DICTIONARY_VALUE_CREATE
  );

  const onSubmit = async (
    values: DictionaryValuesFormInputs
  ): Promise<void> => {
    setIsLoading(true);

    try {
      await createValue({
        variables: {
          dictionaryValueCreateInput: {
            dictionaryId,
            isSystem: false,
            ...values,
          },
        },
      });

      enqueueSnackbar({
        message: "Wartość została dodana",
        variant: "success",
      });
      close();
      reset();
      onCompleted();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal onClose={close} isOpen={isOpen}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Dodanie wartości</ModalHeader>
        <ModalBody>
          {DICTIONARY_VALUES_FIELDS.filter(
            (g) => g.fields.filter((f) => f.create.visible).length > 0
          ).map((group) => [
            group.fields
              .filter((f) => f.create.visible)
              .map((item, index) => (
                <FormControl
                  key={group.id + `-field` + index}
                  label={item.label}
                  caption={item.caption}
                  required={item.create.required}
                  error={
                    ((errors as FormErrors<DictionaryValuesFormInputs>)[
                      item.id as keyof DictionaryValuesFormInputs
                    ] &&
                      (errors as FormErrors<DictionaryValuesFormInputs>)[
                        item.id as keyof DictionaryValuesFormInputs
                      ]?.message) ||
                    (error &&
                      error.graphQLErrors[0]?.extensions?.code ===
                        INPUT_VALIDATION_ERROR &&
                      error.graphQLErrors[0]?.extensions?.validationErrors
                        ?.find(
                          (vE: InputValidationError) => vE?.property === item.id
                        )
                        ?.errors.map((message: string) => (
                          <div
                            key="error"
                            className={css({
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            })}
                          >
                            {message}
                          </div>
                        ))[0])
                  }
                  disabled={isLoading}
                >
                  {item.type === FieldType.Checkbox ? (
                    <ControlledCheckbox name={item.id} control={control}>
                      Ukryta
                    </ControlledCheckbox>
                  ) : (
                    <ControlledInput
                      control={control}
                      name={item.id}
                      id={item.id}
                      placeholder={item.placeholder}
                      {...(item.create.required && {
                        rules: {
                          required: FORM_VALIDATION_MESSAGES.required,
                        },
                      })}
                    />
                  )}
                </FormControl>
              )),
          ])}
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            kind={KIND.secondary}
            $style={{ marginRight: "10px" }}
            onClick={close}
            isLoading={loading}
          >
            Anuluj
          </Button>
          <Button type="submit" kind={KIND.primary} isLoading={loading}>
            Utwórz
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
