import { OutputData } from "@editorjs/editorjs";
import { DataType } from "components/formatted-value";
import { SelectValue } from "components/select/select";
import { Category } from "containers/Categories/categories";
import { SeoTagsetsField } from "containers/SeoTagsets/seo-tagsets.form";

import { DictionarySystemName } from "../../constants";
import { FieldsGroup, FieldType } from "../../fields.d";

export type EventsFormInputs = {
  name: string;
  slug: string;
  verticalPoster: File;
  horizontalPoster: File;
  content: OutputData[];
  locationName: string;
  locationLatitude: number | string;
  locationLongitude: number | string;
  startedAt: Date;
  endedAt: Date;
  position: number;
  sliderPosition: number;
  showInSlider: boolean;
  startingPrice: number | string;
  biletynaId: string;
  isVisible: boolean;
  visibleAt: Date;
  saleStartedAt: Date;
  saleEndedAt: Date;
  isSaleBlocked: boolean;
  isRedirectUrl: boolean;
  categories: Category[];
  metaTitle: string;
  metaDescription: string;
  metaKeywords: string;
  ogTitle: string;
  ogDescription: string;
  ogImage: File;
  canonical: string;
  robots: SelectValue;
  organizers: SelectValue;
  termsOfService: File[];
  redirectUrl: string;
  displayAlternativeButton: boolean;
  alternativeButtonText: string;
  alternativeButtonUrl: string;
};

export enum EventsField {
  Name = "name",
  Slug = "slug",
  VerticalPoster = "verticalPoster",
  VerticalPosterUrl = "verticalPosterUrl",
  HorizontalPoster = "horizontalPoster",
  HorizontalPosterUrl = "horizontalPosterUrl",
  Content = "content",
  LocationName = "locationName",
  LocationLatitude = "locationLatitude",
  locationLongitude = "locationLongitude",
  StartedAt = "startedAt",
  EndedAt = "endedAt",
  StartingPrice = "startingPrice",
  BiletynaId = "biletynaId",
  IsVisible = "isVisible",
  VisibleAt = "visibleAt",
  SaleStartedAt = "saleStartedAt",
  SaleEndedAt = "saleEndedAt",
  IsSaleBlocked = "isSaleBlocked",
  Categories = "categories",
  CreatedAt = "createdAt",
  CreatedBy = "createdBy",
  UpdatedAt = "updatedAt",
  UpdatedBy = "updatedBy",
  DeletedAt = "deletedAt",
  SeoTagset = "seoTagset",
  Organizers = "organizers",
  Organizer = "organizer",
  SliderPosition = "sliderPosition",
  ShowInSlider = "showInSlider",
  Position = "position",
  TermsOfService = "termsOfService",
  TermsOfServiceUrl = "termsOfServiceUrl",
  IsRedirectUrl = "isRedirectUrl",
  RedirectUrl = "redirectUrl",
  DisplayAlternativeButton = "displayAlternativeButton",
  AlternativeButtonText = "alternativeButtonText",
  AlternativeButtonUrl = "alternativeButtonUrl",
}

export const EVENTS_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: EventsField.Name,
        label: "Nazwa",
        type: FieldType.BigInput,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: EventsField.Slug,
        label: "Slug",
        type: FieldType.SlugInput,
        dataType: DataType.Pre,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        update: { visible: false },
        caption:
          "Slug jest generowany automatycznie na podstawie nazwy. Możesz go zmienić, edytując powyższe pole.",
      },
      {
        id: EventsField.Slug,
        label: "Slug",
        type: FieldType.SlugInput,
        dataType: DataType.Pre,
        span: 12,
        show: { visible: false },
        create: { visible: false },
        update: { visible: true },
        caption: "Możesz zmienić slug, edytując powyższe pole",
      },
      {
        id: EventsField.IsRedirectUrl,
        label: "Przekierowanie zewnętrzne",
        span: 12,
        type: FieldType.Checkbox,
        dataType: DataType.Boolean,
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: EventsField.RedirectUrl,
        label: "Link do przekierowania zewnętrznego",
        dataType: DataType.Link,
        type: FieldType.Input,
        caption: "Przekieruj wydarzenie do zewnętrznej lokalizacji",
        span: 12,
        show: { visible: true },
        create: { visible: true, required: false },
        update: { visible: true, required: false },
      },
      {
        id: EventsField.Slug,
        label: "Link do wydarzenia",
        type: FieldType.Link,
        span: 12,
        show: { visible: true },
        caption:
          "Link do udostępnienia. Uwaga: Jeśli widoczność jest ustawiona na „nie”, pozycja nie jest dostępna publicznie",
        create: { visible: false, required: false },
        update: { visible: false, required: false },
      },
      {
        id: EventsField.LocationName,
        label: "Lokalizacja",
        type: FieldType.Input,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: EventsField.Categories,
        label: "Kategoria",
        span: 12,
        type: FieldType.CategorySelect,
        dataType: DataType.Categories,
        show: { visible: false },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: EventsField.Categories,
        label: "Kategoria",
        span: 12,
        type: FieldType.Multi,
        dataType: DataType.Categories,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: EventsField.BiletynaId,
        label: "ID wydarzenia w systemie Biletyna",
        type: FieldType.Input,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        update: { visible: false },
      },
      {
        id: EventsField.BiletynaId,
        label: "ID wydarzenia w systemie Biletyna",
        type: FieldType.LockedInput,
        span: 6,
        show: { visible: false },
        create: { visible: false },
        update: { visible: true },
      },
      {
        id: EventsField.LocationLatitude,
        label: "Szerokość geograficzna",
        type: FieldType.NumberInput,
        span: 4,
        show: { visible: false },
        create: { visible: false, required: false },
        update: { visible: false, required: false },
      },
      {
        id: EventsField.locationLongitude,
        label: "Długość geograficzna",
        type: FieldType.NumberInput,
        span: 4,
        show: { visible: false },
        create: { visible: false, required: false },
        update: { visible: false, required: false },
      },
      {
        id: EventsField.StartingPrice,
        label: "Cena od",
        type: FieldType.NumberInput,
        dataType: DataType.Price,
        endEnhancer: "PLN",
        span: 6,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: EventsField.IsVisible,
        label: "Widoczność",
        type: FieldType.Switch,
        dataType: DataType.VisibilityBoolean,
        span: 6,
        caption: "Widoczność określa, czy ta pozycja jest publiczna",
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: EventsField.VisibleAt,
        label: "Data widoczności",
        span: 6,
        type: FieldType.DatePicker,
        withTime: true,
        dataType: DataType.DateTime,
        caption: "Data określa, od kiedy wydarzenie jest widoczne publicznie",
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: EventsField.Organizers,
        label: "Organizator",
        type: FieldType.OrganizersSelect,
        span: 12,
        show: { visible: false },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: EventsField.Organizer,
        label: "Organizator",
        type: FieldType.Input,
        dataType: DataType.Organizers,
        span: 12,
        show: { visible: true, accessor: ["organizer", "name"] },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: EventsField.IsSaleBlocked,
        label: "Blokada sprzedaży",
        span: 12,
        type: FieldType.Checkbox,
        dataType: DataType.InvertedBoolean,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "display",
    label: "Wyświetlanie",
    fields: [
      {
        id: EventsField.Position,
        label: "Pozycja",
        type: FieldType.NumberInput,
        caption:
          "Globalna kolejność wyświetlania wydarzeń (np. lista, agregatory, wyszukiwarka)",
        span: 4,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true, required: true },
      },
      {
        id: EventsField.ShowInSlider,
        label: "Pokaż w sliderze",
        type: FieldType.Switch,
        dataType: DataType.VisibilityBoolean,
        span: 4,
        caption: "Widoczność określa, czy ta pozycja jest widoczna w sliderze",
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: EventsField.SliderPosition,
        label: "Pozycja w sliderze",
        type: FieldType.NumberInput,
        span: 4,
        caption:
          "Kolejność wyświetlania wydarzeń w sliderze na stronie głównej",
        show: { visible: true },
        create: { visible: true },
        update: { visible: true, required: true },
      },
    ],
  },
  {
    id: "alternativeButton",
    label: "Alternatywny przycisk zakupu biletów",
    fields: [
      {
        id: EventsField.DisplayAlternativeButton,
        label: "Pokaż przycisk alternatywny",
        type: FieldType.Switch,
        dataType: DataType.VisibilityBoolean,
        span: 4,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: EventsField.AlternativeButtonText,
        label: "Tekst przycisku",
        type: FieldType.TextArea,
        rows: 2,
        span: 4,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: EventsField.AlternativeButtonUrl,
        label: "Link przycisku",
        type: FieldType.Input,
        dataType: DataType.Link,
        span: 4,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "dates",
    label: "Daty",
    fields: [
      {
        id: EventsField.StartedAt,
        label: "Rozpoczęcie wydarzenia",
        span: 6,
        type: FieldType.DatePicker,
        withTime: true,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
        minDate: new Date(),
        maxDate: EventsField.EndedAt,
      },
      {
        id: EventsField.EndedAt,
        label: "Zakończenie wydarzenia",
        span: 6,
        type: FieldType.DatePicker,
        withTime: true,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
        minDate: EventsField.StartedAt,
      },
      {
        id: EventsField.SaleStartedAt,
        label: "Rozpoczęcie sprzedaży",
        span: 6,
        type: FieldType.DatePicker,
        withTime: true,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
        minDate: new Date(),
        maxDate: EventsField.SaleEndedAt,
      },
      {
        id: EventsField.SaleEndedAt,
        label: "Zakończenie sprzedaży",
        span: 6,
        type: FieldType.DatePicker,
        withTime: true,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
        minDate: EventsField.SaleStartedAt,
      },
    ],
  },
  {
    id: "content",
    label: "Treść",
    fields: [
      {
        id: EventsField.Content,
        label: "",
        span: 12,
        type: FieldType.Editor,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "documents",
    label: "Dokumenty",
    fields: [
      {
        id: EventsField.TermsOfServiceUrl,
        label: "Regulamin",
        type: FieldType.FilesList,
        dataType: DataType.FileDownload,
        span: 12,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: EventsField.TermsOfService,
        label: "Regulamin",
        type: FieldType.FilesPicker,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "images",
    label: "Media",
    fields: [
      {
        id: EventsField.VerticalPosterUrl,
        label: "Pionowy plakat",
        type: FieldType.Image,
        span: 12,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: EventsField.VerticalPoster,
        label: "Pionowy plakat",
        type: FieldType.ImageCropper,
        imageRequirements: {
          aspect: 3 / 4,
        },
        caption: "Proporcje kadru: 3:4",
        span: 12,
        show: { visible: false },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: EventsField.HorizontalPosterUrl,
        label: "Poziomy plakat",
        type: FieldType.Image,
        span: 12,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: EventsField.HorizontalPoster,
        label: "Poziomy plakat",
        type: FieldType.ImageCropper,
        imageRequirements: {
          aspect: 1920 / 915,
        },
        caption: "Proporcje kadru: 2.1:1",
        span: 12,
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "seo",
    label: "Tagi SEO",
    fields: [
      {
        id: SeoTagsetsField.MetaTitle,
        label: "Tytuł meta",
        caption:
          "Tytuł strony wyświetlany na pasku przeglądarki i w wynikach wyszukiwania. Maks. 70 znaków ze spacjami",
        maxLength: 70,
        span: 6,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [EventsField.SeoTagset, SeoTagsetsField.MetaTitle],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.OgTitle,
        label: "Tytuł OG",
        caption:
          "Tytuł strony, który jest wykorzystywany przy udostępnianiu linku na platformach społecznościowych. Maks. 70 znaków ze spacjami",
        maxLength: 70,
        span: 6,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [EventsField.SeoTagset, SeoTagsetsField.OgTitle],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.MetaDescription,
        label: "Opis meta",
        caption:
          "Krótka treść opisująca zawartość strony, która wyświetla się w wynikach wyszukiwania. Maks. 160 znaków ze spacjami",
        maxLength: 160,
        span: 6,
        type: FieldType.TextArea,
        show: {
          visible: true,
          accessor: [EventsField.SeoTagset, SeoTagsetsField.MetaDescription],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.OgDescription,
        label: "Opis OG",
        caption:
          "Krótka treść opisująca zawartość strony, która wyświetla się pod linkiem przy udostępnianiu na platformach społecznościowych. Maks. 200 znaków ze spacjami",
        maxLength: 200,
        span: 6,
        type: FieldType.TextArea,
        show: {
          visible: true,
          accessor: [EventsField.SeoTagset, SeoTagsetsField.OgDescription],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.OgImageUrl,
        label: "Grafika OG",
        caption:
          "Grafika, która wyświetla się razem linkiem przy udostępnianiu strony na platformach społecznościowych.",
        type: FieldType.OgImage,
        span: 12,
        show: {
          visible: true,
          accessor: [EventsField.SeoTagset, SeoTagsetsField.OgImageUrl],
        },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: SeoTagsetsField.OgImage,
        label: "Grafika OG",
        caption:
          "Grafika, która wyświetla się razem linkiem przy udostępnianiu strony na platformach społecznościowych. Proporcje kadru: 2.1:1.",
        type: FieldType.ImageCropper,
        imageRequirements: {
          aspect: 1920 / 915,
        },
        span: 12,
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.MetaKeywords,
        label: "Słowa kluczowe meta",
        caption: "Wpisz po przecinku",
        span: 12,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [EventsField.SeoTagset, SeoTagsetsField.MetaKeywords],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.Robots,
        label: "Robots",
        span: 4,
        type: FieldType.DictionaryValuesSelect,
        caption:
          "Pozwala na kontrolowanie zachowania robotów indeksujących wyszukiwarek na stronie",
        dictionarySystemName: DictionarySystemName.RobotsSeoTagsets,
        show: {
          visible: true,
          accessor: [EventsField.SeoTagset, SeoTagsetsField.Robots],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.Canonical,
        label: "Link canonical",
        span: 8,
        caption:
          "Informuje wyszukiwarki, która wersja strony jest preferowana, co pomaga uniknąć problemów związanych z duplikatami treści",
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [EventsField.SeoTagset, SeoTagsetsField.Canonical],
        },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "system",
    label: "Dane systemowe",
    fields: [
      {
        id: EventsField.CreatedAt,
        label: "Utworzenie",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: EventsField.CreatedBy,
        label: "Utworzono przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: EventsField.UpdatedAt,
        label: "Ostatnia aktualizacja",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: EventsField.UpdatedBy,
        label: "Zaktualizowano przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
];
