import { OutputData } from "@editorjs/editorjs";
import { DataType } from "components/formatted-value";
import { SelectValue } from "components/select/select";
import { FieldsGroup, FieldType } from "fields.d";

export type EntriesFormInputs = {
  name: string;
  slug: string;
  content: OutputData[];
  groups: SelectValue;
  position: number;
  isVisible: boolean;
};

export enum EntriesField {
  Name = "name",
  Slug = "slug",
  Content = "content",
  IsVisible = "isVisible",
  Groups = "groups",
  Group = "group",
  Position = "position",
  CreatedAt = "createdAt",
  CreatedBy = "createdBy",
  UpdatedAt = "updatedAt",
  UpdatedBy = "updatedBy",
}

export const ENTRIES_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: EntriesField.Name,
        label: "Nazwa",
        type: FieldType.BigInput,
        span: 12,
        autocomplete: "title",
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: EntriesField.Slug,
        label: "Slug",
        type: FieldType.SlugInput,
        dataType: DataType.Pre,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        update: { visible: false },
        caption:
          "Slug jest generowany automatycznie na podstawie nazwy. Możesz go zmienić, edytując powyższe pole.",
      },
      {
        id: EntriesField.Slug,
        label: "Slug",
        type: FieldType.SlugInput,
        dataType: DataType.Pre,
        span: 12,
        show: { visible: false },
        create: { visible: false },
        update: { visible: true },
        caption: "Możesz zmienić slug, edytując powyższe pole",
      },
      {
        id: EntriesField.Position,
        label: "Pozycja",
        type: FieldType.NumberInput,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true, required: true },
      },
      {
        id: EntriesField.IsVisible,
        label: "Widoczność",
        type: FieldType.Switch,
        dataType: DataType.VisibilityBoolean,
        caption: "Widoczność określa, czy ta pozycja jest publiczna",
        span: 6,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: EntriesField.Groups,
        label: "Grupa",
        type: FieldType.GroupsSelect,
        span: 12,
        show: { visible: false },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: EntriesField.Group,
        label: "Grupa",
        type: FieldType.Input,
        dataType: DataType.Groups,
        span: 12,
        show: { visible: true, accessor: ["group", "name"] },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
  {
    id: "content",
    label: "Treść",
    fields: [
      {
        id: EntriesField.Content,
        label: "Treść",
        span: 12,
        type: FieldType.Editor,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
    ],
  },
  {
    id: "system",
    label: "Dane systemowe",
    fields: [
      {
        id: EntriesField.CreatedAt,
        label: "Utworzenie",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: EntriesField.CreatedBy,
        label: "Utworzono przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: EntriesField.UpdatedAt,
        label: "Ostatnia aktualizacja",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: EntriesField.UpdatedBy,
        label: "Zaktualizowano przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
];
