import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { KIND } from "baseui/button";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { FormRenderer } from "components/form-renderer";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { Role } from "containers/Roles/roles";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { DeviceFloppy, FileOff } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";
import { renderUserLabel } from "utils/render-user-label";

import { User } from "../users";
import { USERS_FIELDS, UsersFormInputs } from "../users.form";
import { USERS_SHOW, USERS_UPDATE } from "../users.gql";

export default function UsersUpdate(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const { enqueueSnackbar } = useSnackbar();
  const { setIsFetching, isLoading, setIsLoading } = useLoading();
  const { checkPermission } = useAuth();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    register,
    setValue,
    reset,
    unregister,
    watch,
    ...methods
  } = useForm<UsersFormInputs>();

  const { refetch, data, error: queryError } = useQuery(USERS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
  });
  const user: User = data?.user;

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    if (user) {
      setIsFetching(false);
      setValue("firstName", user?.firstName);
      setValue("lastName", user?.lastName);
      setValue("email", user?.email);
      setValue("description", user?.description);
      user?.roles &&
        setValue(
          "roles",
          user?.roles.map(({ id, name }: Role) => ({ id, name }))
        );
    }
  }, [data]);

  useEffect(() => {
    if (user) refetch();
  }, []);

  const [updateUser, { error }] = useMutation(USERS_UPDATE);

  const onSubmit = async ({
    roles,
    ...values
  }: UsersFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await updateUser({
        variables: {
          userUpdateInput: {
            id: id ? parseInt(id) : null,
            roleIds: roles ? roles.map(({ id }: Role) => id) : [],
            ...values,
          },
        },
      });
      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.push(`/users/${id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.user.update))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title={renderUserLabel(user)}
        labels={["Użytkownicy", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "updateUser",
          },
        ]}
      />
      <Content>
        <FormProvider<UsersFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          setValue={setValue}
          watch={watch}
          register={register}
          unregister={unregister}
          {...methods}
        >
          <FormRenderer<UsersFormInputs>
            id="updateUser"
            onSubmit={handleSubmit(onSubmit)}
            type="update"
            isLoading={isLoading}
            error={error}
            fields={USERS_FIELDS}
          />
        </FormProvider>
        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie edycji użytkownika"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
      </Content>
    </article>
  );
}
