import { BasicFilter, FilterType } from "./../../filters.d";
import { StaticPagesField } from "./static-pages.form";

export const STATIC_PAGES_FILTERS: BasicFilter[] = [
  {
    id: StaticPagesField.Name,
    label: "Nazwa",
    type: FilterType.Text,
  },
  {
    id: StaticPagesField.CreatedAt,
    label: "Utworzenie",
    type: FilterType.Date,
  },
  {
    id: StaticPagesField.IsVisible,
    label: "Widoczność",
    type: FilterType.Boolean,
  },
  {
    id: StaticPagesField.ShowInMainMenu,
    label: "Widoczność w menu głównym",
    type: FilterType.Boolean,
  },
  {
    id: StaticPagesField.ShowInFooterMenu,
    label: "Widoczność w stopce",
    type: FilterType.Boolean,
  },
  {
    id: StaticPagesField.Position,
    label: "Pozycja",
    type: FilterType.Position,
  },
];
