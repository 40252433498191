import { OutputData } from "@editorjs/editorjs";
import { DataType } from "components/formatted-value";
import { SelectValue } from "components/select/select";
import { Category } from "containers/Categories/categories";
import { SeoTagsetsField } from "containers/SeoTagsets/seo-tagsets.form";

import { DictionarySystemName } from "../../constants";
import { FieldsGroup, FieldType } from "../../fields.d";

export type EventAggregatorsFormInputs = {
  name: string;
  slug: string;
  isVisible: boolean;
  events: SelectValue;
  content: OutputData[];
  startedAt: Date;
  endedAt: Date;
  sliderPosition: number;
  showInSlider: boolean;
  verticalPoster: File;
  categories: Category[];
  locationName: string;
  metaTitle: string;
  metaDescription: string;
  metaKeywords: string;
  ogTitle: string;
  ogDescription: string;
  ogImage: File;
  canonical: string;
  robots: SelectValue;
};

export enum EventAggregatorsField {
  Name = "name",
  Slug = "slug",
  IsVisible = "isVisible",
  Content = "content",
  SliderPosition = "sliderPosition",
  ShowInSlider = "showInSlider",
  VerticalPoster = "verticalPoster",
  VerticalPosterUrl = "verticalPosterUrl",
  Categories = "categories",
  LocationName = "locationName",
  StartedAt = "startedAt",
  EndedAt = "endedAt",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  Events = "events",
  CreatedBy = "createdBy",
  UpdatedBy = "updatedBy",
  SeoTagset = "seoTagset",
}

export const EVENT_AGGREGATORS_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: EventAggregatorsField.Name,
        label: "Nazwa",
        type: FieldType.BigInput,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: EventAggregatorsField.Slug,
        label: "Slug",
        type: FieldType.SlugInput,
        dataType: DataType.Pre,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        update: { visible: false },
        caption:
          "Slug jest generowany automatycznie na podstawie nazwy. Możesz go zmienić, edytując powyższe pole.",
      },
      {
        id: EventAggregatorsField.Slug,
        label: "Slug",
        type: FieldType.SlugInput,
        dataType: DataType.Pre,
        span: 12,
        show: { visible: false },
        create: { visible: false },
        update: { visible: true },
        caption: "Możesz zmienić slug, edytując powyższe pole",
      },
      {
        id: EventAggregatorsField.Slug,
        label: "Link do agregatora",
        type: FieldType.Link,
        span: 12,
        show: { visible: true },
        caption:
          "Link do udostępnienia. Uwaga: Jeśli widoczność jest ustawiona na „nie”, pozycja nie jest dostępna publicznie",
        create: { visible: false, required: false },
        update: { visible: false, required: false },
      },
      {
        id: EventAggregatorsField.LocationName,
        label: "Lokalizacja",
        type: FieldType.Input,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: EventAggregatorsField.Categories,
        label: "Kategoria",
        span: 12,
        type: FieldType.Multi,
        dataType: DataType.Categories,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: EventAggregatorsField.Categories,
        label: "Kategoria",
        span: 12,
        type: FieldType.CategorySelect,
        dataType: DataType.Categories,
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: EventAggregatorsField.IsVisible,
        label: "Widoczność",
        type: FieldType.Switch,
        dataType: DataType.VisibilityBoolean,
        readonly: true,
        caption: "Widoczność określa, czy ta pozycja jest publiczna",
        span: 6,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: EventAggregatorsField.Events,
        label: "Wydarzenia",
        type: FieldType.EventsLiveSearchSelect,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: EventAggregatorsField.Events,
        label: "Wydarzenia",
        type: FieldType.EventsTable,
        dataType: DataType.Events,
        span: 12,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
  {
    id: "display",
    label: "Wyświetlanie",
    fields: [
      {
        id: EventAggregatorsField.ShowInSlider,
        label: "Pokaż w sliderze",
        type: FieldType.Switch,
        dataType: DataType.VisibilityBoolean,
        span: 4,
        caption: "Widoczność określa, czy ta pozycja jest widoczna w sliderze",
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: EventAggregatorsField.SliderPosition,
        label: "Pozycja w sliderze",
        type: FieldType.NumberInput,
        span: 4,
        caption: "Kolejność wyświetlania w sliderze na stronie głównej",
        show: { visible: true },
        create: { visible: true },
        update: { visible: true, required: true },
      },
    ],
  },
  {
    id: "dates",
    label: "Daty",
    fields: [
      {
        id: EventAggregatorsField.StartedAt,
        label: "Rozpoczęcie wydarzeń",
        span: 6,
        type: FieldType.DatePicker,
        withTime: true,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
        minDate: new Date(),
        maxDate: EventAggregatorsField.EndedAt,
      },
      {
        id: EventAggregatorsField.EndedAt,
        label: "Zakończenie wydarzeń",
        span: 6,
        type: FieldType.DatePicker,
        withTime: true,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
        minDate: EventAggregatorsField.StartedAt,
      },
    ],
  },
  {
    id: "content",
    label: "Treść",
    fields: [
      {
        id: EventAggregatorsField.Content,
        label: "Treść",
        span: 12,
        type: FieldType.Editor,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "images",
    label: "Media",
    fields: [
      {
        id: EventAggregatorsField.VerticalPosterUrl,
        label: "Pionowy plakat",
        type: FieldType.Image,
        span: 12,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: EventAggregatorsField.VerticalPoster,
        label: "Pionowy plakat",
        type: FieldType.ImageCropper,
        imageRequirements: {
          aspect: 3 / 4,
        },
        caption: "Proporcje kadru: 3:4",
        span: 12,
        show: { visible: false },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
    ],
  },
  {
    id: "seo",
    label: "Tagi SEO",
    fields: [
      {
        id: SeoTagsetsField.MetaTitle,
        label: "Tytuł meta",
        caption:
          "Tytuł strony wyświetlany na pasku przeglądarki i w wynikach wyszukiwania. Maks. 70 znaków ze spacjami",
        maxLength: 70,
        span: 6,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [
            EventAggregatorsField.SeoTagset,
            SeoTagsetsField.MetaTitle,
          ],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.OgTitle,
        label: "Tytuł OG",
        caption:
          "Tytuł strony, który jest wykorzystywany przy udostępnianiu linku na platformach społecznościowych. Maks. 70 znaków ze spacjami",
        maxLength: 70,
        span: 6,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [EventAggregatorsField.SeoTagset, SeoTagsetsField.OgTitle],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.MetaDescription,
        label: "Opis meta",
        caption:
          "Krótka treść opisująca zawartość strony, która wyświetla się w wynikach wyszukiwania. Maks. 160 znaków ze spacjami",
        maxLength: 160,
        span: 6,
        type: FieldType.TextArea,
        show: {
          visible: true,
          accessor: [
            EventAggregatorsField.SeoTagset,
            SeoTagsetsField.MetaDescription,
          ],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.OgDescription,
        label: "Opis OG",
        caption:
          "Krótka treść opisująca zawartość strony, która wyświetla się pod linkiem przy udostępnianiu na platformach społecznościowych. Maks. 200 znaków ze spacjami",
        maxLength: 200,
        span: 6,
        type: FieldType.TextArea,
        show: {
          visible: true,
          accessor: [
            EventAggregatorsField.SeoTagset,
            SeoTagsetsField.OgDescription,
          ],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.OgImageUrl,
        label: "Grafika OG",
        caption:
          "Grafika, która wyświetla się razem linkiem przy udostępnianiu strony na platformach społecznościowych.",
        type: FieldType.OgImage,
        span: 12,
        show: {
          visible: true,
          accessor: [
            EventAggregatorsField.SeoTagset,
            SeoTagsetsField.OgImageUrl,
          ],
        },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: SeoTagsetsField.OgImage,
        label: "Grafika OG",
        caption:
          "Grafika, która wyświetla się razem linkiem przy udostępnianiu strony na platformach społecznościowych. Proporcje kadru: 2.1:1.",
        type: FieldType.ImageCropper,
        imageRequirements: {
          aspect: 1920 / 915,
        },
        span: 12,
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.MetaKeywords,
        label: "Słowa kluczowe meta",
        caption: "Wpisz po przecinku",
        span: 12,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [
            EventAggregatorsField.SeoTagset,
            SeoTagsetsField.MetaKeywords,
          ],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.Robots,
        label: "Robots",
        span: 4,
        type: FieldType.DictionaryValuesSelect,
        dictionarySystemName: DictionarySystemName.RobotsSeoTagsets,
        caption:
          "Pozwala na kontrolowanie zachowania robotów indeksujących wyszukiwarek na stronie",
        show: {
          visible: true,
          accessor: [EventAggregatorsField.SeoTagset, SeoTagsetsField.Robots],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.Canonical,
        label: "Link canonical",
        span: 8,
        type: FieldType.Input,
        caption:
          "Informuje wyszukiwarki, która wersja strony jest preferowana, co pomaga uniknąć problemów związanych z duplikatami treści",
        show: {
          visible: true,
          accessor: [
            EventAggregatorsField.SeoTagset,
            SeoTagsetsField.Canonical,
          ],
        },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "system",
    label: "Dane systemowe",
    fields: [
      {
        id: EventAggregatorsField.CreatedAt,
        label: "Utworzenie",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: EventAggregatorsField.CreatedBy,
        label: "Utworzono przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: EventAggregatorsField.UpdatedAt,
        label: "Ostatnia aktualizacja",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: EventAggregatorsField.UpdatedBy,
        label: "Zaktualizowano przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
];
