import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { ObjectViewRenderer } from "components/object-view-renderer";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Copy, Eye, Pencil, Trash } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import { Event } from "../events";
import { EVENTS_FIELDS } from "../events.form";
import { EVENTS_CLONE, EVENTS_DELETE, EVENTS_SHOW } from "../events.gql";

export default function EventsShow(): React.ReactElement {
  const [, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { checkPermission } = useAuth();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { setIsLoading, setIsFetching } = useLoading();

  const { refetch, data, error } = useQuery(EVENTS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
    notifyOnNetworkStatusChange: true,
  });
  const event: Event = data?.event;

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.event) refetch();
  }, []);

  useEffect(() => data?.event && setIsFetching(false), [data]);

  const [deleteEvent] = useMutation(EVENTS_DELETE);

  const [cloneEvent] = useMutation(EVENTS_CLONE);

  const handleEventAction = async (actionType: "clone" | "delete") => {
    setIsLoading(true);

    try {
      let data;
      const variables = {
        eventInput: { id: id ? parseInt(id) : null },
      };

      if (actionType === "clone") {
        const response = await cloneEvent({
          variables: { eventCloneInput: variables.eventInput },
        });
        data = response.data;
        enqueueSnackbar({
          message: "Sklonowano pomyślnie",
          variant: "success",
        });
        history.push(`/events/${data?.eventClone?.id}/update`);
      } else if (actionType === "delete") {
        await deleteEvent({
          variables: { eventDeleteInput: variables.eventInput },
        });
        enqueueSnackbar({
          message: "Usunięto pomyślnie",
          variant: "success",
        });
        setIsConfirmDialogOpen(false);
        history.push("/events");
      }
    } catch (error: unknown) {
      setIsConfirmDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.event.read))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title={event?.name}
        labels={["Wydarzenia", "Wyświetlanie"]}
        goBackOption
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            permission: checkPermission(PERMISSIONS.event.update),
            onClick: () => history.push(`/events/${id}/update`),
          },
          {
            label: "Podgląd",
            kind: KIND.secondary,
            $as: "a",
            target: "_blank",
            href: `${process.env.REACT_APP_URL}/api/preview?secret=${event?.previewToken}&slug=/bilety/${event?.slug}`,
            startEnhancer: <Eye size={18} />,
          },
        ]}
        actions={[
          {
            label: "Klonuj wydarzenie",
            icon: Copy,
            color: theme.colors.primary,
            onClick: () => handleEventAction("clone"),
            permission: checkPermission(PERMISSIONS.event.create),
          },
          {
            label: "Usuń wydarzenie",
            icon: Trash,
            color: theme.colors.negative,
            onClick: () => setIsConfirmDialogOpen(true),
            permission: checkPermission(PERMISSIONS.event.delete),
          },
        ]}
      />
      <Content>
        <ObjectViewRenderer<Event> fields={EVENTS_FIELDS} data={event} />

        <ConfirmDialog
          isOpen={isConfirmDialogOpen}
          label={`Usunięcie wydarzenia ${event?.name}`}
          close={() => setIsConfirmDialogOpen(false)}
          confirm={() => handleEventAction("delete")}
        />
      </Content>
    </article>
  );
}
