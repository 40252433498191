import { gql } from "@apollo/client";

export const ENTRIES_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: FaqEntryFilter
    $sorting: [FaqEntrySort!]
  ) {
    faqEntries(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        slug
        isVisible
        position
        createdAt
        updatedAt
        createdBy {
          id
          firstName
          lastName
          email
        }
        updatedBy {
          id
          firstName
          lastName
          email
        }
        group {
          name
        }
      }
    }
  }
`;

export const ENTRIES_SHOW = gql`
  query FaqEntryShow($id: Int!) {
    faqEntry(id: $id) {
      id
      name
      slug
      isVisible
      position
      createdAt
      updatedAt
      content
      createdBy {
        id
        email
        firstName
        lastName
      }
      updatedBy {
        id
        email
        firstName
        lastName
      }
      group {
        id
        name
      }
    }
  }
`;

export const ENTRIES_CREATE = gql`
  mutation FaqEntryCreate($faqEntryCreateInput: FaqEntryCreateInput!) {
    faqEntryCreate(faqEntryCreateInput: $faqEntryCreateInput) {
      id
    }
  }
`;

export const ENTRIES_UPDATE = gql`
  mutation FaqEntryUpdate($faqEntryUpdateInput: FaqEntryUpdateInput!) {
    faqEntryUpdate(faqEntryUpdateInput: $faqEntryUpdateInput) {
      id
    }
  }
`;

export const ENTRIES_DELETE = gql`
  mutation FaqEntryDelete($faqEntryDeleteInput: FaqEntryDeleteInput!) {
    faqEntryDelete(faqEntryDeleteInput: $faqEntryDeleteInput) {
      id
    }
  }
`;
