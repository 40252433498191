import { styled, useStyletron } from "baseui";
import { Cell } from "components/cell";
import { Grid } from "components/grid";
import React, { RefObject, useEffect, useState } from "react";
import { useRef } from "react";

const StickedTableHead = styled(
  "div",
  ({
    $sticked,
    $left,
    $compact,
  }: {
    $sticked?: boolean;
    $left?: number;
    $compact?: boolean;
  }) => ({
    position: "fixed",
    left: $left ? `${$left}px` : "225px",
    right: "0px",
    top: $compact ? "70px" : "120px",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
    zIndex: 8,
    paddingRight: "10px",
    transform: "translateY(-200px)",
    ...($sticked && {
      animationIterationCount: "1",
      animationDelay: "0ms",
      animationDuration: "200ms",
      animationFillMode: "both",
      animationName: {
        from: {
          transform: "translateY(-200px)",
        },
        to: {
          transform: "translateY(0px)",
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any,
    }),
  })
);

type TableHeadProps = {
  tableRef: RefObject<HTMLDivElement>;
  compact?: boolean;
};

export default function TableHead({
  tableRef,
  compact = false,
}: TableHeadProps): React.ReactElement {
  const [css, theme] = useStyletron();

  const [sticked, setSticked] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const shouldStickHigher =
    !window?.document?.querySelector("[data-ui=filters]") || compact;

  function cloneTableHead() {
    if (tableRef?.current && containerRef?.current) {
      const clonedTHead = tableRef.current
        .querySelector("thead")
        ?.cloneNode(true);

      const tableNode = containerRef.current.querySelector("table");

      if (clonedTHead && tableNode) {
        tableNode.innerHTML = "";
        tableNode.appendChild(clonedTHead);

        const items = tableNode.querySelectorAll("th");

        for (const item of items) {
          item.addEventListener("mouseenter", highlightColumn);
          item.addEventListener("mouseleave", unhighlightColumn);
          item.addEventListener("click", clickCell);
        }
      }
    }
  }

  function checkPosition() {
    if (
      (tableRef?.current?.getBoundingClientRect().top || 0) -
        (shouldStickHigher ? 70 : 120) <
      0
    ) {
      if (
        (tableRef?.current?.getBoundingClientRect().bottom || 0) -
          (shouldStickHigher ? 70 : 120) -
          44 <
        0
      )
        setSticked(false);
      else setSticked(true);
    } else setSticked(false);
  }

  const customSidebarWidth =
    localStorage.getItem("sidebarWidth") &&
    parseInt(localStorage.getItem("sidebarWidth") as string);

  const [sidebarWidth, setSidebarWidth] = useState<number>(
    customSidebarWidth || 225
  );

  useEffect(() => cloneTableHead, [sticked]);

  useEffect(() => {
    checkPosition();

    document
      ?.querySelector("main > section")
      ?.addEventListener("scroll", checkPosition, { passive: true });

    window?.addEventListener("resize", checkPosition);

    const observer = new ResizeObserver(function () {
      const width = document?.querySelector("aside")?.offsetWidth;

      if (width) {
        setSidebarWidth(width);
      }
    });

    const sidebarNode = document?.querySelector("aside");

    if (sidebarNode) {
      observer.observe(sidebarNode);
    }

    return () => {
      window?.removeEventListener("resize", checkPosition);
      document
        ?.querySelector("main > section")
        ?.removeEventListener("scroll", checkPosition);
      observer.disconnect();
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function highlightColumn(event: any) {
    const index = [...event.target.closest("thead > tr")?.children].indexOf(
      event.target.closest("th")
    );

    event.target
      .closest("[data-ui=table-root]")
      ?.querySelector("[data-baseweb=table-semantic] table")
      ?.querySelectorAll(`td:nth-child(${index + 1})`)
      ?.forEach((el: HTMLElement) => (el.style.backgroundColor = "#fbfbfb"));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function unhighlightColumn(event: any) {
    const index = [...event.target.closest("thead > tr")?.children].indexOf(
      event.target.closest("th")
    );

    event.target
      .closest("[data-ui=table-root]")
      ?.querySelector("[data-baseweb=table-semantic] table")
      ?.querySelectorAll(`td:nth-child(${index + 1})`)
      ?.forEach((el: HTMLElement) => (el.style.backgroundColor = ""));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function clickCell(event: any) {
    const index = [...event.target.closest("thead > tr")?.children].indexOf(
      event.target.closest("th")
    );

    event.target
      .closest("[data-ui=table-root]")
      ?.querySelector("[data-baseweb=table-semantic] table")
      ?.querySelector(`th:nth-child(${index + 1}) > div > div`)
      ?.click();
  }

  return (
    <StickedTableHead
      $sticked={sticked}
      $left={sidebarWidth}
      $compact={shouldStickHigher}
    >
      <Grid>
        <Cell span={12}>
          <div
            ref={containerRef}
            className={css({
              width: "100%",
              overflowY: "hidden",
              overflowX: "auto",
              borderRightStyle: "solid",
              borderRightWidth: "1px",
              borderRightColor: theme.colors.inputBorder,
              borderLeftStyle: "solid",
              borderLeftWidth: "1px",
              borderLeftColor: theme.colors.inputBorder,
              paddingBottom: "4px",
            })}
            data-ui="sticked-table-head"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onScroll={(event: any) => {
              const tableRoot = event.target?.closest("[data-ui=table-root]");

              if (tableRoot) {
                const table = tableRoot?.querySelector("[data-ui=table]");

                if (table) {
                  const scrolling =
                    tableRoot.getAttribute("data-scrolling") === "true"
                      ? true
                      : false;

                  if (scrolling) {
                    tableRoot.setAttribute("data-scrolling", "false");
                    return;
                  }

                  if (
                    table.scrollLeft !==
                    (event.target as HTMLElement).scrollLeft
                  ) {
                    tableRoot.setAttribute("data-scrolling", "true");
                    table.scrollLeft = (event.target as HTMLElement).scrollLeft;
                  }
                }
              }
            }}
          >
            <table
              className={css({
                width: "100%",
                left: "0px",
                position: "relative",
                borderCollapse: "collapse",
              })}
            >
              <thead />
            </table>
          </div>
        </Cell>
      </Grid>
    </StickedTableHead>
  );
}
