import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { KIND } from "baseui/button";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { FormRenderer } from "components/form-renderer";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { FaqGroup } from "containers/Faq/Groups/groups";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { DeviceFloppy, FileOff } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";
import { scrollOnError } from "utils/scrollOnError";

import { GROUPS_FIELDS, GroupsFormInputs } from "../groups.form";
import { GROUPS_SHOW, GROUPS_UPDATE } from "../groups.gql";

export default function GroupsUpdate(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const { enqueueSnackbar } = useSnackbar();
  const { setIsFetching, isLoading, setIsLoading } = useLoading();
  const { checkPermission } = useAuth();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    setValue,
    reset,
    watch,
    ...methods
  } = useForm<GroupsFormInputs>();

  const { refetch, data, error } = useQuery(GROUPS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
    notifyOnNetworkStatusChange: true,
  });
  const group: FaqGroup = data?.faqGroup;

  useEffect(() => {
    setIsFetching(true);
    if (data?.faqGroup) refetch();
  }, []);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (group) {
      setValue("name", group?.name);
      setValue("isVisible", group?.isVisible);
      setValue("position", group?.position);
      setValue("slug", group?.slug);

      setIsFetching(false);
    }
  }, [data]);

  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  useEffect(() => {
    if (errors) scrollOnError(errors, GROUPS_FIELDS, "update");
  }, [errorTimeStamp]);

  const [faqGroupCreate] = useMutation(GROUPS_UPDATE);

  const onSubmit = async ({
    isVisible,
    ...values
  }: GroupsFormInputs): Promise<void> => {
    setIsLoading(true);
    try {
      await faqGroupCreate({
        variables: {
          faqGroupUpdateInput: {
            id: id ? parseInt(id) : null,
            isVisible: !!isVisible,
            ...values,
          },
        },
      });
      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.push(`/groups/${id}`);
    } catch (mutationError: unknown) {
      enqueueSnackbar({
        message: (mutationError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.group.update))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        labels={["Grupy", "Edytowanie"]}
        title={group?.name}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit, onError),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "updateGroup",
          },
        ]}
      />
      <Content>
        <FormProvider<GroupsFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          setValue={setValue}
          watch={watch}
          {...methods}
        >
          <FormRenderer<GroupsFormInputs>
            id="updateGroup"
            onSubmit={handleSubmit(onSubmit, onError)}
            type="update"
            isLoading={isLoading}
            error={error}
            fields={GROUPS_FIELDS}
          />
        </FormProvider>
        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie edycji grupy"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
      </Content>
    </article>
  );
}
