export const getTextColor = (hex: string): string => {
  const hexCode = hex?.charAt(0) === "#" ? hex?.substring(1, 7) : hex;

  const contrastRatio =
    (parseInt(hexCode?.substring(0, 2), 16) +
      parseInt(hexCode?.substring(2, 4), 16) +
      parseInt(hexCode?.substring(4, 6), 16)) /
    (255 * 3);

  return contrastRatio >= 0.5 ? "#000" : "#FFF";
};
