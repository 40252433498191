import ActivityLogsIndex from "containers/ActivityLogs/Index";
import ActivityLogsShow from "containers/ActivityLogs/Show";
import Invitation from "containers/Auth/Invitation";
import CategoriesCreate from "containers/Categories/Create";
import CategoriesIndex from "containers/Categories/Index";
import CategoriesShow from "containers/Categories/Show";
import CategoriesUpdate from "containers/Categories/Update";
import EventAggregatorsCreate from "containers/EventAggregators/Create";
import EventAggregatorsIndex from "containers/EventAggregators/Index";
import EventAggregatorsShow from "containers/EventAggregators/Show";
import EventAggregatorsUpdate from "containers/EventAggregators/Update";
import EventsCreate from "containers/Events/Create";
import EventsIndex from "containers/Events/Index";
import EventsShow from "containers/Events/Show";
import EventsUpdate from "containers/Events/Update";
import EntriesCreate from "containers/Faq/Entries/Create";
import EntriesIndex from "containers/Faq/Entries/Index";
import EntriesShow from "containers/Faq/Entries/Show";
import EntriesUpdate from "containers/Faq/Entries/Update";
import GroupsCreate from "containers/Faq/Groups/Create";
import GroupsIndex from "containers/Faq/Groups/Index";
import GroupsShow from "containers/Faq/Groups/Show";
import GroupsUpdate from "containers/Faq/Groups/Update";
import ImagesCreate from "containers/Images/Create";
import ImagesIndex from "containers/Images/Index";
import ImagesShow from "containers/Images/Show";
import ImagesUpdate from "containers/Images/Update";
import OrganizersCreate from "containers/Organizers/Create";
import OrganizersIndex from "containers/Organizers/Index";
import OrganizerShow from "containers/Organizers/Show";
import OrganizerUpdate from "containers/Organizers/Update";
import RolesCreate from "containers/Roles/Create";
import RolesIndex from "containers/Roles/Index";
import RolesShow from "containers/Roles/Show";
import RolesUpdate from "containers/Roles/Update";
import SeoTagsetsIndex from "containers/SeoTagsets/Index";
import SeoTagsetsShow from "containers/SeoTagsets/Show";
import SeoTagsetsUpdate from "containers/SeoTagsets/Update";
import StaticPagesCreate from "containers/StaticPages/Create";
import StaticPagesIndex from "containers/StaticPages/Index";
import StaticPagesShow from "containers/StaticPages/Show";
import StaticPagesUpdate from "containers/StaticPages/Update";
import { ReactElement } from "react";

import Login from "./containers/Auth/Login";
import Logout from "./containers/Auth/Logout";
import ResetPassword from "./containers/Auth/ResetPassword";
import DictionariesIndex from "./containers/Dictionaries/Index";
import DictionariesShow from "./containers/Dictionaries/Show";
import Error404 from "./containers/ErrorPages/404";
import Home from "./containers/Home";
import MyAccount from "./containers/MyAccount";
import UsersCreate from "./containers/Users/Create";
import UsersIndex from "./containers/Users/Index";
import UsersShow from "./containers/Users/Show";
import UsersUpdate from "./containers/Users/Update";

export type Route = {
  path: string;
  component: () => ReactElement;
  exact?: boolean;
};

export type ModelRoute = {
  name: string;
  path: string;
  index?: Route;
  show?: Route;
  update?: Route;
  create?: Route;
};

const ROUTES = {
  private: {
    basic: [
      {
        path: "/",
        component: Home,
        exact: true,
      },
      {
        path: "/my-account",
        component: MyAccount,
        exact: true,
      },
      {
        path: "/logout",
        component: Logout,
        exact: true,
      },
    ] as Route[],
    models: [
      {
        name: "activityLogs",
        path: "/activity-logs",
        index: {
          path: "/",
          component: ActivityLogsIndex,
          exact: true,
        },
        show: {
          path: "/:id",
          component: ActivityLogsShow,
          exact: true,
        },
      },
      {
        name: "faqGroups",
        path: "/groups",
        index: {
          path: "/",
          component: GroupsIndex,
          exact: true,
        },
        create: {
          path: "/create",
          component: GroupsCreate,
          exact: true,
        },
        show: {
          path: "/:id",
          component: GroupsShow,
          exact: true,
        },
        update: {
          path: "/:id/update",
          component: GroupsUpdate,
          exact: true,
        },
      },
      {
        name: "faqEntries",
        path: "/entries",
        index: {
          path: "/",
          component: EntriesIndex,
          exact: true,
        },
        create: {
          path: "/create",
          component: EntriesCreate,
          exact: true,
        },
        show: {
          path: "/:id",
          component: EntriesShow,
          exact: true,
        },
        update: {
          path: "/:id/update",
          component: EntriesUpdate,
          exact: true,
        },
      },
      {
        name: "categories",
        path: "/categories",
        index: {
          path: "/",
          component: CategoriesIndex,
          exact: true,
        },
        create: {
          path: "/create",
          component: CategoriesCreate,
          exact: true,
        },
        show: {
          path: "/:id",
          component: CategoriesShow,
          exact: true,
        },
        update: {
          path: "/:id/update",
          component: CategoriesUpdate,
          exact: true,
        },
      },
      {
        name: "dictionaries",
        path: "/dictionaries",
        index: {
          path: "/",
          component: DictionariesIndex,
          exact: true,
        },
        show: {
          path: "/:id",
          component: DictionariesShow,
          exact: true,
        },
      },
      {
        name: "roles",
        path: "/roles",
        index: {
          path: "/",
          component: RolesIndex,
          exact: true,
        },
        create: {
          path: "/create",
          component: RolesCreate,
          exact: true,
        },
        show: {
          path: "/:id",
          component: RolesShow,
          exact: true,
        },
        update: {
          path: "/:id/update",
          component: RolesUpdate,
          exact: true,
        },
      },
      {
        name: "seoTagsets",
        path: "/seo-tagsets",
        index: {
          path: "/",
          component: SeoTagsetsIndex,
          exact: true,
        },
        show: {
          path: "/:id",
          component: SeoTagsetsShow,
          exact: true,
        },
        update: {
          path: "/:id/update",
          component: SeoTagsetsUpdate,
          exact: true,
        },
      },
      {
        name: "static-pages",
        path: "/static-pages",
        index: {
          path: "/",
          component: StaticPagesIndex,
          exact: true,
        },
        create: {
          path: "/create",
          component: StaticPagesCreate,
          exact: true,
        },
        show: {
          path: "/:id",
          component: StaticPagesShow,
          exact: true,
        },
        update: {
          path: "/:id/update",
          component: StaticPagesUpdate,
          exact: true,
        },
      },
      {
        name: "organizers",
        path: "/organizers",
        index: {
          path: "/",
          component: OrganizersIndex,
          exact: true,
        },
        create: {
          path: "/create",
          component: OrganizersCreate,
          exact: true,
        },
        show: {
          path: "/:id",
          component: OrganizerShow,
          exact: true,
        },
        update: {
          path: "/:id/update",
          component: OrganizerUpdate,
          exact: true,
        },
      },
      {
        name: "users",
        path: "/users",
        index: {
          path: "/",
          component: UsersIndex,
          exact: true,
        },
        create: {
          path: "/create",
          component: UsersCreate,
          exact: true,
        },
        show: {
          path: "/:id",
          component: UsersShow,
          exact: true,
        },
        update: {
          path: "/:id/update",
          component: UsersUpdate,
          exact: true,
        },
      },
      {
        name: "images",
        path: "/images",
        index: {
          path: "/",
          component: ImagesIndex,
          exact: true,
        },
        create: {
          path: "/create",
          component: ImagesCreate,
          exact: true,
        },
        show: {
          path: "/:id",
          component: ImagesShow,
          exact: true,
        },
        update: {
          path: "/:id/update",
          component: ImagesUpdate,
          exact: true,
        },
      },
      {
        name: "events",
        path: "/events",
        index: {
          path: "/",
          component: EventsIndex,
          exact: true,
        },
        create: {
          path: "/create",
          component: EventsCreate,
          exact: true,
        },
        show: {
          path: "/:id",
          component: EventsShow,
          exact: true,
        },
        update: {
          path: "/:id/update",
          component: EventsUpdate,
          exact: true,
        },
      },
      {
        name: "eventAggregators",
        path: "/event-aggregators",
        index: {
          path: "/",
          component: EventAggregatorsIndex,
          exact: true,
        },
        create: {
          path: "/create",
          component: EventAggregatorsCreate,
          exact: true,
        },
        show: {
          path: "/:id",
          component: EventAggregatorsShow,
          exact: true,
        },
        update: {
          path: "/:id/update",
          component: EventAggregatorsUpdate,
          exact: true,
        },
      },
    ] as ModelRoute[],
  },
  auth: [
    {
      path: "/login",
      component: Login,
      exact: true,
    },
    {
      path: "/reset-password/:token",
      component: ResetPassword,
      exact: true,
    },
    {
      path: "/invitation/:token",
      component: Invitation,
      exact: true,
    },
  ] as Route[],
  shared: [] as Route[],
  fallback: [
    {
      path: "*",
      component: Error404,
      exact: false,
    },
  ] as Route[],
};

export default ROUTES;
