import { DataType } from "components/formatted-value";

import { FieldsGroup, FieldType } from "../../fields.d";

export type ImagesFormInputs = {
  file: File;
  alt: string;
};

export enum ImagesField {
  Url = "url",
  OriginalName = "originalName",
  Alt = "alt",
  CreatedAt = "createdAt",
  CreatedBy = "createdBy",
  UpdatedAt = "updatedAt",
  UpdatedBy = "updatedBy",
  File = "file",
}

export const IMAGES_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: ImagesField.OriginalName,
        label: "Nazwa i rozszerzenie",
        type: FieldType.Input,
        span: 6,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ImagesField.Alt,
        label: "Tekst alternatywny",
        type: FieldType.Input,
        span: 6,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ImagesField.Alt,
        label: "Tekst alternatywny",
        type: FieldType.Input,
        span: 12,
        show: { visible: false },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: ImagesField.Url,
        label: "Grafika",
        type: FieldType.Image,
        span: 12,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ImagesField.File,
        label: "Grafika",
        type: FieldType.ImageCropper,
        span: 12,
        imageRequirements: { allowVectors: true },
        show: { visible: false },
        create: { visible: true, required: true },
        update: { visible: false },
      },
    ],
  },
  {
    id: "system",
    label: "Dane systemowe",
    fields: [
      {
        id: ImagesField.CreatedAt,
        label: "Utworzenie",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ImagesField.CreatedBy,
        label: "Utworzono przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ImagesField.UpdatedAt,
        label: "Ostatnia aktualizacja",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ImagesField.UpdatedBy,
        label: "Zaktualizowano przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
];
