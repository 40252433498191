import { gql } from "@apollo/client";

export const EVENTS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: EventFilter
    $sorting: [EventSort!]
  ) {
    events(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        slug
        locationName
        startedAt
        redirectUrl
        endedAt
        startingPrice
        biletynaId
        sliderPosition
        showInSlider
        position
        isVisible
        visibleAt
        saleStartedAt
        saleEndedAt
        isSaleBlocked
        createdAt
        organizer {
          id
          name
        }
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
        deletedAt
        categories {
          id
          name
        }
        displayAlternativeButton
        alternativeButtonText
        alternativeButtonUrl
      }
    }
  }
`;

export const EVENTS_SHOW = gql`
  query EventShow($id: Int!) {
    event(id: $id) {
      id
      name
      slug
      verticalPosterUrl
      horizontalPosterUrl
      content
      locationName
      startedAt
      redirectUrl
      endedAt
      startingPrice
      biletynaId
      sliderPosition
      showInSlider
      position
      isVisible
      visibleAt
      termsOfServiceUrl
      saleStartedAt
      saleEndedAt
      previewToken
      isSaleBlocked
      organizer {
        id
        name
      }
      categories {
        id
        name
      }
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      updatedAt
      updatedBy {
        id
        firstName
        lastName
      }
      deletedAt
      seoTagset {
        id
        metaTitle
        metaDescription
        metaKeywords
        ogTitle
        ogDescription
        ogImageUrl
        canonical
        robots
      }
      displayAlternativeButton
      alternativeButtonText
      alternativeButtonUrl
    }
  }
`;

export const EVENTS_SELECT = gql`
  query($filter: EventFilter) {
    events(paging: { limit: 10000 }, filter: $filter) {
      nodes {
        id
        name
      }
    }
  }
`;

export const EVENTS_LIVE_SEARCH = gql`
  query($offset: Int, $filter: EventFilter, $sorting: [EventSort!]) {
    events(
      paging: { limit: 5, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      nodes {
        id
        name
        slug
        locationName
        startedAt
        endedAt
        startingPrice
        biletynaId
        sliderPosition
        showInSlider
        position
        isVisible
        visibleAt
        saleStartedAt
        saleEndedAt
        createdAt
        organizer {
          id
          name
        }
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
        deletedAt
        categories {
          id
          name
        }
      }
    }
  }
`;

export const EVENTS_CREATE = gql`
  mutation EventCreate(
    $eventCreateInput: EventCreateInput!
    $seoTagsetUpsertInput: SeoTagsetUpsertInput
  ) {
    eventCreate(
      eventCreateInput: $eventCreateInput
      seoTagsetUpsertInput: $seoTagsetUpsertInput
    ) {
      id
    }
  }
`;

export const EVENTS_UPDATE = gql`
  mutation EventUpdate(
    $eventUpdateInput: EventUpdateInput!
    $seoTagsetUpsertInput: SeoTagsetUpsertInput
  ) {
    eventUpdate(
      eventUpdateInput: $eventUpdateInput
      seoTagsetUpsertInput: $seoTagsetUpsertInput
    ) {
      id
    }
  }
`;

export const EVENTS_DELETE = gql`
  mutation EventDelete($eventDeleteInput: EventDeleteInput!) {
    eventDelete(eventDeleteInput: $eventDeleteInput) {
      id
    }
  }
`;

export const EVENTS_CLONE = gql`
  mutation EventClone($eventCloneInput: EventCloneInput!) {
    eventClone(eventCloneInput: $eventCloneInput) {
      id
    }
  }
`;
