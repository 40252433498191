import { BasicFilter, FilterType } from "./../../filters.d";
import { CategoriesField } from "./categories.form";

export const CATEGORIES_FILTERS: BasicFilter[] = [
  {
    id: CategoriesField.Name,
    label: "Nazwa",
    type: FilterType.Text,
  },
  {
    id: CategoriesField.CreatedAt,
    label: "Utworzenie",
    type: FilterType.Date,
  },
  {
    id: CategoriesField.IsVisible,
    label: "Widoczność",
    type: FilterType.Boolean,
  },
  {
    id: CategoriesField.IsFeatured,
    label: "Wyróżnienie",
    type: FilterType.Boolean,
  },
  {
    id: CategoriesField.Description,
    label: "Opis",
    type: FilterType.Text,
  },
];
