import { ApolloClient, from, InMemoryCache, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";

import { AUTH_TOKEN } from "../constants";

const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_GRAPHQL_WS_URL}`,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => ({
      token: localStorage.getItem(AUTH_TOKEN),
    }),
  },
});

const authLink = setContext((_, { headers }) => {
  const token: string | null = localStorage.getItem(AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ operation, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }) => {
      if (
        extensions &&
        "UNAUTHENTICATED" === extensions.code &&
        operation.operationName !== "Me"
      ) {
        window.location.href = "/login";
      }
    });
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink
);

const link = from([
  splitLink,
  errorLink,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createUploadLink({ uri: process.env.REACT_APP_GRAPHQL_API_URL }) as any,
]);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});
