import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { BottomPanel } from "components/bottom-panel";
import { Button } from "components/button";
import { Cell } from "components/cell";
import { Content } from "components/content";
import { Filters } from "components/filters";
import { DataType, FormattedValue } from "components/formatted-value";
import { Grid } from "components/grid";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { PagingControls } from "components/pagination";
import { Table } from "components/table";
import SortingTableHeader, {
  SortDirection,
} from "components/table/sorting-table-header";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { usePaging } from "contexts/paging-context";
import { FiltersState } from "filters";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Cell as TableCell, Row } from "react-table";
import { Eye, FileExport } from "tabler-icons-react";
import { translateFiltersState } from "utils/filters";
import { PERMISSIONS } from "utils/permissions";
import { renderUserLabel } from "utils/render-user-label";

import { Typename } from "../../../constants";
import { ActivityLog } from "../activity-logs";
import { ACTIVITY_LOGS_FILTERS } from "../activity-logs.filters";
import { ActivityLogsField } from "../activity-logs.form";
import {
  ACTIVITY_LOGS_EXPORT,
  ACTIVITY_LOGS_INDEX,
} from "../activity-logs.gql";

export default function ActivityLogsIndex(): React.ReactElement {
  const { pageSize, currentPage, setTotalCount } = usePaging();
  const [sortBy, setSortBy] = useState<ActivityLogsField | null>(
    ActivityLogsField.CreatedAt
  );
  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    SortDirection.DESC
  );
  const { checkPermission } = useAuth();
  const [filters, setFilters] = useState<FiltersState>();
  const { enqueueSnackbar } = useSnackbar();
  const [css, theme] = useStyletron();
  const {
    isFetching,
    isPartialFetching,
    setIsPartialFetching,
    setIsLoading,
    setIsFetching,
  } = useLoading();
  const history = useHistory();

  const handleSorting = (column: ActivityLogsField) => {
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const { refetch, loading, data, error } = useQuery(ACTIVITY_LOGS_INDEX, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      ...(filters &&
        filters.length && { filter: translateFiltersState(filters) }),
    },
  });

  const activityLogs: { totalCount: number; nodes: ActivityLog[] } =
    data?.activityLogs;

  useEffect(() => {
    refetch();
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    refetch();
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (activityLogs) setTimeout(() => refetch(), 100);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (data?.activityLogs) setIsFetching(false);
    if (data?.activityLogs?.totalCount >= 0)
      setTotalCount(data?.activityLogs?.totalCount);
  }, [data]);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  const [exportActivityLogs] = useMutation(ACTIVITY_LOGS_EXPORT, {
    variables: {
      ...(filters &&
        filters.length && { filter: translateFiltersState(filters) }),
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
    },
  });

  const onSubmit = async () => {
    setIsLoading(true);

    try {
      const response = await exportActivityLogs();

      enqueueSnackbar({
        message: "Rozpoczęto pobieranie pliku",
        variant: "success",
      });

      window.open(response?.data?.activityLogsExportToXlsx, "_self");
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(ActivityLogsField.Id)}
            sortDirection={
              sortBy === ActivityLogsField.Id ? sortDirection : null
            }
          >
            Id
          </SortingTableHeader>
        ),
        accessor: ActivityLogsField.Id,
        Cell: ({ row }: { row: Row<ActivityLog> }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(`/activity-logs/${row.original.id}`);
            }}
            href={`/activity-logs/${row.original.id}`}
          >
            {row.original.id}
          </StyledLink>
        ),
      },
      {
        Header: "Użytkownik",
        id: ActivityLogsField.User,
        Cell: ({ row }: { row: Row<ActivityLog> }) =>
          row.original.user?.id === undefined ? (
            row.original.properties?.login ? (
              <FormattedValue dataType={DataType.Login}>
                {row.original.properties?.login}
              </FormattedValue>
            ) : (
              <FormattedValue dataType={DataType.System}></FormattedValue>
            )
          ) : (
            <FormattedValue
              dataType={DataType.Users}
              data={row.original.user?.id}
              deletedAt={row.original.user?.deletedAt}
            >
              {renderUserLabel(row.original.user)}
            </FormattedValue>
          ),
      },
      {
        Header: "Akcja",
        accessor: ActivityLogsField.Description,
        Cell: ({ row }: { row: Row<ActivityLog> }) => (
          <FormattedValue>
            {row.original.subject?.__typename
              ? `${row.original.description} (${row.original.subject?.__typename})`
              : row.original.description}
          </FormattedValue>
        ),
      },
      {
        Header: "Obiekt",
        id: "subject.id",
        Cell: ({ row }: { row: Row<ActivityLog> }) => {
          const handleSubject = (typename: string) => {
            switch (typename) {
              case Typename.Category:
                return (
                  <FormattedValue
                    dataType={DataType.Categories}
                    data={row.original?.subject?.id}
                    deletedAt={row.original?.subject?.deletedAt}
                  >
                    {row.original?.subject?.name}
                  </FormattedValue>
                );
              case Typename.Dictionary:
                return (
                  <FormattedValue
                    dataType={DataType.Dictionaries}
                    data={row.original?.subject?.id}
                    deletedAt={row.original?.subject?.deletedAt}
                  >
                    {row.original?.subject?.name}
                  </FormattedValue>
                );
              case Typename.DictionaryValue:
                return (
                  <FormattedValue
                    dataType={DataType.Dictionaries}
                    data={row.original?.subject?.dictionaryId}
                    deletedAt={row.original?.subject?.deletedAt}
                  >
                    {`${row.original?.subject?.name} (słownik: ${row.original?.subject?.dictionary?.name})`}
                  </FormattedValue>
                );
              case Typename.Event:
                return (
                  <FormattedValue
                    dataType={DataType.Events}
                    data={row.original?.subject?.id}
                    deletedAt={row.original?.subject?.deletedAt}
                  >
                    {row.original?.subject?.name}
                  </FormattedValue>
                );
              case Typename.EventAggregator:
                return (
                  <FormattedValue
                    dataType={DataType.EventAggregators}
                    data={row.original?.subject?.id}
                    deletedAt={row.original?.subject?.deletedAt}
                  >
                    {row.original?.subject?.name}
                  </FormattedValue>
                );
              case Typename.Organizer:
                return (
                  <FormattedValue
                    dataType={DataType.Organizers}
                    data={row.original?.subject?.id}
                    deletedAt={row.original?.subject?.deletedAt}
                  >
                    {row.original?.subject?.name}
                  </FormattedValue>
                );
              case Typename.Image:
                return (
                  <FormattedValue
                    dataType={DataType.Images}
                    data={row.original?.subject?.id}
                    deletedAt={row.original?.subject?.deletedAt}
                  >
                    {row.original?.subject?.originalName}
                  </FormattedValue>
                );
              case Typename.Role:
                return (
                  <FormattedValue
                    dataType={DataType.Roles}
                    data={row.original?.subject?.id}
                    deletedAt={row.original?.subject?.deletedAt}
                  >
                    {row.original?.subject?.name}
                  </FormattedValue>
                );
              case Typename.SeoTagset:
                return (
                  <FormattedValue
                    dataType={DataType.SeoTagsets}
                    data={row.original?.subject?.id}
                    deletedAt={row.original?.subject?.deletedAt}
                  >
                    {`${row?.original?.subject?.id} (obiekt ${row?.original?.subject?.subject?.name})`}
                  </FormattedValue>
                );
              case Typename.Slide:
                return (
                  <FormattedValue
                    dataType={DataType.Slides}
                    data={row.original?.subject?.id}
                    deletedAt={row.original?.subject?.deletedAt}
                  >
                    {row.original?.subject?.title}
                  </FormattedValue>
                );
              case Typename.StaticPage:
                return (
                  <FormattedValue
                    dataType={DataType.StaticPages}
                    data={row.original?.subject?.id}
                    deletedAt={row.original?.subject?.deletedAt}
                  >
                    {row.original?.subject?.name}
                  </FormattedValue>
                );
              case Typename.Tag:
                return (
                  <FormattedValue
                    dataType={DataType.Tags}
                    data={row.original?.subject?.id}
                    deletedAt={row.original?.subject?.deletedAt}
                  >
                    {row.original?.subject?.name}
                  </FormattedValue>
                );
              case Typename.User:
                return (
                  <FormattedValue
                    dataType={DataType.Users}
                    data={row.original?.subject?.id}
                    deletedAt={row.original.subject?.deletedAt}
                  >
                    {renderUserLabel(row.original?.subject)}
                  </FormattedValue>
                );
              case Typename.FaqGroup:
                return (
                  <FormattedValue
                    dataType={DataType.Groups}
                    data={row.original?.subject?.id}
                    deletedAt={row.original.subject?.deletedAt}
                  >
                    {row.original.subject?.name}
                  </FormattedValue>
                );
              case Typename.FaqEntry:
                return (
                  <FormattedValue
                    dataType={DataType.Entries}
                    data={row.original?.subject?.id}
                    deletedAt={row.original.subject?.deletedAt}
                  >
                    {row.original.subject?.name}
                  </FormattedValue>
                );
              default:
                <FormattedValue />;
            }
          };

          return row.original.subject?.__typename ? (
            <>{handleSubject(row.original.subject?.__typename)}</>
          ) : (
            <FormattedValue />
          );
        },
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(ActivityLogsField.CreatedAt)}
            sortDirection={
              sortBy === ActivityLogsField.CreatedAt ? sortDirection : null
            }
            $style={{ display: "flex", justifyContent: "flex-end" }}
          >
            Data
          </SortingTableHeader>
        ),
        accessor: ActivityLogsField.CreatedAt,
        Cell: ({ cell }: { cell: TableCell }) => (
          <FormattedValue dataType={DataType.DateTime}>
            {cell.value}
          </FormattedValue>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<ActivityLog> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              onClick={() => history.push(`/activity-logs/${row.original.id}`)}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [sortBy, sortDirection]
  );

  if (!checkPermission(PERMISSIONS.activityLog.read))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title="Logi aktywności"
        recordsNum={data?.activityLogs?.totalCount}
        labels={["Lista"]}
        actions={[
          {
            label: "Eksport do pliku XLSX",
            icon: FileExport,
            color: theme.colors.primary,
            permission: checkPermission(PERMISSIONS.activityLog.read),
            onClick: onSubmit,
          },
        ]}
      />
      <Filters
        filters={ACTIVITY_LOGS_FILTERS}
        state={filters}
        setState={setFilters}
      />
      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <Table<ActivityLog>
              columns={columns}
              data={activityLogs?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>
          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
