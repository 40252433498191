import { useStyletron } from "baseui";
import {
  DatePicker as BaseDatePicker,
  DatepickerProps,
} from "baseui/datepicker";
import { StyledLabel } from "baseui/form-control";
import pl from "date-fns/locale/pl";
import React, { HTMLProps } from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import { StyleObject } from "styletron-react";
import { ChevronDown, X } from "tabler-icons-react";

type Props = {
  $style?: StyleObject;
  date?: Date | null;
  setDate?: (date: Date) => void;
  withTime?: boolean;
  id?: string;
} & DatepickerProps;

export default function DatePicker({
  $style,
  date,
  setDate,
  withTime = false,
  id,
  disabled,
  ...props
}: Props): React.ReactElement {
  const [css, theme] = useStyletron();

  return (
    <BaseDatePicker
      value={date}
      onChange={({ date }) => setDate && setDate(date as Date)}
      locale={pl}
      formatString={withTime ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy"}
      timeSelectStart={withTime}
      placeholder="DD.MM.RRRR"
      clearable
      overrides={{
        Popover: {
          props: {
            overrides: {
              Body: {
                style: {
                  zIndex: 300,
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                  borderLeftWidth: "2px",
                  borderLeftStyle: "solid",
                  borderLeftColor: theme.colors.borderSelected,
                  borderBottomWidth: "2px",
                  borderBottomStyle: "solid",
                  borderBottomColor: theme.colors.borderSelected,
                  borderRightWidth: "2px",
                  borderRightStyle: "solid",
                  borderRightColor: theme.colors.borderSelected,
                  borderTopWidth: "2px",
                  borderTopStyle: "solid",
                  borderTopColor: theme.colors.borderSelected,
                  boxShadow: "unset",
                },
              },
            },
          },
        },
        MonthYearSelectPopover: {
          props: {
            overrides: {
              Body: {
                style: {
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                  borderLeftWidth: "2px",
                  borderLeftStyle: "solid",
                  borderLeftColor: theme.colors.inputFill,
                  borderBottomWidth: "2px",
                  borderBottomStyle: "solid",
                  borderBottomColor: theme.colors.inputFill,
                  borderRightWidth: "2px",
                  borderRightStyle: "solid",
                  borderRightColor: theme.colors.inputFill,
                  borderTopWidth: "2px",
                  borderTopStyle: "solid",
                  borderTopColor: theme.colors.inputFill,
                  boxShadow: "unset",
                  zIndex: 301,
                },
              },
            },
          },
        },
        CalendarHeader: {
          style: {
            backgroundColor: theme.colors.borderSelected,
          },
        },
        Input: {
          props: {
            id,
            size: "compact",
            overrides: {
              Root: {
                style: () => ({
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                  ...$style,
                }),
              },
              ClearIcon: {
                props: {
                  overrides: {
                    Svg: (props: HTMLProps<HTMLElement>) => (
                      <span
                        {...props}
                        className={css({
                          cursor: "pointer",
                          display: "inline",
                          lineHeight: 0,
                        })}
                      >
                        <X color={theme.colors.borderFocus} size={16} />
                      </span>
                    ),
                  },
                },
              },
            },
            disabled,
          },
        },
        MonthHeader: {
          style: {
            backgroundColor: theme.colors.backgroundTertiary,
            color: "#000",
          },
        },
        TimeSelect: {
          props: {
            creatable: true,
            format: "24",
            overrides: {
              Select: {
                props: {
                  size: "compact",
                  overrides: {
                    Root: {
                      style: {
                        borderTopLeftRadius: theme.borders.radius200,
                        borderTopRightRadius: theme.borders.radius200,
                        borderBottomRightRadius: theme.borders.radius200,
                        borderBottomLeftRadius: theme.borders.radius200,
                      },
                    },
                    ControlContainer: {
                      style: {
                        borderTopLeftRadius: theme.borders.radius200,
                        borderTopRightRadius: theme.borders.radius200,
                        borderBottomRightRadius: theme.borders.radius200,
                        borderBottomLeftRadius: theme.borders.radius200,
                        transitionDuration: "100ms",
                      },
                    },
                    LoadingIndicator: {
                      props: {
                        overrides: {
                          ActivePath: {
                            style: {
                              fill: theme.colors.borderSelected,
                            },
                          },
                          Svg: {
                            style: {
                              height: "16px",
                              marginRight: "5px",
                            },
                          },
                        },
                      },
                    },
                    SelectArrow: {
                      props: {
                        overrides: {
                          Svg: () => (
                            <ChevronDown
                              color={theme.colors.borderFocus}
                              size={18}
                            />
                          ),
                        },
                      },
                    },
                    DropdownListItem: {
                      style: {
                        paddingTop: theme.sizing.scale200,
                        paddingBottom: theme.sizing.scale200,
                      },
                    },
                    Dropdown: {
                      style: {
                        boxShadow: "unset",
                        borderTopLeftRadius: theme.borders.radius200,
                        borderTopRightRadius: theme.borders.radius200,
                        borderBottomRightRadius: theme.borders.radius200,
                        borderBottomLeftRadius: theme.borders.radius200,
                        borderLeftWidth: "2px",
                        borderLeftStyle: "solid",
                        borderLeftColor: theme.colors.borderSelected,
                        borderBottomWidth: "2px",
                        borderBottomStyle: "solid",
                        borderBottomColor: theme.colors.borderSelected,
                        borderRightWidth: "2px",
                        borderRightStyle: "solid",
                        borderRightColor: theme.colors.borderSelected,
                        borderTopWidth: "2px",
                        borderTopStyle: "solid",
                        borderTopColor: theme.colors.borderSelected,
                        backgroundColor: theme.colors.inputFill,
                      },
                    },
                    Popover: {
                      props: {
                        ignoreBoundary: true,
                        overrides: {
                          Body: {
                            style: {
                              zIndex: 301,
                              boxShadow: "none",
                              marginLeft: "1px",
                              marginTop: "5px",
                              marginBottom: "5px",
                              backgroundColor: "transparent",
                            },
                          },
                          Inner: {
                            style: {
                              backgroundColor: "transparent",
                            },
                          },
                        },
                      },
                    },
                    StatefulMenu: {
                      props: {
                        overrides: {
                          EmptyState: {
                            style: {
                              color: "#999999",
                              paddingTop: theme.sizing.scale300,
                              paddingBottom: theme.sizing.scale300,
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        TimeSelectFormControl: {
          props: {
            overrides: {
              Label: () => <StyledLabel>Godzina</StyledLabel>,
            },
          },
        },
      }}
      {...props}
    />
  );
}

export function ControlledDatePicker({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value, name } }) => (
        <DatePicker
          value={value}
          onChange={({ date }) => onChange(date as Date)}
          id={name}
          {...rest}
        />
      )}
    />
  );
}
