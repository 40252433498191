import { ApolloError, useMutation } from "@apollo/client";
import { styled } from "baseui";
import { FormControl } from "baseui/form-control";
import { LabelMedium } from "baseui/typography";
import { Image } from "containers/Images/images";
import { ImagesFormInputs } from "containers/Images/images.form";
import { IMAGES_UPDATE } from "containers/Images/images.gql";
import { useLoading } from "contexts/loading-context";
import { enqueueSnackbar } from "notistack";
import React, { Fragment, ReactElement, useEffect } from "react";
import { useForm } from "react-hook-form";

import { Button } from "../button";
import { FormattedValue } from "../formatted-value";
import { ControlledInput } from "../input";

type ImageDetailsProps = {
  image: Image;
  onChange: () => void;
};

type FormInputs = {
  alt?: string;
  tags?: { id: number; label?: string }[];
  tagIds?: number[] | [];
};

const ImageThumbnail = styled("div", ({ $theme }) => ({
  backgroundColor: $theme.colors.backgroundTertiary,
  backgroundPosition: "center center",
  backgroundSize: "auto 90%",
  backgroundRepeat: "no-repeat",
  height: "200px",
}));

export default function ImageDetails({
  image,
  onChange,
}: ImageDetailsProps): ReactElement {
  const { setIsLoading } = useLoading();

  const [updateImage] = useMutation(IMAGES_UPDATE);

  const onSubmit = async ({ ...values }: ImagesFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await updateImage({
        variables: {
          imageUpdateInput: {
            id: image?.id,
            ...values,
          },
        },
      });
      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });

      onChange();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<FormInputs>();

  useEffect(() => {
    setValue("alt", image.alt ?? "");
  }, [image]);

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <LabelMedium marginBottom="scale400">{image.originalName}</LabelMedium>

        <ImageThumbnail
          $style={{
            backgroundImage: `url(${image?.url})`,
          }}
        />
        <FormControl label="Utworzenie" disabled={true}>
          <FormattedValue dataType="date">{image.createdAt}</FormattedValue>
        </FormControl>
        <FormControl
          label="Tekst alternatywny"
          error={errors.alt && errors.alt.message}
        >
          <ControlledInput control={control} name="alt" error={!!errors.alt} />
        </FormControl>

        <Button type="submit">Zapisz</Button>
      </form>
    </Fragment>
  );
}
