import { gql } from "@apollo/client";

export const SEO_TAGSETS_INDEX = gql`
  query SeoTagsets(
    $pageSize: Int
    $offset: Int
    $filter: SeoTagsetFilter
    $sorting: [SeoTagsetSort!]
  ) {
    seoTagsets(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        subjectType
        subjectId
        subject {
          ... on Category {
            __typename
            id
            name
            slug
            deletedAt
          }
          ... on Event {
            __typename
            id
            name
            slug
            deletedAt
          }
          ... on EventAggregator {
            __typename
            id
            name
            slug
            deletedAt
          }
          ... on StaticPage {
            __typename
            id
            name
            slug
            deletedAt
          }
        }
        metaTitle
        metaDescription
        metaKeywords
        ogTitle
        ogDescription
        canonical
        robots
      }
    }
  }
`;

export const SEO_TAGSETS_SHOW = gql`
  query SeoTagsetShow($id: Int!) {
    seoTagset(id: $id) {
      id
      subjectType
      subjectId
      subject {
        ... on Category {
          __typename
          id
          name
          slug
          deletedAt
        }
        ... on Event {
          __typename
          id
          name
          slug
          deletedAt
        }
        ... on EventAggregator {
          __typename
          id
          name
          slug
          deletedAt
        }
        ... on StaticPage {
          __typename
          id
          name
          slug
          deletedAt
        }
      }
      metaTitle
      metaDescription
      metaKeywords
      ogTitle
      ogDescription
      ogImageUrl
      canonical
      robots
    }
  }
`;

export const SEO_TAGSETS_UPDATE = gql`
  mutation SeoTagsetUpdate($seoTagsetUpdateInput: SeoTagsetUpdateInput!) {
    seoTagsetUpdate(seoTagsetUpdateInput: $seoTagsetUpdateInput) {
      id
    }
  }
`;

export const SEO_TAGSETS_EXPORT = gql`
  mutation SeoTagsetsExport(
    $filter: SeoTagsetFilter
    $sorting: [SeoTagsetSort!]
  ) {
    seoTagsetsExport(
      paging: { limit: 100000 }
      sorting: $sorting
      filter: $filter
    )
  }
`;
