import { OutputData } from "@editorjs/editorjs";
import { DataType } from "components/formatted-value";
import { SelectValue } from "components/select/select";
import { SeoTagsetsField } from "containers/SeoTagsets/seo-tagsets.form";

import { DictionarySystemName } from "../../constants";
import { FieldsGroup, FieldType } from "../../fields.d";

export type StaticPagesFormInputs = {
  name: string;
  slug: string;
  content: OutputData[];
  redirectUrl: string;
  isVisible: boolean;
  metaTitle: string;
  metaDescription: string;
  metaKeywords: string;
  canonical: string;
  robots: SelectValue;
  ogTitle: string;
  ogDescription: string;
  ogImage: File;
  showInMainMenu: boolean;
  showInFooterMenu: boolean;
  position: number;
};

export enum StaticPagesField {
  Name = "name",
  Slug = "slug",
  Content = "content",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  IsVisible = "isVisible",
  RedirectUrl = "redirectUrl",
  SeoTagset = "seoTagset",
  ShowInMainMenu = "showInMainMenu",
  ShowInFooterMenu = "showInFooterMenu",
  Position = "position",
}

export const STATIC_PAGES_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: StaticPagesField.Name,
        label: "Nazwa",
        type: FieldType.BigInput,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: StaticPagesField.Slug,
        label: "Slug",
        type: FieldType.SlugInput,
        dataType: DataType.Pre,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        update: { visible: false },
        caption:
          "Slug jest generowany automatycznie na podstawie nazwy. Możesz go zmienić, edytując powyższe pole.",
      },
      {
        id: StaticPagesField.Slug,
        label: "Link do strony informacyjnej",
        type: FieldType.Link,
        span: 12,
        show: { visible: true },
        caption:
          "Link do udostępnienia. Uwaga: Jeśli widoczność jest ustawiona na „nie”, pozycja nie jest dostępna publicznie",
        create: { visible: false, required: false },
        update: { visible: false, required: false },
      },
      {
        id: StaticPagesField.Slug,
        label: "Slug",
        type: FieldType.SlugInput,
        dataType: DataType.Pre,
        span: 12,
        show: { visible: false },
        create: { visible: false },
        update: { visible: true },
        caption: "Możesz zmienić slug, edytując powyższe pole",
      },
      {
        id: StaticPagesField.IsVisible,
        label: "Widoczność",
        type: FieldType.Switch,
        dataType: DataType.VisibilityBoolean,
        readonly: true,
        caption: "Widoczność określa, czy ta pozycja jest publiczna",
        span: 6,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: StaticPagesField.RedirectUrl,
        label: "Link do przekierowania",
        type: FieldType.Input,
        dataType: DataType.Link,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: StaticPagesField.ShowInMainMenu,
        label: "Pokazuj w menu głównym",
        type: FieldType.Switch,
        dataType: DataType.VisibilityBoolean,
        span: 4,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: StaticPagesField.ShowInFooterMenu,
        label: "Pokazuj w stopce",
        type: FieldType.Switch,
        dataType: DataType.VisibilityBoolean,
        span: 4,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: StaticPagesField.Position,
        label: "Pozycja",
        type: FieldType.NumberInput,
        span: 4,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true, required: true },
      },
    ],
  },
  {
    id: "content",
    label: "Treść",
    fields: [
      {
        id: StaticPagesField.Content,
        label: "",
        span: 12,
        type: FieldType.Editor,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "seo",
    label: "Tagi SEO",
    fields: [
      {
        id: SeoTagsetsField.MetaTitle,
        label: "Tytuł meta",
        caption:
          "Tytuł strony wyświetlany na pasku przeglądarki i w wynikach wyszukiwania. Maks. 70 znaków ze spacjami",
        maxLength: 70,
        span: 6,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [StaticPagesField.SeoTagset, SeoTagsetsField.MetaTitle],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.OgTitle,
        label: "Tytuł OG",
        caption:
          "Tytuł strony, który jest wykorzystywany przy udostępnianiu linku na platformach społecznościowych. Maks. 70 znaków ze spacjami",
        maxLength: 70,
        span: 6,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [StaticPagesField.SeoTagset, SeoTagsetsField.OgTitle],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.MetaDescription,
        label: "Opis meta",
        caption:
          "Krótka treść opisująca zawartość strony, która wyświetla się w wynikach wyszukiwania. Maks. 160 znaków ze spacjami",
        maxLength: 160,
        span: 6,
        type: FieldType.TextArea,
        show: {
          visible: true,
          accessor: [
            StaticPagesField.SeoTagset,
            SeoTagsetsField.MetaDescription,
          ],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.OgDescription,
        label: "Opis OG",
        maxLength: 200,
        span: 6,
        caption:
          "Krótka treść opisująca zawartość strony, która wyświetla się pod linkiem przy udostępnianiu na platformach społecznościowych. Maks. 200 znaków ze spacjami",
        type: FieldType.TextArea,
        show: {
          visible: true,
          accessor: [StaticPagesField.SeoTagset, SeoTagsetsField.OgDescription],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.OgImageUrl,
        label: "Grafika OG",
        caption:
          "Grafika, która wyświetla się razem linkiem przy udostępnianiu strony na platformach społecznościowych.",
        type: FieldType.OgImage,
        span: 12,
        show: {
          visible: true,
          accessor: [StaticPagesField.SeoTagset, SeoTagsetsField.OgImageUrl],
        },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: SeoTagsetsField.OgImage,
        label: "Grafika OG",
        caption:
          "Grafika, która wyświetla się razem linkiem przy udostępnianiu strony na platformach społecznościowych. Proporcje kadru: 2.1:1.",
        type: FieldType.ImageCropper,
        imageRequirements: {
          aspect: 1920 / 915,
        },
        span: 12,
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.MetaKeywords,
        label: "Słowa kluczowe meta",
        caption: "Wpisz po przecinku",
        span: 12,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [StaticPagesField.SeoTagset, SeoTagsetsField.MetaKeywords],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.Robots,
        label: "Robots",
        span: 4,
        type: FieldType.DictionaryValuesSelect,
        caption:
          "Pozwala na kontrolowanie zachowania robotów indeksujących wyszukiwarek na stronie",
        dictionarySystemName: DictionarySystemName.RobotsSeoTagsets,
        show: {
          visible: true,
          accessor: [StaticPagesField.SeoTagset, SeoTagsetsField.Robots],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.Canonical,
        label: "Link canonical",
        span: 8,
        caption:
          "Informuje wyszukiwarki, która wersja strony jest preferowana, co pomaga uniknąć problemów związanych z duplikatami treści",
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [StaticPagesField.SeoTagset, SeoTagsetsField.Canonical],
        },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "system",
    label: "Dane systemowe",
    fields: [
      {
        id: StaticPagesField.CreatedAt,
        label: "Utworzenie",
        span: 6,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: StaticPagesField.UpdatedAt,
        label: "Ostatnia aktualizacja",
        span: 6,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
];
