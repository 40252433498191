import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND, SIZE } from "baseui/button";
import { BottomPanel } from "components/bottom-panel";
import { Button } from "components/button";
import { Cell } from "components/cell";
import { Content } from "components/content";
import { Filters } from "components/filters";
import { DataType, FormattedValue } from "components/formatted-value";
import { Grid } from "components/grid";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { PagingControls } from "components/pagination";
import { Table } from "components/table";
import SortingTableHeader, {
  SortDirection,
} from "components/table/sorting-table-header";
import { Tooltip } from "components/tooltip";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { usePaging } from "contexts/paging-context";
import { BasicFilter, FiltersState } from "filters";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Row } from "react-table";
import { Eye, Plus } from "tabler-icons-react";
import { translateFiltersState } from "utils/filters";
import { PERMISSIONS } from "utils/permissions";

import { StaticPage } from "../static-pages";
import { STATIC_PAGES_FILTERS } from "../static-pages.filters";
import { StaticPagesField } from "../static-pages.form";
import { STATIC_PAGES_INDEX, STATIC_PAGES_UPDATE } from "../static-pages.gql";

export default function StaticPagesIndex(): React.ReactElement {
  const { pageSize, currentPage, setTotalCount } = usePaging();
  const [sortBy, setSortBy] = useState<StaticPagesField | null>(
    StaticPagesField.Name
  );
  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    SortDirection.ASC
  );
  const { enqueueSnackbar } = useSnackbar();
  const [css] = useStyletron();
  const {
    isFetching,
    setIsFetching,
    setIsLoading,
    isPartialFetching,
    setIsPartialFetching,
  } = useLoading();
  const history = useHistory();
  const { checkPermission } = useAuth();
  const [filters, setFilters] = useState<FiltersState>();

  const handleSorting = (column: StaticPagesField) => {
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const { refetch, data, loading, error } = useQuery(STATIC_PAGES_INDEX, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      filter: {
        and: [
          {
            ...(filters && (translateFiltersState(filters) as BasicFilter[])),
          },
        ],
      },
    },
  });

  const staticPages: { totalCount: number; nodes: StaticPage[] } =
    data?.staticPages;

  useEffect(() => {
    if (data?.staticPages) setTimeout(() => refetch(), 100);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    refetch();
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    refetch();
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.staticPages) setIsFetching(false);
    if (staticPages?.totalCount >= 0) setTotalCount(staticPages?.totalCount);
  }, [data]);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  const [updateStaticPage] = useMutation(STATIC_PAGES_UPDATE);

  const onSubmit = async (
    staticPage: StaticPage,
    fieldName: string
  ): Promise<void> => {
    setIsLoading(true);

    const updatedValues: Partial<StaticPage> = {
      id: staticPage.id,
      isVisible: staticPage.isVisible,
      showInMainMenu: staticPage.showInMainMenu,
      showInFooterMenu: staticPage.showInFooterMenu,
    };
    let successMessage = "";

    switch (fieldName) {
      case "isVisible":
        updatedValues.isVisible = !staticPage.isVisible;
        successMessage = `Strona informacyjna "${staticPage.name}" jest ${
          staticPage.isVisible ? "niewidoczna" : "widoczna"
        }`;
        break;
      case "showInMainMenu":
        updatedValues.showInMainMenu = !staticPage.showInMainMenu;
        successMessage = `Strona informacyjna "${
          staticPage.name
        }" w menu głównym jest ${
          staticPage.showInMainMenu ? "niewidoczna" : "widoczna"
        }`;
        break;
      case "showInFooterMenu":
        updatedValues.showInFooterMenu = !staticPage.showInFooterMenu;
        successMessage = `Strona informacyjna "${
          staticPage.name
        }" w menu stopki jest ${
          staticPage.showInFooterMenu ? "niewidoczna" : "widoczna"
        }`;
        break;
      default:
        break;
    }

    try {
      await updateStaticPage({
        variables: {
          staticPageUpdateInput: updatedValues,
        },
      });
      enqueueSnackbar({
        message: successMessage,
        variant: "success",
      });
      refetch();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(StaticPagesField.Name)}
            sortDirection={
              sortBy === StaticPagesField.Name ? sortDirection : null
            }
          >
            Nazwa
          </SortingTableHeader>
        ),
        accessor: StaticPagesField.Name,
        Cell: ({ row }: { row: Row<StaticPage> }) => (
          <FormattedValue
            dataType={DataType.StaticPages}
            data={row.original.id}
          >
            {row.original.name}
          </FormattedValue>
        ),
        disableGlobalFilter: true,
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(StaticPagesField.Slug)}
            sortDirection={
              sortBy === StaticPagesField.Slug ? sortDirection : null
            }
          >
            Slug
          </SortingTableHeader>
        ),
        accessor: StaticPagesField.Slug,
        Cell: ({ row }: { row: Row<StaticPage> }) => (
          <FormattedValue dataType={DataType.Pre} $style={{ fontSize: "12px" }}>
            {row.original.slug}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(StaticPagesField.RedirectUrl)}
            sortDirection={
              sortBy === StaticPagesField.RedirectUrl ? sortDirection : null
            }
          >
            Link do przekierowania
          </SortingTableHeader>
        ),
        accessor: StaticPagesField.RedirectUrl,
        Cell: ({ row }: { row: Row<StaticPage> }) => (
          <FormattedValue dataType={DataType.Link}>
            {row.original.redirectUrl}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(StaticPagesField.ShowInMainMenu)}
            sortDirection={
              sortBy === StaticPagesField.ShowInMainMenu ? sortDirection : null
            }
          >
            Widoczność w menu głównym
          </SortingTableHeader>
        ),
        accessor: StaticPagesField.ShowInMainMenu,
        Cell: ({ row }: { row: Row<StaticPage> }) => (
          <Tooltip
            content={`Ustaw jako ${
              row.original.showInMainMenu ? "niewidoczna" : "widoczna"
            }`}
            placement="left"
          >
            <span
              onClick={() => onSubmit(row.original, "showInMainMenu")}
              className={css({
                cursor: "pointer",
              })}
            >
              <FormattedValue dataType={DataType.VisibilityBoolean}>
                {row.original.showInMainMenu}
              </FormattedValue>
            </span>
          </Tooltip>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(StaticPagesField.ShowInFooterMenu)}
            sortDirection={
              sortBy === StaticPagesField.ShowInFooterMenu
                ? sortDirection
                : null
            }
          >
            Widoczność w menu stopki
          </SortingTableHeader>
        ),
        accessor: StaticPagesField.ShowInFooterMenu,
        Cell: ({ row }: { row: Row<StaticPage> }) => (
          <Tooltip
            content={`Ustaw jako ${
              row.original.showInFooterMenu ? "niewidoczna" : "widoczna"
            }`}
            placement="right"
          >
            <span
              onClick={() => onSubmit(row.original, "showInFooterMenu")}
              className={css({
                cursor: "pointer",
              })}
            >
              <FormattedValue dataType={DataType.VisibilityBoolean}>
                {row.original.showInFooterMenu}
              </FormattedValue>
            </span>
          </Tooltip>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(StaticPagesField.Position)}
            sortDirection={
              sortBy === StaticPagesField.Position ? sortDirection : null
            }
          >
            Pozycja
          </SortingTableHeader>
        ),
        accessor: StaticPagesField.Position,
        Cell: ({ row }: { row: Row<StaticPage> }) => (
          <FormattedValue dataType={DataType.Pre} $style={{ fontSize: "12px" }}>
            {row.original.position}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(StaticPagesField.CreatedAt)}
            sortDirection={
              sortBy === StaticPagesField.CreatedAt ? sortDirection : null
            }
          >
            Utworzenie
          </SortingTableHeader>
        ),
        accessor: StaticPagesField.CreatedAt,
        Cell: ({ row }: { row: Row<StaticPage> }) => (
          <FormattedValue dataType={DataType.Date}>
            {row.original.createdAt}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(StaticPagesField.UpdatedAt)}
            sortDirection={
              sortBy === StaticPagesField.UpdatedAt ? sortDirection : null
            }
          >
            Ostatnia aktualizacja
          </SortingTableHeader>
        ),
        accessor: StaticPagesField.UpdatedAt,
        Cell: ({ row }: { row: Row<StaticPage> }) => (
          <FormattedValue dataType={DataType.Date}>
            {row.original.updatedAt}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(StaticPagesField.IsVisible)}
            sortDirection={
              sortBy === StaticPagesField.IsVisible ? sortDirection : null
            }
          >
            Widoczność
          </SortingTableHeader>
        ),
        accessor: StaticPagesField.IsVisible,
        Cell: ({ row }: { row: Row<StaticPage> }) => (
          <Tooltip
            content={`Ustaw jako ${
              row.original.isVisible ? "niewidoczna" : "widoczna"
            }`}
            placement="right"
          >
            <span
              onClick={() => onSubmit(row.original, "isVisible")}
              className={css({
                cursor: "pointer",
              })}
            >
              <FormattedValue dataType={DataType.VisibilityBoolean}>
                {row.original.isVisible}
              </FormattedValue>
            </span>
          </Tooltip>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<StaticPage> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $style={{ marginLeft: "6px" }}
              onClick={() => history.push(`/static-pages/${row.original.id}`)}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [sortBy, sortDirection]
  );

  if (!checkPermission(PERMISSIONS.staticPage.read))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title="Strony informacyjne"
        recordsNum={staticPages?.totalCount}
        labels={["Lista"]}
        buttons={[
          {
            label: "Dodaj stronę informacyjną",
            kind: KIND.primary,
            startEnhancer: <Plus size={18} />,
            permission: checkPermission(PERMISSIONS.staticPage.create),
            onClick: () => history.push("/static-pages/create"),
          },
        ]}
      />
      <Filters
        filters={STATIC_PAGES_FILTERS}
        state={filters}
        setState={setFilters}
      />
      <Content filtersOffset>
        <Grid>
          <Cell span={12}>
            <Table<StaticPage>
              columns={columns}
              data={staticPages?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>
          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
