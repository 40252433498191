import { ApolloError, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { Cell } from "components/cell";
import { Content } from "components/content";
import { FormControl } from "components/form-control";
import { DataType, FormattedValue } from "components/formatted-value";
import { Grid } from "components/grid";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { PERMISSIONS } from "utils/permissions";
import { renderUserLabel } from "utils/render-user-label";

import { Typename } from "../../../constants";
import { ActivityLog, ActivityLogSubject } from "../activity-logs";
import { ACTIVITY_LOGS_FIELDS, ActivityLogsField } from "../activity-logs.form";
import { ACTIVITY_LOGS_SHOW } from "../activity-logs.gql";

export default function ActivityLogsShow(): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id?: string }>();
  const { isFetching, setIsFetching } = useLoading();
  const { checkPermission } = useAuth();

  const { refetch, data, error } = useQuery(ACTIVITY_LOGS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
    fetchPolicy: "cache-first",
  });
  const activityLog: ActivityLog = data?.activityLog;

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.activityLog) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.activityLog && setIsFetching(false), [data]);

  const handleActivityLogSubject = (
    typename: string,
    subject: ActivityLogSubject
  ) => {
    switch (typename) {
      case Typename.Category:
        return (
          <FormattedValue
            dataType={DataType.Categories}
            data={subject?.id}
            deletedAt={subject?.deletedAt}
          >
            {subject?.name}
          </FormattedValue>
        );
      case Typename.Dictionary:
        return (
          <FormattedValue
            dataType={DataType.Dictionaries}
            data={subject?.id}
            deletedAt={subject?.deletedAt}
          >
            {subject?.name}
          </FormattedValue>
        );
      case Typename.DictionaryValue:
        return (
          <FormattedValue
            dataType={DataType.Dictionaries}
            data={subject?.dictionaryId}
            deletedAt={subject?.deletedAt}
          >
            {`${subject?.name} (słownik: ${subject?.dictionary?.name})`}
          </FormattedValue>
        );
      case Typename.Event:
        return (
          <FormattedValue
            dataType={DataType.Events}
            data={subject?.id}
            deletedAt={subject?.deletedAt}
          >
            {subject?.name}
          </FormattedValue>
        );
      case Typename.EventAggregator:
        return (
          <FormattedValue
            dataType={DataType.EventAggregators}
            data={subject?.id}
            deletedAt={subject?.deletedAt}
          >
            {subject?.name}
          </FormattedValue>
        );
      case Typename.Organizer:
        return (
          <FormattedValue
            dataType={DataType.Organizers}
            data={subject?.id}
            deletedAt={subject?.deletedAt}
          >
            {subject?.name}
          </FormattedValue>
        );
      case Typename.Image:
        return (
          <FormattedValue
            dataType={DataType.Images}
            data={subject?.id}
            deletedAt={subject?.deletedAt}
          >
            {subject?.originalName}
          </FormattedValue>
        );
      case Typename.Role:
        return (
          <FormattedValue
            dataType={DataType.Roles}
            data={subject?.id}
            deletedAt={subject?.deletedAt}
          >
            {subject?.name}
          </FormattedValue>
        );
      case Typename.SeoTagset:
        return (
          <FormattedValue
            dataType={DataType.SeoTagsets}
            data={subject?.id}
            deletedAt={subject?.deletedAt}
          >
            {`${subject?.id} (obiekt ${subject?.subject?.name})`}
          </FormattedValue>
        );
      case Typename.Slide:
        return (
          <FormattedValue
            dataType={DataType.Slides}
            data={subject?.id}
            deletedAt={subject?.deletedAt}
          >
            {subject?.title}
          </FormattedValue>
        );
      case Typename.StaticPage:
        return (
          <FormattedValue
            dataType={DataType.StaticPages}
            data={subject?.id}
            deletedAt={subject?.deletedAt}
          >
            {subject?.name}
          </FormattedValue>
        );
      case Typename.Tag:
        return (
          <FormattedValue
            dataType={DataType.Tags}
            data={subject?.id}
            deletedAt={subject?.deletedAt}
          >
            {subject?.name}
          </FormattedValue>
        );
      case Typename.User:
        return (
          <FormattedValue
            dataType={DataType.Users}
            data={subject?.id}
            deletedAt={subject?.deletedAt}
          >
            {renderUserLabel(subject)}
          </FormattedValue>
        );
      case Typename.FaqGroup:
        return (
          <FormattedValue
            dataType={DataType.Groups}
            data={subject?.id}
            deletedAt={subject?.deletedAt}
          >
            {subject?.name}
          </FormattedValue>
        );
      case Typename.FaqEntry:
        return (
          <FormattedValue
            dataType={DataType.Entries}
            data={subject?.id}
            deletedAt={subject?.deletedAt}
          >
            {subject?.name}
          </FormattedValue>
        );
      default:
        <FormattedValue />;
    }
  };

  if (!checkPermission(PERMISSIONS.activityLog.read))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title={`Log ${activityLog?.id}`}
        labels={["Logi aktywności", "Wyświetlanie"]}
        goBackOption
      />
      <Content>
        <Grid>
          {ACTIVITY_LOGS_FIELDS.filter(
            (g) =>
              g.fields.filter((f) => f.show.visible).length > 0 &&
              ((g.accessor && data?.activityLog[g.accessor]) || !g.accessor)
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter(
                (f) =>
                  f.show.visible &&
                  ((f.typeName &&
                    f.typeName === data?.activityLog?.subject?.__typename) ||
                    !f.typeName)
              )
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : item.typeName ? (
                      handleActivityLogSubject(
                        item.typeName,
                        activityLog?.subject
                      )
                    ) : item.id === ActivityLogsField.User ? (
                      activityLog?.user?.id === undefined ? (
                        activityLog?.properties?.login ? (
                          <FormattedValue dataType={DataType.Login}>
                            {data?.activityLog?.properties?.login}
                          </FormattedValue>
                        ) : (
                          <FormattedValue dataType={DataType.System} />
                        )
                      ) : (
                        <FormattedValue
                          dataType={DataType.Users}
                          data={activityLog?.user?.id}
                          deletedAt={activityLog?.user?.deletedAt}
                        >
                          {renderUserLabel(activityLog?.user)}
                        </FormattedValue>
                      )
                    ) : item.info === ActivityLogsField.Properties ? (
                      activityLog?.properties?.clientInfo ? (
                        <FormattedValue
                          dataType={item.dataType}
                          data={data?.activityLog[item.id]}
                        >
                          {activityLog?.properties?.clientInfo[item.id]}
                        </FormattedValue>
                      ) : (
                        <FormattedValue
                          dataType={item.dataType}
                          data={data?.activityLog[item.id]}
                        >
                          {item.show.accessor
                            ? data?.activityLog[item.show.accessor[0]]?.[
                                item.show.accessor[1]
                              ]
                            : data?.activityLog[item.id]}
                        </FormattedValue>
                      )
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={data?.activityLog[item.id]}
                      >
                        {item.show.accessor
                          ? data?.activityLog[item.show.accessor[0]]?.[
                              item.show.accessor[1]
                            ]
                          : data?.activityLog[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
        </Grid>
      </Content>
    </article>
  );
}
