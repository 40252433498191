export const FORM_VALIDATION_MESSAGES = {
  differentPasswords: "Podane nowe hasła są różne",
  incorrectEmail: "Adres e-mail jest nieprawidłowy",
  maxLength: "Przekroczono dozwoloną liczbę znaków",
  required: "To pole jest wymagane",
  incorrectLink: "Podany link jest nieprawidłowy",
  requiredPermission: "Należy zaznaczyć przynajmniej jedno uprawnienie",
  unsavedPhoto: "Nie zapisano zdjęcia",
  bannedWords: "Pole zawiera niedozwolone frazy",
};
