import { GroupsField } from "containers/Faq/Groups/groups.form";
import { BasicFilter, FilterType } from "filters.d";

export const GROUPS_FILTERS: BasicFilter[] = [
  {
    id: GroupsField.Name,
    label: "Nazwa",
    type: FilterType.Text,
  },
  {
    id: GroupsField.IsVisible,
    label: "Widoczność",
    type: FilterType.Boolean,
  },
  {
    id: GroupsField.Position,
    label: "Pozycja",
    type: FilterType.Position,
  },
  {
    id: GroupsField.CreatedAt,
    label: "Utworzenie",
    type: FilterType.Date,
  },
  {
    id: GroupsField.CreatedBy,
    label: "Utworzono przez",
    type: FilterType.User,
  },
  {
    id: GroupsField.UpdatedAt,
    label: "Ostatnia aktualizacja",
    type: FilterType.Date,
  },
  {
    id: GroupsField.UpdatedBy,
    label: "Zaktualizowano przez",
    type: FilterType.User,
  },
];
