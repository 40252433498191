import { Tag as BaseTag } from "baseui/tag";
import React, { ReactElement, ReactNode } from "react";
import { getTextColor } from "utils/text-color";

type TagProps = {
  children?: string | ReactNode;
  backgroundColor?: string;
};

export default function Tag({
  children,
  backgroundColor = "#10069F",
}: TagProps): ReactElement {
  return (
    <BaseTag
      closeable={false}
      overrides={{
        Root: {
          style: {
            backgroundColor,
            border: "none",
            paddingTop: "4px",
            paddingBottom: "4px",
            paddingLeft: "8px",
            paddingRight: "8px",
            borderRadius: "16px",
            height: "20px",
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: 0,
          },
        },
        Text: {
          style: {
            color: getTextColor(backgroundColor),
            textTransform: "uppercase",
            maxWidth: "100%",
            lineClamp: 1,
          },
        },
      }}
    >
      {children}
    </BaseTag>
  );
}
