import { useLazyQuery, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Button } from "components/button";
import { DataType, FormattedValue } from "components/formatted-value";
import { Table } from "components/table";
import { Event } from "containers/Events/events";
import { EventsField } from "containers/Events/events.form";
import {
  EVENTS_LIVE_SEARCH,
  EVENTS_SELECT,
} from "containers/Events/events.gql";
import { useAuth } from "contexts/auth-context";
import React, { FormEvent, Fragment, useEffect } from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import { Row } from "react-table";
import { Ban, Trash } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import Select, { LiveSearchSelect, SelectProps } from "./select";

export default function ControlledEventsSelect({
  control,
  disabled,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  const { checkPermission } = useAuth();
  const [css] = useStyletron();

  const { data, loading } = useQuery(EVENTS_SELECT);

  const hasPermission = checkPermission(PERMISSIONS.event.read);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={data?.events?.nodes?.map(({ id, name }: Event) => ({
            id,
            label: name,
          }))}
          placeholder={
            hasPermission ? (
              "Wybierz"
            ) : (
              <div
                className={css({
                  display: "flex",
                  alignItems: "center",
                  gap: " 5px",
                })}
              >
                <Ban size={16} />
                Brak uprawnień do przeglądania wydarzeń
              </div>
            )
          }
          disabled={disabled || !hasPermission}
          isLoading={loading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          {...(data && { value: value })}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledEventsLiveSearchSelect({
  control,
  disabled,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  const { checkPermission } = useAuth();
  const [css] = useStyletron();
  const [fetchData, { data, loading }] = useLazyQuery(EVENTS_LIVE_SEARCH);

  useEffect(() => fetchData(), []);

  const hasPermission = checkPermission(PERMISSIONS.event.read);

  const columns = React.useMemo(
    () => [
      {
        Header: "Nazwa",
        accessor: EventsField.Name,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue dataType={DataType.Events} data={row.original.id}>
            {row.original.name}
          </FormattedValue>
        ),
        disableGlobalFilter: true,
      },
      {
        Header: "Kategoria",
        accessor: EventsField.Categories,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue
            dataType={DataType.Categories}
            data={row.original.categories?.[0]?.id}
          >
            {row.original.categories?.[0]?.name}
          </FormattedValue>
        ),
      },
      {
        Header: "Organizator",
        accessor: EventsField.Organizer,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue
            dataType={DataType.Organizers}
            data={row.original.organizer.id}
          >
            {row.original.organizer.name}
          </FormattedValue>
        ),
      },

      {
        Header: "Widoczność",
        accessor: EventsField.IsVisible,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue dataType={DataType.VisibilityBoolean}>
            {row.original.isVisible}
          </FormattedValue>
        ),
      },
    ],
    []
  );

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Fragment>
          <LiveSearchSelect
            id={name}
            options={data?.events?.nodes.map((event: Event) => ({
              label: event?.name,
              ...event,
            }))}
            getValueLabel={({ option }) => `${option?.label}`}
            getOptionLabel={({ option }) => `${option?.label}`}
            isLoading={loading}
            multi
            onInputChange={(event: FormEvent) => {
              fetchData({
                variables: {
                  filter: {
                    name: {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      like: `%${(event.currentTarget as any).value}%`,
                    },
                  },
                },
              });
            }}
            placeholder={
              hasPermission ? (
                "Wybierz"
              ) : (
                <div
                  className={css({
                    display: "flex",
                    alignItems: "center",
                    gap: " 5px",
                  })}
                >
                  <Ban size={16} />
                  Brak uprawnień do przeglądania użytkowników
                </div>
              )
            }
            disabled={disabled || !hasPermission}
            onClose={fetchData}
            onChange={(params) => params && onChange(params.value)}
            onBlur={onBlur}
            overrides={{
              Tag: {
                component: () => null,
              },
              ClearIcon: {
                component: () => null,
              },
              Popover: {
                props: {
                  placement: "top",
                },
              },
            }}
            backspaceRemoves={false}
            {...(data && { value: value })}
            {...rest}
          />

          {value?.length > 0 && (
            <Block marginTop="16px">
              <Table<Event>
                compact
                columns={[
                  ...columns,
                  {
                    id: "actions",
                    Cell: ({ row }: { row: Row<Event> }) => (
                      <div
                        className={css({
                          display: "flex",
                          justifyContent: "flex-end",
                        })}
                      >
                        <Button
                          kind={KIND.secondary}
                          size={SIZE.mini}
                          $style={{ marginLeft: "6px" }}
                          onClick={() =>
                            onChange(
                              value.filter(
                                ({ id }: Event) => id !== row.original.id
                              )
                            )
                          }
                          startEnhancer={<Trash size={14} />}
                        />
                      </div>
                    ),
                  },
                ]}
                data={value}
                stickLastColumn
              />
            </Block>
          )}
        </Fragment>
      )}
    />
  );
}
