import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { styled } from "baseui";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { StyledSpinnerNext } from "baseui/spinner";
import { Theme } from "baseui/theme";
import { LabelSmall } from "baseui/typography";
import { Modal } from "components/modal";
import { Image } from "containers/Images/images";
import { IMAGES_INDEX, IMAGES_UPLOAD } from "containers/Images/images.gql";
import { useSnackbar } from "notistack";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";

import { Button } from "../components/button";
import { ImageDetails } from "../components/image-library";
import { ImagePreview } from "../components/image-library";
import { useLoading } from "./loading-context";

type ImageLibraryOpenCallback = (image: Image) => void;

type ImageLibraryContextProps = {
  open: (callback: ImageLibraryOpenCallback) => void;
};

export const ImageLibraryContext = createContext<ImageLibraryContextProps>(
  {} as ImageLibraryContextProps
);

const Dropzone = styled<{ isDragActive: boolean }, "div", Theme>(
  "div",
  ({ $theme, isDragActive }) => ({
    width: "100%",
    height: "100%",
    backgroundColor: isDragActive
      ? $theme.colors.backgroundLightAccent
      : $theme.colors.backgroundPrimary,
    borderRadius: $theme.borders.radius300,
    borderWidth: 2,
    borderStyle: "dashed",
    borderColor: isDragActive ? $theme.colors.accent : "transparent",
    padding: isDragActive ? $theme.sizing.scale400 : 0,
  })
);

const LibraryRoot = styled("div", {
  display: "flex",
  overflow: "hidden",
  height: "100%",
});

const Sidebar = styled("div", ({ $theme }) => ({
  width: "400px",
  flexShrink: 0,
  marginLeft: $theme.sizing.scale1200,
  boxSizing: "border-box",
  overflow: "scroll",
}));

const LibraryImages = styled("div", () => ({
  flexGrow: 1,
  overflow: "scroll",
}));

export function ImageLibraryProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);
  const [uploadingImages, setUploadingImages] = useState<Partial<Image>[]>([]);

  const openCallback = useRef<ImageLibraryOpenCallback | null>(null);

  const { setIsLoading } = useLoading();

  const [uploadImage] = useMutation(IMAGES_UPLOAD);

  const uploadImageMutation = async (file: File): Promise<void> => {
    setIsLoading(true);

    try {
      await uploadImage({
        variables: {
          imageUploadInput: {
            file,
          },
        },
      });

      enqueueSnackbar({
        message: "Wgrano pomyślnie",
        variant: "success",
      });
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const { enqueueSnackbar } = useSnackbar();
  const { setIsPartialFetching } = useLoading();

  const { refetch, data, loading, error: queryError } = useQuery(IMAGES_INDEX, {
    variables: {
      sorting: {
        field: "createdAt",
        direction: "DESC",
      },

      pageSize: 10000,
    },
  });

  const images: { totalCount: number; nodes: Image[] } = data?.images;

  useEffect(() => {
    if (data?.images) setTimeout(() => refetch(), 100);
    setIsPartialFetching(true);
  }, []);

  useEffect(() => {
    if (data?.images) setIsPartialFetching(false);
  }, [data]);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  const onDrop = useCallback(async (acceptedFiles) => {
    for (const file of acceptedFiles) {
      const image: Partial<Image> = {
        originalName: file.name,
      };

      setUploadingImages((uploadingImages) => [...uploadingImages, image]);

      await uploadImageMutation(file);

      setUploadingImages((uploadingImages) =>
        uploadingImages.filter((uploadingImage) => uploadingImage !== image)
      );
      refetch();
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: pickFiles,
  } = useDropzone({
    noClick: true,
    maxSize: 5000000,
    onDrop,
  });

  const open = useCallback((callback: ImageLibraryOpenCallback) => {
    openCallback.current = callback;
    setIsOpen(true);
  }, []);

  const select = useCallback(() => {
    selectedImage &&
      openCallback.current &&
      openCallback.current(selectedImage);

    close();
  }, [selectedImage, openCallback.current]);

  const close = useCallback(() => {
    openCallback.current = null;
    setSelectedImage(null);
    setIsOpen(false);
  }, []);

  return (
    <ImageLibraryContext.Provider
      value={{
        open,
      }}
    >
      {children}

      <Modal
        onClose={close}
        isOpen={isOpen}
        overrides={{
          Dialog: {
            style: {
              width: "80vw",
              height: "80vh",
              display: "flex",
              flexDirection: "column",
            },
          },
          Root: {
            style: {
              zIndex: 20,
            },
          },
        }}
      >
        <ModalHeader>Biblioteka obrazków</ModalHeader>
        <ModalBody $style={{ flex: "1 1 0", overflow: "hidden" }}>
          <LibraryRoot>
            <LibraryImages>
              <Dropzone {...getRootProps()} isDragActive={isDragActive}>
                {loading ? (
                  <StyledSpinnerNext />
                ) : images?.nodes?.length > 0 ? (
                  <FlexGrid
                    flexGridColumnCount={4}
                    flexGridColumnGap="scale400"
                    flexGridRowGap="scale400"
                  >
                    {uploadingImages.map((image, index) => (
                      <FlexGridItem key={`uploadingImage${index}`}>
                        <ImagePreview image={image} isLoading={true} />
                      </FlexGridItem>
                    ))}
                    {images?.nodes?.map((image) => (
                      <FlexGridItem key={image?.id}>
                        <ImagePreview
                          image={image}
                          isSelected={
                            !!selectedImage && selectedImage?.id === image?.id
                          }
                          onClick={() =>
                            setSelectedImage((selectedImage) =>
                              selectedImage?.id !== image?.id ? image : null
                            )
                          }
                        />
                      </FlexGridItem>
                    ))}
                  </FlexGrid>
                ) : (
                  <LabelSmall>Brak obrazków</LabelSmall>
                )}
                <input {...getInputProps()} />
              </Dropzone>
            </LibraryImages>

            <Sidebar>
              {selectedImage && (
                <ImageDetails onChange={refetch} image={selectedImage} />
              )}
            </Sidebar>
          </LibraryRoot>
        </ModalBody>
        <ModalFooter>
          <Button
            kind="tertiary"
            onClick={close}
            $style={{ marginRight: "8px" }}
          >
            Anuluj
          </Button>
          <Button
            kind="secondary"
            onClick={pickFiles}
            $style={{ marginRight: "8px" }}
          >
            Wgraj plik(i)
          </Button>
          <Button onClick={select} disabled={!selectedImage}>
            Wybierz
          </Button>
        </ModalFooter>
      </Modal>
    </ImageLibraryContext.Provider>
  );
}

export const useImageLibrary = (): ImageLibraryContextProps =>
  useContext(ImageLibraryContext);
