import { ApolloError, useMutation } from "@apollo/client";
import { KIND } from "baseui/button";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { FormRenderer } from "components/form-renderer";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { DeviceFloppy, FileOff, Note } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";
import { scrollOnError } from "utils/scrollOnError";

import { EVENTS_FIELDS, EventsField, EventsFormInputs } from "../events.form";
import { EVENTS_CREATE } from "../events.gql";

export default function EventsCreate(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [isClearConfirmDialogOpen, setIsClearConfirmDialogOpen] = useState(
    false
  );
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const { isLoading, setIsLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const { checkPermission } = useAuth();
  const history = useHistory();

  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    reset,
    setValue,
    register,
    unregister,
    watch,
    ...methods
  } = useForm<EventsFormInputs>({
    defaultValues: {
      name: "",
      slug: "",
      locationName: "",
      biletynaId: "",
      isVisible: false,
      showInSlider: false,
      isSaleBlocked: false,
      categories: [],
      metaTitle: "",
      metaDescription: "",
      metaKeywords: "",
      ogTitle: "",
      ogDescription: "",
      robots: [],
      canonical: "",
      displayAlternativeButton: false,
      alternativeButtonText: "",
      alternativeButtonUrl: "",
    },
  });

  const [createEvent, { error }] = useMutation(EVENTS_CREATE);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  useEffect(() => {
    if (errors) scrollOnError(errors, EVENTS_FIELDS, "create");
  }, [errorTimeStamp]);

  useEffect(() => {
    if (watch(EventsField.IsRedirectUrl)) {
      register(EventsField.RedirectUrl);
    } else {
      unregister(EventsField.RedirectUrl);
      setValue(EventsField.RedirectUrl, "" as never);
    }
  }, [watch(EventsField.IsRedirectUrl)]);

  const onSubmit = async ({
    startingPrice,
    categories,
    isVisible,
    isSaleBlocked,
    metaTitle,
    metaDescription,
    metaKeywords,
    ogTitle,
    ogDescription,
    ogImage,
    organizers,
    canonical,
    termsOfService,
    robots,
    isRedirectUrl,
    ...values
  }: EventsFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      const { data } = await createEvent({
        variables: {
          eventCreateInput: {
            startingPrice: parseFloat(startingPrice as string) || null,
            organizerId: organizers?.length ? organizers?.[0]?.id : null,
            termsOfService: termsOfService?.[0],
            isVisible: !!isVisible,
            isSaleBlocked: !!isSaleBlocked,
            categoryIds: categories?.[0]?.id || [],
            ...values,
          },
          seoTagsetUpsertInput: {
            metaTitle,
            metaDescription,
            metaKeywords,
            ogTitle,
            ogDescription,
            ogImage,
            canonical,
            robots: robots?.length ? robots?.[0]?.label : null,
          },
        },
      });

      enqueueSnackbar({
        message: "Utworzono pomyślnie",
        variant: "success",
      });
      history.push(`/events/${data?.eventCreate?.id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.event.create))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title="Nowe wydarzenie"
        labels={["Wydarzenia", "Tworzenie"]}
        goBackOption
        buttons={[
          {
            label: "Wyczyść",
            kind: KIND.secondary,
            startEnhancer: <Note size={18} />,
            disabled: isLoading,
            onClick: () => {
              setIsClearConfirmDialogOpen(true);
            },
          },
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Utwórz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit, onError),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "createEvent",
          },
        ]}
      />
      <Content>
        <FormProvider<EventsFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          register={register}
          unregister={unregister}
          reset={reset}
          watch={watch}
          setValue={setValue}
          {...methods}
        >
          <FormRenderer<EventsFormInputs>
            id="createEvent"
            onSubmit={handleSubmit(onSubmit)}
            type="create"
            isLoading={isLoading}
            error={error}
            fields={EVENTS_FIELDS}
          />
        </FormProvider>
        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie tworzenia nowego wydarzenia"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
        <ConfirmDialog
          isOpen={isClearConfirmDialogOpen}
          label="Wyczyszczenie formularza"
          close={() => setIsClearConfirmDialogOpen(false)}
          confirm={() => {
            reset();
            setIsClearConfirmDialogOpen(false);
          }}
        />
      </Content>
    </article>
  );
}
