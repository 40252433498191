import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { DisplayXSmall } from "baseui/typography";
import { ActionsList } from "components/actions-list";
import { Cell } from "components/cell";
import { Content } from "components/content";
import { Grid } from "components/grid";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Calendar, CalendarEvent, UserPlus } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

export default function Home(): React.ReactElement {
  const [css] = useStyletron();
  const { setIsFetching } = useLoading();

  const { checkPermission } = useAuth();

  const history = useHistory();

  useEffect(() => {
    setIsFetching(false);
  }, []);

  return (
    <article>
      <Content>
        <Grid>
          <Cell span={12}>
            <Calendar size={90} />

            <Block
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              marginTop="scale500"
            >
              <DisplayXSmall>Twój dashboard</DisplayXSmall>
            </Block>
          </Cell>
          <Cell span={12}>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            <ActionsList
              actions={[
                {
                  label: "Dodaj wydarzenie",
                  icon: CalendarEvent,
                  onClick: () => history.push("/events/create"),
                  permission: checkPermission(PERMISSIONS.event.create),
                },
                {
                  label: "Dodaj użytkownika",
                  icon: UserPlus,
                  onClick: () => history.push("/users/create"),
                  permission: checkPermission(PERMISSIONS.user.create),
                },
              ]}
            />
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
