import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { KIND } from "baseui/button";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { FormRenderer } from "components/form-renderer";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { Event } from "containers/Events/events";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { DeviceFloppy, FileOff } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";
import { scrollOnError } from "utils/scrollOnError";

import { EventAggregator } from "../event-aggregators";
import {
  EVENT_AGGREGATORS_FIELDS,
  EventAggregatorsFormInputs,
} from "../event-aggregators.form";
import {
  EVENT_AGGREGATORS_SHOW,
  EVENT_AGGREGATORS_UPDATE,
} from "../event-aggregators.gql";

export default function EventAggregatorsUpdate(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const { enqueueSnackbar } = useSnackbar();
  const {
    setIsFetching,
    isLoading,
    setIsLoading,
    setIsPartialFetching,
  } = useLoading();
  const { checkPermission } = useAuth();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    setValue,
    reset,
    watch,
    ...methods
  } = useForm<EventAggregatorsFormInputs>();

  const { refetch, data, error: queryError } = useQuery(
    EVENT_AGGREGATORS_SHOW,
    {
      variables: { id: id ? parseInt(id) : null },
    }
  );
  const eventAggregator: EventAggregator = data?.eventAggregator;

  useEffect(() => {
    setIsFetching(true);
    if (data?.eventAggregator) refetch();
  }, []);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    async function setFile(fieldName: string, url: string) {
      const response = await fetch(url + "?cropper");
      const data = await response.blob();
      const extension = /\.(jpe?g|png|gif|svg)$/gi.exec(url)?.[1];
      const metadata = {
        type: `image/${extension === "svg" ? "svg+xml" : "png"}`,
      };

      const file = new File(
        [data],
        `${eventAggregator?.id}.${extension}`,
        metadata
      );

      if (url) setValue(fieldName as keyof EventAggregatorsFormInputs, file);
    }

    if (eventAggregator) {
      setValue("name", eventAggregator?.name);
      setValue("isVisible", eventAggregator?.isVisible);
      setValue("slug", eventAggregator?.slug);
      !!eventAggregator?.events &&
        setValue(
          "events",
          eventAggregator?.events?.map((event: Event) => ({
            label: event.name,
            ...event,
          }))
        );
      setValue("locationName", eventAggregator?.locationName);
      eventAggregator?.startedAt &&
        setValue("startedAt", new Date(eventAggregator?.startedAt));
      eventAggregator?.endedAt &&
        setValue("endedAt", new Date(eventAggregator?.endedAt));
      setValue("content", eventAggregator?.content);
      setValue("categories", eventAggregator?.categories);
      setValue("sliderPosition", eventAggregator?.sliderPosition);
      setValue("showInSlider", eventAggregator?.showInSlider);
      setValue("metaTitle", eventAggregator?.seoTagset?.metaTitle);
      setValue("metaDescription", eventAggregator?.seoTagset?.metaDescription);
      setValue("ogTitle", eventAggregator?.seoTagset?.ogTitle);
      setValue("ogDescription", eventAggregator?.seoTagset?.ogDescription);
      setValue("metaKeywords", eventAggregator?.seoTagset?.metaKeywords);
      setValue("canonical", eventAggregator?.seoTagset?.canonical);
      !!eventAggregator?.seoTagset?.robots &&
        setValue("robots", [
          {
            id: eventAggregator?.seoTagset?.robots,
            label: eventAggregator?.seoTagset?.robots,
          },
        ]);

      setTimeout(() => setIsFetching(false), 250);

      setTimeout(() => {
        setIsPartialFetching(true);

        Promise.all([
          setFile("verticalPoster", eventAggregator?.verticalPosterUrl),
          setFile("ogImage", eventAggregator?.seoTagset?.ogImageUrl),
        ]).then(() => setIsPartialFetching(false));
      }, 500);
    }
  }, [data]);

  useEffect(() => {
    setIsFetching(true);
    if (data?.eventAggregator) refetch();
  }, []);

  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  useEffect(() => {
    if (errors) scrollOnError(errors, EVENT_AGGREGATORS_FIELDS, "update");
  }, [errorTimeStamp]);

  const [updateEventAggregator, { error }] = useMutation(
    EVENT_AGGREGATORS_UPDATE
  );

  const onSubmit = async ({
    isVisible,
    events,
    metaTitle,
    metaDescription,
    metaKeywords,
    ogTitle,
    ogDescription,
    ogImage,
    canonical,
    robots,
    categories,
    ...values
  }: EventAggregatorsFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await updateEventAggregator({
        variables: {
          eventAggregatorUpdateInput: {
            id: id ? parseInt(id) : null,
            isVisible: !!isVisible,
            eventIds: events?.map(
              ({ id }: { id: number | string }) => id as number
            ),
            categoryIds: categories?.map(
              ({ id }: { id: number | string }) => id as number
            ),
            ...values,
          },
          seoTagsetUpsertInput: {
            metaTitle,
            metaDescription,
            metaKeywords,
            ogTitle,
            ogDescription,
            ogImage: ogImage || null,
            canonical,
            robots: robots?.length ? robots?.[0]?.label : null,
          },
        },
      });
      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.push(`/event-aggregators/${id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.eventAggregator.update))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title={eventAggregator?.name}
        labels={["Agregatory wydarzeń", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit, onError),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "updateeventAggregator",
          },
        ]}
      />
      <Content>
        <FormProvider<EventAggregatorsFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          setValue={setValue}
          watch={watch}
          {...methods}
        >
          <FormRenderer<EventAggregatorsFormInputs>
            id="updateeventAggregator"
            onSubmit={handleSubmit(onSubmit, onError)}
            type="update"
            isLoading={isLoading}
            error={error}
            fields={EVENT_AGGREGATORS_FIELDS}
          />
        </FormProvider>
        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie edycji agregatora wydarzeń"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
      </Content>
    </article>
  );
}
