import { StyleObject } from "styletron-standard";

export const preferredColumnWidth: { [key: string]: string } = {
  checkbox: "30px",
  notification: "100%",
};

export function getColumnWidth(id: string): string | undefined {
  switch (id) {
    case id.match("thumbnail|color")?.input:
      return "60px";

    case id.match("size")?.input:
      return "80px";

    case id.match("subject.id|startedAt|endedAt|saleStartedAt|saleEndedAt")
      ?.input:
      return "200px";

    case id.match("lastLoginAt")?.input:
      return "220px";

    case id.match("accountNumber|biletynaId")?.input:
      return "250px";

    case id.match("ordinalNumber|^id|Id")?.input:
      return "40px";

    case id.match("displayAlternativeButton")?.input:
      return "160px";

    case id.match(
      "At|number|Number|count|Count|amount|Amount|rate|Rate|effectiveOn|value|Value|firstName|lastName"
    )?.input:
      return "150px";

    case id.match(
      "balance|symbol|isHidden|isVisible|isActive|isFeatured|length|position|format|startingPrice|displayAlternativeButton"
    )?.input:
      return "100px";

    case id.match("status|isSystem")?.input:
      return "100px";

    case id.match("showInMainMenu")?.input:
      return "220px";

    case id.match("redirectUrl")?.input:
      return "290px";

    default:
      return preferredColumnWidth[id];
  }
}

export function getColumnDataAlign(id: string): StyleObject {
  switch (id) {
    case id.match("thumbnail")?.input:
      return {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      };

    case id.match("subject.id")?.input:
      return {
        display: "flex",
        justifyContent: "flex-start",
        textAlign: "left",
      };

    case id.match(
      "isHidden|isVisible|isSystem|isFeatured|isActive|showInMainMenu|showInFooterMenu|showInSlider|isSaleBlocked|displayAlternativeButton"
    )?.input:
      return {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      };

    case id.match(
      "At|id|Id|number|Number|count|Count|balance|ordinalNumber|amount|Amount|rate|Rate|effectiveOn|length|size|position|startingPrice"
    )?.input:
      return {
        display: "flex",
        justifyContent: "flex-end",
        textAlign: "right",
        float: "right",
      };

    default:
      return {
        textAlign: "left",
      };
  }
}
