/* eslint-disable @typescript-eslint/no-explicit-any */
import EditorJS, {
  OutputData,
  ToolConstructable,
  ToolSettings,
} from "@editorjs/editorjs";
import Header from "@editorjs/header";
import Paragraph from "@editorjs/paragraph";
import Raw from "@editorjs/raw";
import { useStyletron } from "baseui";
import { Tab, Tabs } from "baseui/tabs-motion";
import { useImageLibrary } from "contexts/image-library-context";
import Hyperlink from "editorjs-hyperlink";
import Undo from "editorjs-undo";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createReactEditorJS } from "react-editor-js";
import { Controller, UseControllerProps } from "react-hook-form";
import { Pencil } from "tabler-icons-react";

import Gallery from "./blocks/gallery";
import Image from "./blocks/image";
import Video from "./blocks/video";

Object.defineProperty(Paragraph, "sanitize", {
  get() {
    return {
      text: true,
    };
  },
});

const TOOLS = {
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
    config: {
      preserveBlank: true,
    },
  },
  header: {
    class: Header,
  },
  video: Video,
  link: {
    class: Hyperlink,
    config: {
      shortcut: "CMD+L",
      target: "_blank",
      rel: "nofollow",
      availableTargets: ["_blank", "_self"],
      availableRels: ["author", "noreferrer", "dofollow", "nofollow"],
      validate: false,
    },
  },
  raw: {
    class: Raw,
    config: {
      placeholder: "Wpisz kod",
    },
  },
};

type EditorProps = {
  data?: OutputData[];
  setData?: Dispatch<SetStateAction<OutputData[]>>;
  error?: boolean;
} & Readonly<EditorJS.EditorConfig>;

export default function Editor({
  readOnly,
  data,
  setData,
  error,
}: EditorProps): React.ReactElement {
  const [css, theme] = useStyletron();

  const EditorJs = createReactEditorJS();

  const [currentData, setCurrentData] = useState<OutputData[] | undefined>(
    data
  );

  const [pageIndex, setPageIndex] = useState(0);
  const [enableReInitialize, setEnableReInitialize] = useState(false);
  const imageLibraryContext = useImageLibrary();

  const editorRef = useRef<EditorJS>(null);

  useEffect(() => {
    if (JSON.stringify(currentData) !== JSON.stringify(data) && data) {
      if (!setData) setEnableReInitialize(true);
      setCurrentData(data);
    }
  }, [data]);

  useEffect(() => {
    if (JSON.stringify(currentData) === JSON.stringify(data)) {
      setEnableReInitialize(false);
    }

    setTimeout(() => {
      if (
        currentData &&
        JSON.stringify(currentData) !== JSON.stringify(data) &&
        setData
      ) {
        if (
          currentData?.filter((page) => page?.blocks?.length === 0).length === 0
        ) {
          setData([...currentData, { blocks: [] }]);
        } else {
          setData(currentData);
        }
      }
    }, 50);
  }, [currentData]);

  const tools = useMemo(
    () => ({
      ...TOOLS,
      image: {
        class: Image,
        config: {
          imageLibraryContext,
        },
      },
      gallery: {
        class: Gallery,
        config: {
          imageLibraryContext,
        },
      },
    }),
    [imageLibraryContext]
  );

  useEffect(() => {
    function savePage(event: any) {
      if (
        editorRef.current &&
        event?.target?.className?.includes("cdx-block")
      ) {
        editorRef.current?.save()?.then((value) => {
          setCurrentData(
            currentData
              ? [
                  ...currentData.filter((p, i) => i < pageIndex),
                  value,
                  ...currentData.filter((p, i) => i > pageIndex),
                ]
              : [value]
          );
        });
      }
    }

    window.addEventListener("paste", savePage);

    return () => window.removeEventListener("paste", savePage);
  }, [pageIndex]);

  return (
    <div className={css({ marginTop: theme.sizing.scale300 })}>
      <Tabs
        activeKey={pageIndex}
        onChange={({ activeKey }) => {
          setPageIndex(parseInt(activeKey as string));
        }}
        overrides={{
          TabBorder: {
            style: {
              height: "0px",
            },
          },
          TabHighlight: {
            style: {
              height: "2px",
              bottom: "3px",
              backgroundColor: theme.colors.borderFocus,
            },
          },
        }}
      >
        {(currentData
          ? readOnly
            ? currentData?.filter((page) => page?.blocks?.length > 0).length > 0
              ? currentData?.filter((page) => page?.blocks?.length > 0)
              : [{ blocks: [] }]
            : currentData.filter((item, index) => index === 0)
          : [{ blocks: [] }]
        ).map((page, index) => {
          return (
            <Tab
              artwork={
                index === pageIndex && !readOnly
                  ? () => <Pencil size={15} />
                  : undefined
              }
              title={`${index === 0 ? "Strona " : ""}${index + 1}`}
              key={index}
              overrides={{
                Tab: {
                  style: {
                    borderTopLeftRadius: theme.borders.radius300,
                    borderTopRightRadius: theme.borders.radius300,
                    background:
                      page?.blocks?.length === 0
                        ? theme.colors.inputFill
                        : theme.colors.inputBorder,
                    marginRight: theme.sizing.scale100,
                  },
                },
                TabPanel: {
                  style: {
                    paddingLeft: "unset",
                    paddingTop: "unset",
                    paddingRight: "unset",
                  },
                },
              }}
            >
              <div
                className={css({
                  borderBottomRightRadius: theme.borders.radius300,
                  borderBottomLeftRadius: theme.borders.radius300,
                  backgroundColor: error
                    ? theme.colors.inputFillError
                    : theme.colors.inputFill,
                  padding: "20px",
                  ...(!readOnly && {
                    borderWidth: "2px",
                    borderStyle: "solid",
                    borderColor: error
                      ? theme.colors.inputBorderError
                      : theme.colors.inputBorder,
                  }),
                })}
              >
                {enableReInitialize ? (
                  "Trwa ładowanie nowych danych.."
                ) : (
                  <EditorJs
                    placeholder={!readOnly && "Zacznij pisać tutaj..."}
                    readOnly={readOnly}
                    tools={
                      (tools as unknown) as {
                        [toolName: string]: ToolConstructable | ToolSettings;
                      }
                    }
                    onReady={(editor?: EditorJS) => {
                      if ((editor as any)?.holder) new Undo({ editor });
                    }}
                    enableReInitialize={enableReInitialize}
                    data={page}
                    onInitialize={(editor: EditorJS) => {
                      (editorRef as any).current = editor;
                    }}
                    onChange={(editor: EditorJS): any => {
                      editor?.saver?.save().then((value) => {
                        setCurrentData(
                          currentData
                            ? [
                                ...currentData.filter((p, i) => i < index),
                                value,
                                ...currentData.filter((p, i) => i > index),
                              ]
                            : [value]
                        );
                      });
                    }}
                    i18n={{
                      /**
                       * @type {I18nDictionary}
                       */
                      messages: {
                        toolNames: {
                          Text: "Tekst",
                          Heading: "Nagłówek",
                          "Raw HTML": "HTML",
                          Delete: "Usuń",
                          Bold: "Pogrubienie",
                          Italic: "Kursywa",
                        },
                        blockTunes: {
                          delete: {
                            Delete: "Usuń",
                            "Click to delete": "Kliknij, aby usunąć",
                          },
                          moveUp: {
                            "Move up": "Przesuń w górę",
                          },
                          moveDown: {
                            "Move down": "Przesuń w dół",
                          },
                        },
                        ui: {
                          toolbar: {
                            Filter: "Filtruj",
                            toolbox: {
                              Add: "Dodaj",
                              Delete: "Usuń",
                            },
                          },
                          inlineToolbar: {
                            converter: {
                              "Convert to": "Konwertuj na",
                            },
                          },
                          popover: {
                            Filter: "Filtruj",
                            "Nothing found": "Nic nie znaleziono",
                          },
                          blockTunes: {
                            toggler: {
                              "Click to tune": "Kliknij, aby dostosować",
                              "or drag to move": "albo złap i przesuń",
                            },
                          },
                        },
                        tools: {
                          header: {
                            "Heading 1": "Nagłówek 1",
                            "Heading 2": "Nagłówek 2",
                            "Heading 3": "Nagłówek 3",
                            "Heading 4": "Nagłówek 4",
                            "Heading 5": "Nagłówek 5",
                            "Heading 6": "Nagłówek 6",
                          },
                          raw: {},
                          link: {
                            Save: "Zapisz",
                            "Select rel": "Wybierz wartość rel",
                            "Select target": "Wybierz wartość target",
                          },
                        },
                      },
                    }}
                  />
                )}
              </div>
            </Tab>
          );
        })}
      </Tabs>

      <style
        dangerouslySetInnerHTML={{
          __html: `
            .icon {
              background-position: center;
              background-repeat: no-repeat;
            }

            .icon--link {
              background-image: url("data:image/svg+xml;utf8,<svg width='19' height='19' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M7.69998 12.6L7.67896 12.62C6.53993 13.7048 6.52012 15.5155 7.63516 16.625V16.625C8.72293 17.7073 10.4799 17.7102 11.5712 16.6314L13.0263 15.193C14.0703 14.1609 14.2141 12.525 13.3662 11.3266L13.22 11.12' stroke='black' stroke-width='2' stroke-linecap='round'/><path d='M16.22 11.12L16.3564 10.9805C17.2895 10.0265 17.3478 8.5207 16.4914 7.49733V7.49733C15.5691 6.39509 13.9269 6.25143 12.8271 7.17675L11.3901 8.38588C10.0935 9.47674 9.95706 11.4241 11.0888 12.6852L11.12 12.72' stroke='black' stroke-width='2' stroke-linecap='round'/></svg>")            
            }

            .icon--unlink {
              background-image: url("data:image/svg+xml;utf8,<svg width='19' height='19' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M15.7795 11.5C15.7795 11.5 16.053 11.1962 16.5497 10.6722C17.4442 9.72856 17.4701 8.2475 16.5781 7.30145V7.30145C15.6482 6.31522 14.0873 6.29227 13.1288 7.25073L11.8796 8.49999' stroke='black' stroke-width='2' stroke-linecap='round'/><path d='M8.24517 12.3883C8.24517 12.3883 7.97171 12.6922 7.47504 13.2161C6.58051 14.1598 6.55467 15.6408 7.44666 16.5869V16.5869C8.37653 17.5731 9.93744 17.5961 10.8959 16.6376L12.1452 15.3883' stroke='black' stroke-width='2' stroke-linecap='round'/><path d='M17.7802 15.1032L16.597 14.9422C16.0109 14.8624 15.4841 15.3059 15.4627 15.8969L15.4199 17.0818' stroke='black' stroke-width='2' stroke-linecap='round'/><path d='M6.39064 9.03238L7.58432 9.06668C8.17551 9.08366 8.6522 8.58665 8.61056 7.99669L8.5271 6.81397' stroke='black' stroke-width='2' stroke-linecap='round'/><line x1='12.1142' y1='12.2' x2='11.7' y2='11.7858' stroke='black' stroke-width='2' stroke-linecap='round'/></svg>")            
            }
          `,
        }}
      />
    </div>
  );
}

export function ControlledEditor({
  control,
  name,
  rules,
  error,
  ...rest
}: UseControllerProps<any> & EditorProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => {
        return (
          <Editor setData={onChange} data={value} error={error} {...rest} />
        );
      }}
    />
  );
}
