import { DataType, FormattedValue } from "components/formatted-value";
import { Event } from "containers/Events/events";
import { EventsField } from "containers/Events/events.form";
import React from "react";
import { Row } from "react-table";

import Table from "./table";

type EventsTableProps = {
  events: Event[];
  loading?: boolean;
};

export default function EventsTable({
  events,
  loading,
}: EventsTableProps): React.ReactElement {
  const columns = React.useMemo(
    () => [
      {
        Header: "Nazwa",
        accessor: EventsField.Name,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue dataType={DataType.Events} data={row.original.id}>
            {row.original.name}
          </FormattedValue>
        ),
        disableGlobalFilter: true,
      },
      {
        Header: "Kategoria",
        accessor: EventsField.Categories,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue
            dataType={DataType.Categories}
            data={row.original.categories?.[0]?.id}
          >
            {row.original.categories?.[0]?.name}
          </FormattedValue>
        ),
      },
      {
        Header: "Organizator",
        accessor: EventsField.Organizer,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue
            dataType={DataType.Organizers}
            data={row.original.organizer.id}
          >
            {row.original.organizer.name}
          </FormattedValue>
        ),
      },

      {
        Header: "Widoczność",
        accessor: EventsField.IsVisible,
        Cell: ({ row }: { row: Row<Event> }) => (
          <FormattedValue dataType={DataType.VisibilityBoolean}>
            {row.original.isVisible}
          </FormattedValue>
        ),
      },
    ],
    []
  );

  return (
    <Table<Event>
      columns={columns}
      data={events}
      isLoading={loading}
      stickLastColumn
      compact
    />
  );
}
