import { OutputData } from "@editorjs/editorjs";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { Cell } from "components/cell";
import { Editor } from "components/editor";
import { FormControl } from "components/form-control";
import {
  L_SKELETON,
  XL_SKELETON,
} from "components/form-renderer/form-renderer";
import { PermissionsTable } from "components/form-table";
import { DataType, FormattedValue } from "components/formatted-value";
import { Grid } from "components/grid";
import { LinkRenderer } from "components/link-renderer";
import { EventsTable } from "components/table";
import CategoriesTable from "components/table/categories-table";
import { ActivityLog } from "containers/ActivityLogs/activity-logs";
import { Category } from "containers/Categories/categories";
import { Dictionary } from "containers/Dictionaries/dictionaries";
import { EventAggregator } from "containers/EventAggregators/event-aggregators";
import { Event } from "containers/Events/events";
import { Image } from "containers/Images/images";
import { Permission, Role } from "containers/Roles/roles";
import { SeoTagset, SeoTagsetSubject } from "containers/SeoTagsets/seo-tagsets";
import { Slide } from "containers/Slides/slides";
import { StaticPage } from "containers/StaticPages/static-pages";
import { Tag } from "containers/Tags/tags";
import { User } from "containers/Users/users";
import { useLoading } from "contexts/loading-context";
import { Field, FieldsGroup, FieldType } from "fields.d";
import React from "react";
import { Minus } from "tabler-icons-react";
import { renderUserLabel } from "utils/render-user-label";
import { getTextColor } from "utils/text-color";

import { Typename } from "../../constants";

type AppObject =
  | ActivityLog
  | Category
  | Dictionary
  | Event
  | EventAggregator
  | Image
  | Role
  | SeoTagset
  | Slide
  | StaticPage
  | Tag
  | User;

type Props<T> = {
  fields: FieldsGroup[];
  data: T;
  refetch?: () => void;
};

export default function ObjectViewRenderer<T extends AppObject>({
  fields,
  data,
}: Props<T>): React.ReactElement {
  const { isFetching, isLoading } = useLoading();
  const [css, theme] = useStyletron();

  function renderField(field: Field) {
    switch (field.type) {
      case FieldType.CategoryRelations: {
        const children = (data?.[
          "children" as keyof T
        ] as unknown) as Category[];

        const parent = (data?.["parent" as keyof T] as unknown) as Category;

        return (
          <div>
            {children?.length ? (
              <FormControl label="Kategorie podrzędne">
                <CategoriesTable loading={isLoading} categories={children} />
              </FormControl>
            ) : parent ? (
              <FormControl label="Kategoria nadrzędna">
                <FormattedValue
                  dataType={DataType.Categories}
                  data={parent?.id}
                >
                  {parent?.name}
                </FormattedValue>
              </FormControl>
            ) : (
              <div
                className={css({
                  display: "flex",
                  alignItems: "center",
                  color: "#999999",
                })}
              >
                <>
                  <Minus
                    color="#999999"
                    size={18}
                    className={css({ marginRight: "5px" })}
                  />
                  Brak
                </>
              </div>
            )}
          </div>
        );
      }

      case FieldType.ColorPicker: {
        const color = (data?.[field.id as keyof T] as unknown) as string;
        const name = (data?.["name" as keyof T] as unknown) as string;

        return color ? (
          <Block display="flex" alignItems="center">
            <div
              className={css({
                width: "49%",
                height: "46px",
                backgroundColor: color,
                borderRadius: theme.borders.radius300,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "12px",
              })}
            >
              {name && (
                <p
                  className={css({
                    textTransform: "uppercase",
                    fontSize: "15px",
                    color: getTextColor(color),
                  })}
                >
                  {name}
                </p>
              )}
            </div>

            <p
              className={css({
                textTransform: "uppercase",
                fontSize: "18px",
              })}
            >
              {color}
            </p>
          </Block>
        ) : (
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              color: "#999999",
            })}
          >
            <>
              <Minus
                color="#999999"
                size={18}
                className={css({ marginRight: "5px" })}
              />
              Brak
            </>
          </div>
        );
      }

      case FieldType.Domains: {
        const domains = (data?.[field.id as keyof T] as unknown) as string[];

        return (
          <div
            className={css({ display: "flex", gap: "15px", flexWrap: "wrap" })}
          >
            {domains?.length ? (
              domains?.map((domain: string, index: number) => {
                return (
                  <FormattedValue
                    key={`domain-${index + 1}`}
                    dataType={DataType.Link}
                    href={domain}
                  >
                    {domain}
                  </FormattedValue>
                );
              })
            ) : (
              <FormattedValue />
            )}
          </div>
        );
      }

      case FieldType.Editor: {
        const editorData = (data?.[
          field.id as keyof T
        ] as unknown) as OutputData[] & OutputData;

        return <Editor placeholder={false} readOnly data={editorData} />;
      }

      case FieldType.EventsTable: {
        const events = (data?.[field.id as keyof T] as unknown) as Event[];

        return <EventsTable events={events} loading={isLoading} />;
      }

      case FieldType.Image:
      case FieldType.OgImage: {
        const url =
          field.type === FieldType.Image
            ? ((data?.[field.id as keyof T] as unknown) as string)
            : ((data?.["seoTagset" as keyof T] as unknown) as SeoTagset)
                ?.ogImageUrl;

        return (
          <div
            className={css({
              backgroundColor: theme.colors.inputFill,
              padding: "20px",
              borderRadius: theme.borders.radius200,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            {url ? (
              <img
                src={url}
                className={css({
                  maxHeight: "400px",
                  maxWidth: "100%",
                  objectFit: "contain",
                  objectPosition: "left",
                })}
              />
            ) : (
              <div
                className={css({
                  display: "flex",
                  alignItems: "center",
                  color: "#999999",
                })}
              >
                <>
                  <Minus
                    color="#999999"
                    size={18}
                    className={css({ marginRight: "5px" })}
                  />
                  Brak
                </>
              </div>
            )}
          </div>
        );
      }

      case FieldType.Multi: {
        const multiField = (data?.[
          field.id as keyof T
        ] as unknown) as AppObject[];

        return (
          <div
            className={css({ display: "flex", gap: "15px", flexWrap: "wrap" })}
          >
            {multiField?.length ? (
              multiField?.map((object: AppObject) => (
                <FormattedValue
                  key={object.id}
                  dataType={field.dataType}
                  data={object.id}
                >
                  {"name" in object && object.name}
                </FormattedValue>
              ))
            ) : (
              <FormattedValue />
            )}
          </div>
        );
      }

      case FieldType.PermissionsTable:
        return (
          <PermissionsTable
            isReadOnly
            selectedIds={(data as User | Role)?.permissions?.map(
              ({ id }: Permission) => id
            )}
          />
        );

      case FieldType.FileDownload: {
        const url = (data?.[
          "termsOfServiceUrl" as keyof T
        ] as unknown) as string;

        return (
          <FormattedValue dataType={DataType.FileDownload} href={url}>
            {data?.[field.id as keyof AppObject]}
          </FormattedValue>
        );
      }

      case FieldType.SeoTagsetSubject: {
        const handleSeoTagsetSubject = (
          typename: string,
          subject: SeoTagsetSubject
        ) => {
          switch (typename) {
            case Typename.Category:
              return (
                <FormattedValue
                  dataType={DataType.Categories}
                  data={subject?.id}
                  deletedAt={subject?.deletedAt as Date}
                >
                  {subject?.name}
                </FormattedValue>
              );
            case Typename.Event:
              return (
                <FormattedValue
                  dataType={DataType.Events}
                  data={subject?.id}
                  deletedAt={subject?.deletedAt as Date}
                >
                  {subject?.name}
                </FormattedValue>
              );
            case Typename.EventAggregator:
              return (
                <FormattedValue
                  dataType={DataType.EventAggregators}
                  data={subject?.id}
                  deletedAt={subject?.deletedAt as Date}
                >
                  {subject?.name}
                </FormattedValue>
              );
            case Typename.StaticPage:
              return (
                <FormattedValue
                  dataType={DataType.StaticPages}
                  data={subject?.id}
                  deletedAt={subject?.deletedAt as Date}
                >
                  {subject?.name}
                </FormattedValue>
              );
            default:
              <FormattedValue />;
          }
        };
        return field.typeName ? (
          handleSeoTagsetSubject(field.typeName, (data as SeoTagset)?.subject)
        ) : (
          <FormattedValue />
        );
      }

      case FieldType.SlideVariant: {
        const slideVariantField = data as Slide;

        return slideVariantField?.staticPage ? (
          <FormControl label="Strona informacyjna">
            <FormattedValue
              dataType={DataType.StaticPages}
              data={slideVariantField?.staticPage?.id}
            >
              {slideVariantField?.staticPage?.name}
            </FormattedValue>
          </FormControl>
        ) : slideVariantField?.url ? (
          <div className={css({ display: "flex", gap: "64px" })}>
            <div>
              <FormControl label="Adres strony WWW">
                <FormattedValue dataType={DataType.Link}>
                  {slideVariantField?.url}
                </FormattedValue>
              </FormControl>
            </div>

            <div>
              <FormControl label="Etykieta przycisku">
                <FormattedValue>
                  {slideVariantField?.buttonLabel}
                </FormattedValue>
              </FormControl>
            </div>
          </div>
        ) : (
          <FormattedValue />
        );
      }

      case FieldType.UserFullName: {
        const userField = (data?.[field.id as keyof T] as unknown) as User;

        return userField ? (
          <FormattedValue dataType={field.dataType} data={userField?.id}>
            {renderUserLabel(userField)}
          </FormattedValue>
        ) : (
          <FormattedValue />
        );
      }

      case FieldType.UserStatus: {
        const userField = data as User;

        if (
          userField?.createdAt &&
          userField?.invitedAt &&
          !userField?.activatedAt &&
          !userField?.blockedAt &&
          !userField?.deletedAt
        )
          return (
            <FormattedValue dataType={field.dataType}>
              Wysłano zaproszenie
            </FormattedValue>
          );
        else if (
          userField?.createdAt &&
          userField?.activatedAt &&
          !userField?.blockedAt &&
          !userField?.deletedAt
        )
          return (
            <FormattedValue dataType={field.dataType}>Aktywny</FormattedValue>
          );
        else if (userField?.blockedAt && !userField?.deletedAt)
          return (
            <FormattedValue dataType={field.dataType}>
              Zablokowany
            </FormattedValue>
          );
        else return <FormattedValue />;
      }

      case FieldType.Link: {
        const slug = (data?.["slug" as keyof T] as unknown) as string;

        return <LinkRenderer slug={slug} />;
      }

      default:
        return (
          <FormattedValue
            dataType={field.dataType}
            data={
              ((data?.[field.id as keyof AppObject] as unknown) as AppObject)
                ?.id
            }
          >
            {field.show.accessor
              ? ((data?.[
                  (field.show.accessor as string[])[0] as keyof AppObject
                ] as unknown) as AppObject)?.[
                  (field.show.accessor as string[])[1] as keyof AppObject
                ]
              : data?.[field.id as keyof AppObject]}
          </FormattedValue>
        );
    }
  }

  return (
    <Grid>
      {fields.map((group) => [
        group.label && (
          <Cell key={group.id + `-group`} span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              {group.label}
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
        ),
        group.fields
          .filter((field) =>
            field.type === FieldType.SeoTagsetSubject
              ? field.show.visible &&
                ((field.typeName &&
                  field.typeName ===
                    (data as SeoTagset)?.subject?.__typename) ||
                  !field.typeName)
              : field.show.visible
          )
          .map((field, index) => {
            return (
              <Cell
                span={field.show.span || field.span || 6}
                key={group.id + `-field` + index}
                {...(!!field.skip && { skip: field.skip })}
              >
                <FormControl
                  label={field.label}
                  caption={field.caption}
                  overrides={{
                    ControlContainer: {
                      props: {
                        "data-test-id": field.id,
                      },
                    },
                  }}
                >
                  {isFetching ? (
                    <Skeleton
                      rows={0}
                      height={
                        XL_SKELETON.includes(field.type as FieldType)
                          ? "240px"
                          : L_SKELETON.includes(field.type as FieldType)
                          ? "100px"
                          : "20px"
                      }
                      width="100%"
                      animation
                    />
                  ) : (
                    renderField(field)
                  )}
                </FormControl>
              </Cell>
            );
          }),
      ])}
    </Grid>
  );
}
