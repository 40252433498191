import { styled, useStyletron } from "baseui";
import { ButtonProps, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { Theme } from "baseui/theme";
import { HeadingSmall, LabelXSmall } from "baseui/typography";
import { Action, HeaderActionsList } from "components/actions-list";
import { Button } from "components/button";
import { Cell } from "components/cell";
import { Grid } from "components/grid";
import { Tooltip } from "components/tooltip";
import { useLoading } from "contexts/loading-context";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import { ArrowLeft } from "tabler-icons-react";
import { textContent } from "utils/misc";

const Container = styled(
  "header",
  ({ $theme, $sticked }: { $theme?: Theme; $sticked?: boolean }) => ({
    backgroundColor: "white",
    paddingTop: $sticked ? $theme?.sizing.scale600 : $theme?.sizing.scale1000,
    paddingBottom: $sticked ? $theme?.sizing.scale600 : $theme?.sizing.scale600,
    position: $sticked ? "sticky" : "absolute",
    top: "0px",
    left: "0px",
    right: "0px",
    zIndex: 12,
    borderBottomWidth: $sticked ? "2px" : "0px",
    borderBottomStyle: "solid",
    borderBottomColor: $theme?.colors.backgroundTertiary,
    ...($sticked && {
      marginBottom: "-72px",
      animationIterationCount: "1",
      animationDelay: "0ms",
      animationDuration: "200ms",
      animationFillMode: "both",
      animationName: {
        from: {
          transform: "translateY(-200px)",
        },
        to: {
          transform: "translateY(0px)",
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any,
    }),
  })
);

type HeaderProps = {
  title: React.ReactNode;
  recordsNum?: number;
  labels?: string[];
  goBackOption?: boolean;
  buttons?: ({
    label: string;
    isNegative?: boolean;
    isPositive?: boolean;
    permission?: boolean;
    formId?: string;
    type?: string;
    disabled?: boolean;
  } & ButtonProps)[];
  actions?: Action[];
};

export default function Header({
  title,
  recordsNum,
  labels,
  goBackOption,
  buttons,
  actions,
}: HeaderProps): React.ReactElement {
  const [css, theme] = useStyletron();
  const [sticked, setSticked] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const { isFetching, isLoading } = useLoading();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  window.addEventListener("resize", () => setWindowWidth(window.innerWidth));

  useEffect(() => {
    containerRef?.current?.parentNode?.parentNode?.addEventListener(
      "scroll",
      (event: Event) => {
        if ((event?.target as HTMLElement)?.scrollTop > 52) setSticked(true);
        else setSticked(false);
      }
    );
  }, [containerRef]);

  const shouldShowHeaderActions = actions?.some(
    (action: Action) => action.permission || action.permission === undefined
  );

  return (
    <Container $sticked={sticked} ref={containerRef}>
      <Helmet defer={false}>
        {!isFetching && title ? (
          <title>
            {textContent(title as ReactElement)} – Admin SuperTicket
          </title>
        ) : (
          <title>Admin SuperTicket</title>
        )}
      </Helmet>
      <Grid>
        <Cell span={12}>
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              minHeight: "38px",
            })}
          >
            {goBackOption && (
              <span
                className={css({
                  position: "absolute",
                  display: "flex",
                  right: "100%",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  color: "#444",
                  ":hover": {
                    color: "black",
                  },
                })}
                title="Cofnij"
                onClick={() => {
                  !isLoading && history.goBack();
                }}
              >
                <ArrowLeft
                  size={windowWidth > 1140 ? 24 : windowWidth < 600 ? 10 : 18}
                />
              </span>
            )}
            <div
              className={css({
                display: sticked ? "flex" : "block",
                alignItems: "center",
              })}
            >
              {isFetching ? (
                <Skeleton
                  rows={0}
                  height="32px"
                  width={
                    title && (title as string).length > 0
                      ? `${(title as string).length * 1.4}ch`
                      : "160px"
                  }
                  animation
                />
              ) : (
                <HeadingSmall
                  marginTop="0px"
                  marginBottom="0px"
                  marginRight="20px"
                  $style={{ fontWeight: 600 }}
                >
                  {title}
                </HeadingSmall>
              )}
              <div
                className={css({
                  display: "flex",
                  marginTop: "3px",
                })}
              >
                {recordsNum !== undefined && recordsNum >= 0 && (
                  <LabelXSmall
                    marginTop="0px"
                    marginBottom="0px"
                    $style={{
                      textTransform: "uppercase",
                      ":before": {
                        content: "'\\2022'",
                        marginRight: "6px",
                        color: "#aaa",
                      },
                      ":first-of-type:before": {
                        content: "unset",
                      },
                    }}
                  >
                    <span
                      className={css({
                        textDecorationLine: "underline",
                        textDecorationStyle: "dotted",
                      })}
                    >
                      {recordsNum}
                    </span>{" "}
                    {recordsNum === 1
                      ? "rekord"
                      : recordsNum % 10 >= 2 &&
                        recordsNum % 10 <= 4 &&
                        (recordsNum % 100 < 10 || recordsNum % 100 >= 20)
                      ? "rekordy"
                      : "rekordów"}
                  </LabelXSmall>
                )}
                {labels &&
                  labels.map((label, index) => (
                    <LabelXSmall
                      marginTop="0px"
                      marginBottom="0px"
                      key={`sublabel` + index}
                      $style={{
                        textTransform: "uppercase",
                        ":before": {
                          content: "'\\2022'",
                          marginRight: "6px",
                          marginLeft: "6px",
                          color: "#aaa",
                        },
                        ":first-of-type:before": {
                          content: "unset",
                        },
                      }}
                    >
                      {label}
                    </LabelXSmall>
                  ))}
              </div>
            </div>

            <div className={css({ display: "flex", gap: "10px" })}>
              {isFetching ? (
                <Skeleton rows={0} height="50px" width="100px" animation />
              ) : (
                buttons &&
                buttons.map(
                  (item, index) =>
                    item?.permission !== false && (
                      <Tooltip
                        {...(item?.disabled &&
                          item?.label === "Edytuj" && {
                            content:
                              "Dokument został podpisany, brak możliwości edycji",
                          })}
                        key={`index` + index}
                        $style={{ zIndex: 20 }}
                      >
                        <span>
                          <Button
                            size={sticked ? SIZE.compact : SIZE.default}
                            $style={{
                              transitionDuration: "100ms",
                              transitionProperty: "all",
                              transitionTimingFunction: "ease-in-out",
                              ...(item?.isNegative && {
                                backgroundColor: theme.colors.negative400,
                                color: "#FFF",
                                ":hover": {
                                  backgroundColor: theme.colors.negative500,
                                },
                                ":focus": {
                                  backgroundColor: theme.colors.negative500,
                                },
                              }),
                              ...(item?.isPositive && {
                                backgroundColor: theme.colors.positive400,
                                color: "#FFF",
                                ":hover": {
                                  backgroundColor: theme.colors.positive500,
                                },
                                ":focus": {
                                  backgroundColor: theme.colors.positive500,
                                },
                              }),
                            }}
                            {...(item?.type && {
                              type: item?.type,
                            })}
                            {...(item?.formId && {
                              form: item?.formId,
                            })}
                            {...(item?.disabled && {
                              disabled: item?.disabled,
                            })}
                            {...item}
                          >
                            {item?.label}
                          </Button>
                        </span>
                      </Tooltip>
                    )
                )
              )}

              {!!actions?.length &&
                shouldShowHeaderActions &&
                (isFetching ? (
                  <Skeleton rows={0} height="50px" width="50px" animation />
                ) : (
                  <HeaderActionsList actions={actions} sticked={sticked} />
                ))}
            </div>
          </div>
        </Cell>
      </Grid>
    </Container>
  );
}
