import { useStyletron } from "baseui";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { Button } from "components/button";
import { DataType, FormattedValue } from "components/formatted-value";
import { Category } from "containers/Categories/categories";
import { CategoriesField } from "containers/Categories/categories.form";
import React, { MouseEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row } from "react-table";
import { Eye } from "tabler-icons-react";

import SortingTableHeader, { SortDirection } from "./sorting-table-header";
import Table from "./table";

type CategoriesTableProps = {
  categories: Category[];
  loading?: boolean;
};

export default function CategoriesTable({
  categories,
  loading,
}: CategoriesTableProps): React.ReactElement {
  const [css] = useStyletron();
  const history = useHistory();

  const [sortBy, setSortBy] = useState<CategoriesField | null>(
    CategoriesField.Name
  );
  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    SortDirection.ASC
  );

  const handleSorting = (column: CategoriesField) => {
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(CategoriesField.Name)}
            sortDirection={
              sortBy === CategoriesField.Name ? sortDirection : null
            }
          >
            Nazwa
          </SortingTableHeader>
        ),
        accessor: CategoriesField.Name,
        Cell: ({ row }: { row: Row<Category> }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(`/categories/${row.original.id}`);
            }}
            href={`/categories/${row.original.id}`}
          >
            {row.original.name}
          </StyledLink>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(CategoriesField.Slug)}
            sortDirection={
              sortBy === CategoriesField.Slug ? sortDirection : null
            }
          >
            Slug
          </SortingTableHeader>
        ),
        accessor: CategoriesField.Slug,
        Cell: ({ row }: { row: Row<Category> }) => (
          <FormattedValue dataType={DataType.Pre} $style={{ fontSize: "12px" }}>
            {row.original.slug}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(CategoriesField.CreatedAt)}
            sortDirection={
              sortBy === CategoriesField.CreatedAt ? sortDirection : null
            }
          >
            Utworzenie
          </SortingTableHeader>
        ),
        accessor: CategoriesField.CreatedAt,
        Cell: ({ row }: { row: Row<Category> }) => (
          <FormattedValue dataType={DataType.Date}>
            {row.original.createdAt}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(CategoriesField.UpdatedAt)}
            sortDirection={
              sortBy === CategoriesField.UpdatedAt ? sortDirection : null
            }
          >
            Ostatnia aktualizacja
          </SortingTableHeader>
        ),
        accessor: CategoriesField.UpdatedAt,
        Cell: ({ row }: { row: Row<Category> }) => (
          <FormattedValue dataType={DataType.Date}>
            {row.original.updatedAt}
          </FormattedValue>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<Category> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $style={{ marginLeft: "6px" }}
              onClick={() => history.push(`/categories/${row.original.id}`)}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [sortBy, sortDirection]
  );

  return (
    <Table<Category>
      columns={columns}
      data={categories}
      isLoading={loading}
      stickLastColumn
    />
  );
}
